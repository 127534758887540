import React, { useContext, useEffect, useRef, useState } from 'react';
import { Context } from '../index';
import { observer } from 'mobx-react-lite';

const Admin = () => {
  const { adminStore } = useContext(Context);
  const admin = adminStore.admin;
  const [email, setEmail] = useState(admin.email);
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState(admin.first_name || '');
  const [lastName, setLastName] = useState(admin.last_name || '');
  const [isDisabled, setDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const passRef = useRef(null);
  const passInput = passRef.current;
  useEffect(() => {
    if (password || firstName !== admin.first_name || lastName !== admin.last_name) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [password, firstName, lastName]);

  const update = async () => {
    const err = {};
    if (password !== passInput.value) {
      err.pass = 'Password mismatch';
      passInput.classList.add('is-invalid');
    } else if (passInput.value && password && passInput.value.length < 8) {
      err.pass = 'Must be more than 8 characters';
      passInput.classList.add('is-invalid');
    } else {
      err.pass = 'valid';
      passInput.classList.remove('is-invalid');
    }

    setErrors(err);
    if (err.pass === 'valid') {
      if (password || firstName !== admin.first_name || lastName !== admin.last_name) {
        await adminStore.update(admin.id, password, firstName, lastName);
        // .then((response) => {
        //     if (response?.status === 200) {
        //         setFirstName(response.data.admin.first_name)
        //         setLastName(response.data.admin.last_name)
        //     }
        // })
      }
    }
  };

  return (
    <>
      <div className="profile">
        <div className="profile-header">
          <div className="profile-header-cover"></div>
          <div className="profile-header-content">
            <div className="profile-header-info">
              <h4 className="mt-0 mb-1">
                {adminStore.admin.first_name} {adminStore.admin.last_name}
              </h4>
              <p className="mb-2">{adminStore.admin.email}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-content">
        <div className="tab-content p-0">
          <div className="tab-pane fade active show" id="profile-about">
            <div className="table-responsive form-inline">
              <table className="table table-profile align-middle">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <h4>
                        {adminStore.admin.first_name} {adminStore.admin.last_name}
                        <small>{adminStore.admin.email}</small>
                      </h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="highlight">
                    <td className="field">First Name</td>
                    <td>
                      <input
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                        type="text"
                        className="form-control fs-13px"
                        placeholder="First Name"
                      />
                    </td>
                  </tr>
                  <tr className="highlight">
                    <td className="field">Last Name</td>
                    <td>
                      <input
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                        type="text"
                        className="form-control fs-13px"
                        placeholder="Last Name"
                      />
                    </td>
                  </tr>
                  <tr className="divider">
                    <td colSpan="2"></td>
                  </tr>
                  <tr>
                    <td className="field">Email</td>
                    <td>
                      <input
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        type="text"
                        className="form-control fs-13px"
                        placeholder="Email"
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Password</td>
                    <td>
                      <input
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        type="password"
                        className="form-control fs-13px"
                        placeholder="Password"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Password Confirmation</td>
                    <td>
                      <input
                        ref={passRef}
                        type="password"
                        className="form-control fs-13px"
                        id="re-password"
                        placeholder="Password Confirmation"
                      />
                      <div className="invalid-feedback">{errors.pass}</div>
                    </td>
                  </tr>

                  <tr className="highlight">
                    <td className="field">&nbsp;</td>
                    <td className="">
                      <button onClick={update} type="button" className="btn btn-primary w-300px" disabled={isDisabled}>
                        Update
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(Admin);
