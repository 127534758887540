import React, { useContext, useEffect, useRef, useState } from 'react';
import { LOGIN_ROUTE, USER_PIN_AUTHORIZATION_ROUTE, USER_VERIFY_AGE_ROUTE } from '../utils/consts';
import '../assets/scss/pages/index.scss';
import '../assets/scss/components/modal-dialog-box-content.scss';
import LayoutUserPage from '../components/user/LayoutUserPage';
import FormButton from '../components/user/FormButton';
import FormSplitter from '../components/user/FormSplitter';
import ModalDialogBoxContent from '../components/user/ModalDialogBoxContent';
import { Context } from '../index';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Spinner from '../components/Spinner';
import ModalDialogFaq from '../components/user/ModalDialogFaq';
import ModalDialogSupport from '../components/user/ModalDialogSupport';
import ModalDialogTerms from '../components/user/ModalDialogTerms';
import ModalDialogPolicy from '../components/user/ModalDialogPolicy';

const Index = () => {
  const { userStore } = useContext(Context);
  const history = useNavigate();
  const [spinner, setSpinner] = useState(true);
  const showModalLinkRef = useRef(null);
  const [message, setMessage] = useState('');
  const [messageTitle, setMessageTitle] = useState('');

  const hashTag = window.location.hash.substring(1);

  useEffect(() => {
    if (userStore.isAuth) {
      history(USER_PIN_AUTHORIZATION_ROUTE, { replace: true });
    }
    setSpinner(userStore.isLoading);
  }, [userStore.isAuth, userStore.isLoading]);

  useEffect(() => {
    if (hashTag === 'deactivated' || hashTag === 'lockedpin' || hashTag === 'maximum-number-devices') {
      switch (hashTag) {
        case 'deactivated':
          setMessageTitle('The user is not active');
          setMessage(
            'User has been deactivated. For support please email hello@santakindness.com or call 855-223-5692.',
          );
          break;
        case 'lockedpin':
          setMessageTitle('The pin has been locked');
          setMessage(
            'Ornament has been deactivated. For support please email hello@santakindness.com or call 855-223-5692.',
          );
          break;
        case 'maximum-number-devices':
          setMessageTitle('Number of allowed devices exceeded');
          setMessage(
            'Number of allowed devices exceeded!  For support please email hello@santakindness.com or call 855-223-5692.',
          );
          break;
      }
      showModalLinkRef.current?.click();
    }
  }, [hashTag]);

  if (spinner) {
    return <Spinner />;
  } else
    return (
      <LayoutUserPage>
        <h1 className={'title-content'}>
          To receive messages from Santa, a parent must
          <b> create an account</b> or <b>sign in</b>.
        </h1>
        <FormButton path={USER_VERIFY_AGE_ROUTE} name={'Create an Account'} color={'red'} />
        <FormSplitter />
        <FormButton path={LOGIN_ROUTE} name={'Sign In with email'} color={'green'} />

        <div className="block-info">
          <strong>Have questions?</strong>
          <a
            ref={showModalLinkRef}
            href="#"
            data-bs-toggle="modal"
            data-bs-target={'#modalProblems'}
            style={{ display: 'none' }}
          >
            Show problems
          </a>
          <a href="#" data-bs-toggle="modal" data-bs-target={'#modalFAQ'}>
            View FAQ
          </a>
          <a href="#" data-bs-toggle="modal" data-bs-target={'#modalSupport'}>
            Contact Support
          </a>
        </div>
        <div className="links-bottom">
          <a href="#" data-bs-toggle="modal" data-bs-target={'#modalPolicy'}>
            Privacy Policy
          </a>

          <a href="#" data-bs-toggle="modal" data-bs-target={'#modalTerms'}>
            Terms and Conditions
          </a>
        </div>

        <ModalDialogBoxContent title={messageTitle} modalId={'modalProblems'} classSelector={'modal-small'}>
          <p>{message}</p>
        </ModalDialogBoxContent>
        <ModalDialogBoxContent title={'Frequently Asked Questions'} modalId={'modalFAQ'} classSelector={'full-height'}>
          <ModalDialogFaq />
        </ModalDialogBoxContent>
        <ModalDialogBoxContent title={'Support'} modalId={'modalSupport'} classSelector={'modal-small'}>
          <ModalDialogSupport classWrap={'modal-support-small'} />
        </ModalDialogBoxContent>
        <ModalDialogBoxContent title={'SKO Terms of Use'} modalId={'modalTerms'} classSelector={'full-height'}>
          <ModalDialogTerms />
        </ModalDialogBoxContent>
        <ModalDialogBoxContent title={'SKO Privacy Policy'} modalId={'modalPolicy'} classSelector={'full-height'}>
          <ModalDialogPolicy />
        </ModalDialogBoxContent>
      </LayoutUserPage>
    );
};

export default observer(Index);
