import React from 'react';
import '../../assets/scss/components/form-splitter.scss';
import imgLeft from '../../assets/images/left-line.png';
import imgRight from '../../assets/images/right-line.png';

const FormSplitter = () => {
  return (
    <div className={'splitter'}>
      <img src={imgLeft} alt="" />
      or
      <img src={imgRight} alt="" />
    </div>
  );
};

export default FormSplitter;
