import axios from 'axios';
import baseUrl from '../config.json';

export const API_URL =
  process.env.REACT_APP_ENV === 'stage' ? process.env.REACT_APP_API_URL : baseUrl.REACT_APP_API_URL;
const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  config.headers.tokenAdmin = `Bearer ${localStorage.getItem('token')}`;
  config.headers.tokenUser = `Bearer ${localStorage.getItem('tokenUser')}`;
  return config;
});

$api.interceptors.response.use(
  (config) => {
    if (config.data?.device?.deviceToken || config.data?.dataDevice?.deviceToken) {
      const deviceToken = config.data?.device?.deviceToken || config.data?.dataDevice?.deviceToken;
      localStorage.setItem('deviceToken', deviceToken);
    }
    return config;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true;
      try {
        if (originalRequest.response?.data?.errors[0] === 'user_error') {
          const response = await axios.get(`${API_URL}api/user/refresh`, { withCredentials: true });
          localStorage.setItem('tokenUser', response.data.accessToken);
        } else if (originalRequest.response?.data?.errors[0] === 'admin_error') {
          const response = await axios.get(`${API_URL}api/admin/refresh`, { withCredentials: true });
          localStorage.setItem('token', response.data.accessToken);
        }

        return $api.request(originalRequest);
      } catch (e) {
        if (originalRequest.response?.data?.errors[0] === 'user_error') {
          return (window.location.href = window.location.origin + '/login');
        } else if (originalRequest.response?.data?.errors[0] === 'admin_error') {
          return (window.location.href = window.location.origin + '/admin/login');
        }
      }
    }
    throw error;
  },
);

export default $api;
