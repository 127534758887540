import parser from 'ua-parser-js';

export const getPlatform = (userAgent) => {
  const uap = parser(userAgent);
  let osVersion = uap.os.version;
  if (osVersion == null) {
    osVersion = '';
  }
  let browserVersion = uap.browser.major;
  if (browserVersion == null) {
    browserVersion = '';
  }
  let deviceVersion = uap.device.model;
  if (deviceVersion == null) {
    deviceVersion = '';
  }

  const platform = deviceVersion + ' ' + uap.os.name + osVersion + ' ' + uap.browser.name + ' ' + browserVersion;
  // platform = platform.replace(/\s/g, '');
  return platform;
};
