import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/scss/components/form-button.scss';
import PropTypes from 'prop-types';

const FormButton = ({ path, name, color }) => {
  return (
    <Link to={path} className={`btn btn-${color}`}>
      {name}
    </Link>
  );
};

export default FormButton;

FormButton.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  path: PropTypes.string,
};
