import React, { useContext, useEffect, useState } from 'react';
import 'flatpickr/dist/flatpickr.min.css';
import { useDebounce } from '../../utils/useDebounce';
import { ADMIN_REGISTRATION_ROUTE } from '../../utils/consts';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import FilterDate from './FilterDate';
import { Context } from '../../index';

const SearchForm = ({
  getQuerySearch,
  getLimitItems,
  getDateRange,
  isButton,
  buttonName = 'Add new profile',
  buttonRoute = ADMIN_REGISTRATION_ROUTE,
  children,
  exportCSV,
  isExport = false,
  isFilter = false,
  tableId,
}) => {
  const { adminStore } = useContext(Context);
  const [querySearch, setQuerySearch] = useState('');
  const [fieldSearch, setFieldSearch] = useState('email');
  const debouncedSearchTerm = useDebounce(querySearch, 500);

  useEffect(() => {
    const queryOptions = adminStore.queryOptions;
    let term = '';
    let field = 'email';
    if (queryOptions && tableId === 'pins') {
      const pins = queryOptions.pins;
      term = pins?.term || '';
      field = pins?.field || 'email';
    } else if (queryOptions && tableId === 'users') {
      const users = queryOptions.users;
      term = users?.term || '';
      field = users?.field || 'email';
    } else if (queryOptions && tableId === 'admins') {
      const admins = queryOptions.admins;
      term = admins?.term || '';
      field = admins?.field || 'email';
    }

    setQuerySearch(term);
    setFieldSearch(field);
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm || querySearch === '') {
      getQuerySearch(debouncedSearchTerm, fieldSearch);
    }
  }, [debouncedSearchTerm, fieldSearch]);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-7">
        <div className="dataTables_length" id="data-table-default_length">
          {isExport && (
            <button type="button" className="btn btn-gray btn-sm me-2 mb-2 default" onClick={() => exportCSV()}>
              Export CSV
            </button>
          )}
          {isFilter && <FilterDate getDateRange={getDateRange} />}
          <label>
            Show
            <select
              name="data-table-default_length"
              aria-controls="data-table-default"
              className="form-select form-select-sm"
              onChange={(e) => getLimitItems(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>{' '}
            entries
          </label>
          {isButton && (
            <Link to={buttonRoute} className="btn btn-success ms-3 mb-1">
              {buttonName}
            </Link>
          )}
        </div>
      </div>
      <div className="d-flex gap-3 justify-content-end col-sm-12 col-md-5">
        <div className="dataTables_length" id="data-table-default_length">
          <label>
            Fields:
            <select
              name="data-table-default_length"
              aria-controls="data-table-default"
              className="form-select form-select-sm"
              onChange={(e) => setFieldSearch(e.target.value)}
              value={fieldSearch}
            >
              {children}
            </select>
          </label>
        </div>
        <div id="data-table-default_filter" className="dataTables_filter">
          <label>
            Search:
            <input
              value={querySearch}
              onChange={(e) => setQuerySearch(e.target.value)}
              type="search"
              className="form-control form-control-sm"
              placeholder=""
              aria-controls="data-table-default"
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default SearchForm;

SearchForm.propTypes = {
  getQuerySearch: PropTypes.func,
  getLimitItems: PropTypes.func,
  getDateRange: PropTypes.func,
  isButton: PropTypes.bool,
  buttonName: PropTypes.string,
  buttonRoute: PropTypes.string,
  children: PropTypes.array,
  exportCSV: PropTypes.func,
  isExport: PropTypes.bool,
  isFilter: PropTypes.bool,
  tableId: PropTypes.string,
};
