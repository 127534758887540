import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Context } from '../index';
import '../assets/css/default/app.min.css';
import '../assets/css/vendor.min.css';
import '../assets/css/default/fonts/bootstrap-icons.woff';
import '../assets/css/default/fonts/bootstrap-icons.woff2';
import LoginForm from './admin/LoginForm';
import {
  ADMIN_FORGOT_PASSWORD_ROUTE,
  ADMIN_LOG_ROUTE,
  ADMIN_LOGIN_ROUTE,
  ADMIN_PROFILE_ROUTE,
  ADMIN_REGISTRATION_ROUTE,
  ADMIN_RESET_PASSWORD_ROUTE,
  ADMIN_ROUTE,
  ADMIN_SETTINGS_ROUTE,
  ADMIN_TABLE_PINS_ROUTE,
  ADMIN_TABLE_ROUTE,
  ADMIN_TABLE_USERS_ROUTE,
  ADMIN_USER_PROFILE_ROUTE,
  ADMIN_VIEW_PROFILE_ROUTE,
} from '../utils/consts';
import Admin from '../pages/Admin';
import TableAdmins from '../pages/TableAdmins';
import RegistrationForm from './admin/RegistrationForm';
import PrivateRoute from './admin/PrivateRoute';
import AdminProfile from '../pages/AdminProfile';
import TablePins from '../pages/TablePins';
import AdminResetPassword from '../pages/AdminResetPassword';
import AdminForgotPassword from '../pages/AdminForgotPassword';
import DashboardUserDetailPage from '../pages/DashboardUserDetailPage';
import TableUsers from '../pages/TableUsers';
import AdminSettings from '../pages/AdminSettings';
import AdminLog from '../pages/AdminLog';

const AdminRouter = () => {
  const { adminStore } = useContext(Context);
  if (localStorage.getItem('token')) {
    adminStore.checkAuth();
  }
  return (
    <Routes>
      <Route path={ADMIN_ROUTE} element={<PrivateRoute />} exact>
        <Route path={ADMIN_PROFILE_ROUTE} element={<Admin />} />
        <Route path={ADMIN_VIEW_PROFILE_ROUTE} element={<AdminProfile />} />
        <Route path={ADMIN_TABLE_ROUTE} element={<TableAdmins />} />
        <Route path={ADMIN_TABLE_PINS_ROUTE} element={<TablePins />} />
        <Route path={ADMIN_TABLE_USERS_ROUTE} element={<TableUsers />} />
        <Route path={ADMIN_USER_PROFILE_ROUTE} element={<DashboardUserDetailPage />} />
        <Route path={ADMIN_REGISTRATION_ROUTE} element={<RegistrationForm />} />
        <Route path={ADMIN_SETTINGS_ROUTE} element={<AdminSettings />} />
        <Route path={ADMIN_LOG_ROUTE} element={<AdminLog />} />
      </Route>
      <Route path={ADMIN_LOGIN_ROUTE} element={<LoginForm />} />
      <Route path={ADMIN_RESET_PASSWORD_ROUTE} element={<AdminResetPassword />} />
      <Route path={ADMIN_FORGOT_PASSWORD_ROUTE} element={<AdminForgotPassword />} />
      <Route path="admin/*" element={<Navigate to={ADMIN_LOGIN_ROUTE} replace />} />
      <Route path="admin/" element={<Navigate to={ADMIN_LOGIN_ROUTE} replace />} />
    </Routes>
  );
};

export default AdminRouter;
