import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import $ from 'jquery';
import { Context } from '../index';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import '../assets/scss/pages/registration-form.scss';
import '../assets/scss/pages/login-form.scss';
import {
  USER_ADD_PIN_ROUTE,
  USER_FORGOT_PASSWORD_ROUTE,
  USER_PIN_AUTHORIZATION_ROUTE,
  USER_VERIFY_AGE_ROUTE,
} from '../utils/consts';
import FormButtonSubmit from '../components/user/FormButtonSubmit';
import LayoutForm from '../components/user/LayoutForm';
import ModalDialogBoxContent from '../components/user/ModalDialogBoxContent';
import siteKey from '../config.json';
import Spinner from '../components/Spinner';
import ModalDialogPolicy from '../components/user/ModalDialogPolicy';

const UserLoginForm = () => {
  const { userStore } = useContext(Context);
  const history = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const passRef = useRef(null);
  const passInput = passRef.current;
  const emailRef = useRef(null);
  const emailInput = emailRef.current;
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    if (userStore.isAuth) {
      history(USER_PIN_AUTHORIZATION_ROUTE, { replace: true });
    }
    setSpinner(userStore.isLoading);
  }, [userStore.isAuth, userStore.isLoading]);

  useEffect(() => {
    ReactRecaptcha3.init(siteKey.REACT_APP_SITE_KEY).then(() => {
      const observer = new MutationObserver(function () {
        if (document.contains($('.grecaptcha-badge')[0]) && $('#inline-badge').is(':empty')) {
          $(document).find('.grecaptcha-badge').clone().appendTo('#inline-badge');
          observer.disconnect();
        }
      });
      observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
    });
    return () => {
      ReactRecaptcha3.destroy();
      $(document).find('.grecaptcha-badge').remove();
    };
  }, []);

  const signIn = async (e) => {
    e.preventDefault();
    const err = {};

    if (!email) {
      err.email = 'Required';
      emailRef.current.classList.add('is-invalid');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      err.email = 'Invalid email address';
      emailInput.classList.add('is-invalid');
    } else {
      err.email = 'valid';
      emailInput.classList.remove('is-invalid');
    }

    if (!password) {
      err.pass = 'Required';
      passRef.current.classList.remove('is-valid');
      passRef.current.classList.add('is-invalid');
    } else {
      err.pass = 'valid';
      passInput.classList.add('is-valid');
      passInput.classList.remove('is-invalid');
    }

    setErrors(err);

    try {
      if (err.pass === 'valid' && err.email === 'valid') {
        await ReactRecaptcha3.getToken().then(async (verifyCaptcha) => {
          await userStore.login(email, password, verifyCaptcha).then((response) => {
            if (response?.error) {
              emailInput.classList.add('is-invalid');
              setErrors({ email: response.message });
            } else {
              if (response.pins.count === 0) {
                history(USER_ADD_PIN_ROUTE);
              } else {
                history(USER_PIN_AUTHORIZATION_ROUTE);
              }
            }
          });
          emailInput.classList.remove('is-valid');
        });
      }
    } catch (e) {
      return { error: true, message: e.response?.data?.message };
    }
  };

  if (spinner) return <Spinner />;
  else
    return (
      <LayoutForm classSelector={'login-form'} title={'Sign In'}>
        <span>Sign in with your parent’s email address</span>
        <form action="" method="">
          <div className={`input-text ${!errors.email || errors.email === 'valid' ? '' : 'box-invalid'}`}>
            <input
              ref={emailRef}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              className=""
              id="emailAddress"
              placeholder="example@gmail.com"
              required
            />
            <div className="invalid-feedback">{errors.email}</div>
            <label htmlFor="emailAddress" className="">
              Parent’s Email
            </label>
          </div>

          <div className={`input-text ${!errors.pass || errors.pass === 'valid' ? '' : 'box-invalid'}`}>
            <input
              ref={passRef}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
              className=""
              id="password"
              placeholder="Password"
              required
            />
            <div className="invalid-feedback">{errors.pass}</div>
            <label htmlFor="password" className="">
              Password
            </label>
          </div>

          <div className="link-info">
            <Link to={USER_FORGOT_PASSWORD_ROUTE}>Forgot password?</Link>
          </div>

          <FormButtonSubmit name={'Sign In'} type={'submit'} color={'red'} callback={signIn} />
        </form>
        <div className="bottom-info">
          <span className="sc">Don’t have an account?</span> <Link to={USER_VERIFY_AGE_ROUTE}>Sign Up</Link>
        </div>
        <div className="policy">
          <a href="#" data-bs-toggle="modal" data-bs-target={'#modalPolicy'}>
            Privacy Policy
          </a>
          <div id="inline-badge"></div>
        </div>
        <ModalDialogBoxContent title={'SKO Privacy Policy'} modalId={'modalPolicy'} classSelector={'full-height'}>
          <ModalDialogPolicy />
        </ModalDialogBoxContent>
      </LayoutForm>
    );
};

export default observer(UserLoginForm);
