import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import '../assets/scss/pages/registration-form.scss';
import '../assets/scss/pages/user-verify.scss';
import { Context } from '../index';
import FormButton from '../components/user/FormButton';
import { USER_ACTIVATE_PIN_ROUTE } from '../utils/consts';
import LayoutForm from '../components/user/LayoutForm';

const UserVerify = () => {
  const { userStore } = useContext(Context);
  return (
    <LayoutForm classSelector={'user-verify'} title={'Verify Email Address'}>
      <span>
        Your email has been verified <b>{userStore.user.email}</b>.
      </span>
      <br />
      <span>Go to the next step.</span>
      <div className={'block-button'}>
        <FormButton path={USER_ACTIVATE_PIN_ROUTE} color={'red'} name={'Next'} />
      </div>
    </LayoutForm>
  );
};

export default observer(UserVerify);
