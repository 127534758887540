import React, { useContext, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Context } from '../index';
import '../assets/css/default/app.min.css';
import '../assets/css/vendor.min.css';
import '../assets/css/default/fonts/bootstrap-icons.woff';
import '../assets/css/default/fonts/bootstrap-icons.woff2';

import {
  ERROR_PAGE_ROUTE,
  INDEX_ROUTE,
  LOGIN_ROUTE,
  REGISTRATION_ROUTE,
  USER_ACCOUNT_CREATED_ROUTE,
  USER_ACTIVATE_PIN_ROUTE,
  USER_ADD_PIN_ROUTE,
  USER_EXPIRED_ACTIVATION_LINK_ROUTE,
  USER_FORGET_DEVICE_ROUTE,
  USER_FORGOT_PASSWORD_ROUTE,
  USER_MESSAGE_ADDED_PIN_ROUTE,
  USER_MESSAGE_FORGET_DEVICE_ROUTE,
  USER_MESSAGE_MAXIMUM_NUMBER_DEVICES_ROUTE,
  USER_MESSAGE_PASSWORD_RESET_ROUTE,
  USER_PIN_AUTHORIZATION_ROUTE,
  USER_RESET_PASSWORD_ROUTE,
  USER_ROUTE,
  USER_VERIFICATION_CODE_ROUTE,
  USER_VERIFY_AGE_ROUTE,
  USER_VERIFY_MESSAGE_ROUTE,
  USER_VERIFY_ROUTE,
} from '../utils/consts';
import PrivateRoute from './user/PrivateRoute';
import RegistrationForm from './user/RegistrationForm';
import Index from '../pages/Index';
import UserVerify from '../pages/UserVerify';
import UserActivatePin from '../pages/UserActivatePin';
import UserLoginForm from '../pages/UserLoginForm';
import UserVerifyMessage from '../pages/UserVerifyMessage';
import UserAccountCreated from '../pages/UserAccountCreated';
import UserAuthorizationPin from '../pages/UserAuthorizationPin';
import UserAddPin from '../pages/UserAddPin';
import UserForgotPassword from '../pages/UserForgotPassword';
import UserVerificationCode from '../pages/UserVerificationCode';
import UserResetPassword from '../pages/UserResetPassword';
import UserMessagePasswordResetSuccessfully from '../pages/UserMessagePasswordResetSuccessfully';
import UserMessageMaximumNumberDevices from '../pages/UserMessageMaximumNumberDevices';
import UserVerifyAge from '../pages/UserVerifyAge';
import ErrorPage from '../pages/ErrorPage';
import UserExpiredActivationLink from '../pages/UserExpiredActivationLink';
import UserForgetDevice from '../pages/UserForgetDevice';
import UserMessageForgetDevice from '../pages/UserMessageForgetDevice';
import UserMessageAddedOrnament from '../pages/UserMessageAddedOrnament';
import { observer } from 'mobx-react-lite';

const UserRouter = () => {
  const { userStore } = useContext(Context);
  const location = useLocation();
  const pathname = location.pathname;

  useMemo(() => {
    if (localStorage.getItem('tokenUser')) {
      userStore.checkAuth();
    }
  }, []);

  return (
    // switch
    <Routes>
      <Route path={USER_ROUTE} element={<PrivateRoute />}>
        <Route path={USER_VERIFY_ROUTE} element={<UserVerify />} />
        <Route path={USER_ACTIVATE_PIN_ROUTE} element={<UserActivatePin />} />
        <Route path={USER_ACCOUNT_CREATED_ROUTE} element={<UserAccountCreated />} />
        <Route path={USER_PIN_AUTHORIZATION_ROUTE} element={<UserAuthorizationPin />} />
        <Route path={USER_ADD_PIN_ROUTE} element={<UserAddPin />} />
        <Route path={USER_MESSAGE_ADDED_PIN_ROUTE} element={<UserMessageAddedOrnament />} />
        <Route path={USER_MESSAGE_MAXIMUM_NUMBER_DEVICES_ROUTE} element={<UserMessageMaximumNumberDevices />} />
        <Route path={USER_FORGET_DEVICE_ROUTE} element={<UserForgetDevice />} />
        <Route path={USER_MESSAGE_FORGET_DEVICE_ROUTE} element={<UserMessageForgetDevice />} />
      </Route>
      <Route path={INDEX_ROUTE} element={<Index />} />
      <Route path={USER_VERIFY_AGE_ROUTE} element={<UserVerifyAge />} />
      <Route path={REGISTRATION_ROUTE} element={<RegistrationForm />} />
      <Route path={USER_VERIFY_MESSAGE_ROUTE} element={<UserVerifyMessage />} />
      <Route path={LOGIN_ROUTE} element={<UserLoginForm />} />
      <Route path={USER_FORGOT_PASSWORD_ROUTE} element={<UserForgotPassword />} />
      <Route path={USER_RESET_PASSWORD_ROUTE} element={<UserResetPassword />} />
      <Route path={USER_VERIFICATION_CODE_ROUTE} element={<UserVerificationCode />} />
      <Route path={USER_MESSAGE_PASSWORD_RESET_ROUTE} element={<UserMessagePasswordResetSuccessfully />} />
      <Route path={USER_EXPIRED_ACTIVATION_LINK_ROUTE} element={<UserExpiredActivationLink />} />
      <Route path="user/*" element={<Navigate to={LOGIN_ROUTE} replace />} />
      <Route path={ERROR_PAGE_ROUTE} element={<ErrorPage />} />
      {!pathname.includes('/admin/') && <Route path="*" element={<Navigate to={ERROR_PAGE_ROUTE} replace />} />}
    </Routes>
  );
};

export default observer(UserRouter);
