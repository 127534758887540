import $api from '../http';

export default class AdminService {
  static fetchAdmins(page, limit, fieldName, direction, query, field) {
    return $api.get('api/admin/admins', {
      params: {
        page,
        limit,
        fieldName,
        direction,
        query,
        field,
      },
    });
  }

  static fetchPins(page, limit, fieldName, direction, query, field) {
    return $api.get('api/admin/table/pins', {
      params: {
        page,
        limit,
        fieldName,
        direction,
        query,
        field,
      },
    });
  }

  static fetchLogs() {
    return $api.get('api/admin/action-logs');
  }

  static getOneLog(id) {
    return $api.get('api/admin/action-logs/' + id);
  }

  static fetchUsers(page, limit, fieldName, direction, query, field, date, getDevices = false) {
    return $api.get('api/admin/table/users', {
      params: {
        page,
        limit,
        fieldName,
        direction,
        query,
        field,
        date,
        getDevices,
      },
    });
  }

  static fetchOneAdmin(id) {
    return $api.get('api/admin/profile/' + id);
  }

  static fetchOneUserProfile(id) {
    return $api.get('api/admin/user-profile/' + id);
  }

  static updatePinName(id, name) {
    return $api.put('api/admin/update-pin-name', { id, name });
  }

  static resendEmailActivateLink(email) {
    return $api.post('api/admin/resend-activate-link', { email });
  }

  static updateDeviceName(id, name) {
    return $api.put('api/admin/update-device-name', { id, name });
  }

  static removeDevice(id) {
    return $api.delete('api/admin/remove-device/' + id);
  }

  static updateUserEmail(id, email) {
    return $api.put('api/admin/update-user-email', { id, email });
  }

  static updateUserMarketingEmail(id, marketingEmail) {
    return $api.put('api/admin/update-user-marketing-email', { id, marketingEmail });
  }

  static updateSettings(name, value) {
    return $api.post('api/admin/update-setting', { name, value });
  }

  static importFileCSV(file) {
    return $api.post('api/admin/import/csv', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static getControls() {
    return $api.get('api/admin/controls');
  }
}
