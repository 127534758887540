import React, { memo, useEffect, useRef } from 'react';
import { Tooltip as BsTooltip } from 'bootstrap';
import PropTypes from 'prop-types';

const TableTh = ({ sortTable, sortParams, name, tooltip = name }) => {
  const thRef = useRef();
  useEffect(() => {
    const t = new BsTooltip(thRef.current, {
      title: tooltip,
      placement: 'top',
      trigger: 'hover',
    });
    return () => t.dispose();
  }, [tooltip]);
  return (
    <th
      ref={thRef}
      onClick={(event) => sortTable(sortParams.fieldName, sortParams.direction, event)}
      className={`sorting`}
    >
      {name}
    </th>
  );
};

export default memo(TableTh);

TableTh.propTypes = {
  sortTable: PropTypes.func,
  sortParams: PropTypes.object,
  name: PropTypes.string,
  tooltip: PropTypes.string,
};
