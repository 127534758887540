import { makeAutoObservable } from 'mobx';
import AuthService from '../services/AuthService';
import axios from 'axios';
import { API_URL } from '../http';
import AdminService from '../services/AdminService';

export default class AdminStore {
  admin = {};
  isAuth = false;
  isLoading = false;
  isSidebarCollapsed = false;
  numberDevices = 5;
  playerUrl = '';
  queryOptions = {};

  constructor() {
    makeAutoObservable(this);
    this.getControls();
  }

  setNumberDevices(numberDevices) {
    this.numberDevices = numberDevices;
  }

  setPlayerUrl(playerUrl) {
    this.playerUrl = playerUrl;
  }

  setAuth(bool) {
    this.isAuth = bool;
  }

  setSidebarCollapsed(bool) {
    this.isSidebarCollapsed = bool;
  }

  setAdmin(admin) {
    this.admin = admin;
  }

  setLoading(boll) {
    this.isLoading = boll;
  }

  setQueryOptions(options, tableId) {
    switch (tableId) {
      case 'pins':
        this.queryOptions.pins = options;
        break;
      case 'users':
        this.queryOptions.users = options;
        break;
      case 'admins':
        this.queryOptions.admins = options;
        break;
    }
  }

  async getControls() {
    try {
      await AdminService.getControls().then((response) => {
        response.data?.forEach((el) => {
          if (el.name === 'restrictDevices') {
            this.setNumberDevices(Number(el.value));
          }
          if (el.name === 'playerUrl') {
            this.setPlayerUrl(el.value);
          }
        });
      });
    } catch (e) {
      return { error: true, message: e.response?.data?.message };
    }
  }

  async login(email, password, verifyCaptcha) {
    try {
      const response = await AuthService.login(email, password, verifyCaptcha);
      localStorage.setItem('token', response.data.accessToken);
      this.setAuth(true);
      this.setAdmin(response.data.admin);
    } catch (e) {
      return { error: true, message: e.response?.data?.message };
    }
  }

  async registration(email, password, firstName, lastName, role) {
    try {
      await AuthService.registration(email, password, firstName, lastName, role);
    } catch (e) {
      return { error: true, message: e.response?.data?.message };
    }
  }

  async update(id, password, firstName, lastName) {
    try {
      const response = await AuthService.update(id, password, firstName, lastName);
      this.setAdmin(response.data.admin);
    } catch (e) {
      return { error: true, message: e.response?.data?.message };
    }
  }

  async logout() {
    try {
      await AuthService.logout();
      localStorage.removeItem('token');
      this.setAuth(false);
      this.setAdmin({});
    } catch (e) {
      return { error: true, message: e.response?.data?.message };
    }
  }

  async checkAuth() {
    this.setLoading(true);
    try {
      const response = await axios.get(`${API_URL}api/admin/refresh`, { withCredentials: true });
      localStorage.setItem('token', response.data.accessToken);
      this.setAuth(true);
      this.setAdmin(response.data.admin);
    } catch (e) {
      return { error: true, message: e.response?.data?.message };
    } finally {
      this.setLoading(false);
    }
  }
}
