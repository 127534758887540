import React, { useContext, useEffect, useRef, useState } from 'react';
import ModalDialogBox from '../components/admin/ModalDialogBox';
import { ADMIN_TABLE_PINS_ROUTE, roles } from '../utils/consts';
import '../assets/scss/pages/admin-settings.scss';
import AdminService from '../services/AdminService';
import { observer } from 'mobx-react-lite';
import { Context } from '../index';
import ModalImportCSV from '../components/admin/ModalImportCSV';
import { useNavigate } from 'react-router-dom';

const AdminSettings = () => {
  const { adminStore } = useContext(Context);
  const admin = adminStore.admin;
  const isMember = admin.role === roles.member.name;
  const history = useNavigate();
  const [deviceCount, setDeviceCount] = useState(adminStore.numberDevices);
  const [brightcoveUrl, setBrightcoveUrl] = useState(adminStore.playerUrl);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [message, setMessage] = useState({});
  const [file, setFile] = useState(null);
  const deviceSelectRef = useRef(null);
  const deviceSelectValue = deviceSelectRef.current;
  const brightcoveUrlSelectRef = useRef(null);
  const brightcoveUrlSelectValue = brightcoveUrlSelectRef.current;

  useEffect(() => {
    if (isMember) {
      history(ADMIN_TABLE_PINS_ROUTE);
    }
  });

  useEffect(() => {
    setDeviceCount(adminStore.numberDevices);
    setBrightcoveUrl(adminStore.playerUrl);
  }, [adminStore.numberDevices, adminStore.playerUrl]);

  const updateBrightcoveUrl = async () => {
    await AdminService.updateSettings(brightcoveUrlSelectValue.name, brightcoveUrl).then((response) => {
      if (response?.error) {
        setError(true);
        setErrorMessage({ playerUrl: response.error.message });
      } else {
        setError(false);
        setErrorMessage({});
        setBrightcoveUrl(response.data.value);
        adminStore.setPlayerUrl(response.data.value);
      }
    });
  };

  const limitNumberDevices = async () => {
    await AdminService.updateSettings(deviceSelectValue.name, deviceCount).then((response) => {
      if (response?.error) {
        setError(true);
        setErrorMessage({ devices: response.error.message });
      } else {
        setError(false);
        setErrorMessage({});
        setDeviceCount(response.data.value);
        adminStore.setNumberDevices(Number(response.data.value));
      }
    });
  };

  const selectFile = (e) => {
    setFile(e.target.files[0]);
    setErrorMessage({});
    setMessage({});
  };

  const importFileCSV = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('file', file);

    try {
      await AdminService.importFileCSV(formData).then((response) => {
        if (response?.error) {
          setError(true);
          setErrorMessage({ fileCSV: response.error.message });
        } else {
          setError(false);
          setErrorMessage({});
          setMessage({ fileCSV: response.data.message });
        }
      });
    } catch (e) {
      setError(true);
      setErrorMessage({ fileCSV: e.response.data.message + ': ' + e.response.data.errors[0] });
    }
  };
  return (
    <div className="card admin-settings">
      <div className="panel panel-inverse m-0">
        <div className="panel-heading undefined">
          <h4 className="panel-title">Settings</h4>
        </div>
      </div>
      <div className="col-md-12">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-sm-3">
              <h6 className="mb-0">
                <b>Limiting the number of devices per user (pin)</b>
              </h6>
            </div>
            <div className="col-sm-1 text-secondary block-validate">
              <input
                ref={deviceSelectRef}
                onChange={(e) => setDeviceCount(e.target.value)}
                value={deviceCount}
                type="number"
                name={'restrictDevices'}
                className={'form-control'}
              />
              {error ? <span className={'text-red p-lg-2'}>{errorMessage.device}</span> : ''}
            </div>
            <div className="col-sm-2 text-secondary">
              <button
                data-bs-toggle="modal"
                data-bs-target={'#modalAlertDeviceCount'}
                className="btn btn-success ms-5px text-decoration-none fw-bold"
              >
                Save
              </button>
              <ModalDialogBox
                title={'Warning'}
                messageTitle={' Are you sure you want to change the number of devices?'}
                buttonName={'Save'}
                buttonEvent={limitNumberDevices}
                modalId={'modalAlertDeviceCount'}
              >
                Changing the number of devices for a user will limit the use of new devices. The number of devices a
                user has that exceeds the limit will remain unchanged. See{' '}
                <a href={ADMIN_TABLE_PINS_ROUTE}>pin table</a> for more details.
              </ModalDialogBox>
            </div>
          </div>
        </div>
      </div>
      <hr className={'m-0'} />
      <div className="col-md-12">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-sm-3">
              <h6 className="mb-0">
                <b>Select a file with pin codes in csv format</b>
              </h6>
            </div>
            <form onSubmit={importFileCSV} className="col-sm-5 d-flex">
              <div className="col-sm-7 text-secondary block-validate">
                <input onChange={selectFile} type="file" accept=".csv" className={'form-control'} />
              </div>
              <div className="col-sm-5 text-secondary">
                <button
                  type={'button'}
                  data-bs-toggle="modal"
                  data-bs-target={'#modalAlertImport'}
                  className="btn btn-success ms-5px text-decoration-none fw-bold"
                >
                  Upload
                </button>
                <ModalImportCSV
                  title={'Warning'}
                  messageTitle={` Are you sure you want to upload ${file?.name}?`}
                  buttonName={'Import'}
                  modalId={'modalAlertImport'}
                  fileName={file}
                  resMessage={{ error: errorMessage.fileCSV, message: message.fileCSV }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <hr className={'m-0'} />
      <div className="col-md-12">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-sm-3">
              <h6 className="mb-0">
                <b>Brightcove redirection link</b>
              </h6>
            </div>
            <div className="col-sm-5 text-secondary block-validate">
              <input
                ref={brightcoveUrlSelectRef}
                onChange={(e) => setBrightcoveUrl(e.target.value)}
                value={brightcoveUrl}
                type="text"
                name={'playerUrl'}
                className={'form-control'}
              />
              {error ? <span className={'text-red p-lg-2'}>{errorMessage.playerUrl}</span> : ''}
            </div>
            <div className="col-sm-2 text-secondary">
              <button
                data-bs-toggle="modal"
                data-bs-target={'#modalAlertPlayerUrl'}
                className="btn btn-success ms-5px text-decoration-none fw-bold"
              >
                Save
              </button>
              <ModalDialogBox
                title={'Warning'}
                messageTitle={' Are you sure you want to change the player url?'}
                buttonName={'Save'}
                buttonEvent={updateBrightcoveUrl}
                modalId={'modalAlertPlayerUrl'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AdminSettings);
