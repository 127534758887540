import React, { useContext } from 'react';
import MenuItem from './MenuItem';
import ButtonCollapse from './ButtonCollapse';
import {
  ADMIN_SETTINGS_ROUTE,
  ADMIN_TABLE_PINS_ROUTE,
  ADMIN_TABLE_ROUTE,
  ADMIN_TABLE_USERS_ROUTE,
  ADMIN_LOG_ROUTE,
  roles,
} from '../../utils/consts';
import { useLocation } from 'react-router-dom';
import { Context } from '../../index';

const Sidebar = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const { adminStore } = useContext(Context);
  const admin = adminStore.admin;
  const isAdmin = admin.role === roles.admin.name;
  return (
    <>
      <div id="sidebar" className="app-sidebar">
        <div className="app-sidebar-content" data-scrollbar="true" data-height="100%">
          <div className="menu">
            <div className="menu-header"> Navigation</div>

            {isAdmin && (
              <MenuItem
                name="Admins"
                link={ADMIN_TABLE_ROUTE}
                icon={'fa-th-large'}
                isActive={ADMIN_TABLE_ROUTE === pathname}
              />
            )}
            <MenuItem
              name="Consumers"
              link={ADMIN_TABLE_USERS_ROUTE}
              icon={'fa-users'}
              isActive={ADMIN_TABLE_USERS_ROUTE === pathname}
            />
            <MenuItem
              name="Pins"
              link={ADMIN_TABLE_PINS_ROUTE}
              icon={'fa-table-list'}
              isActive={ADMIN_TABLE_PINS_ROUTE === pathname}
            />
            {isAdmin && (
              <MenuItem
                name="Settings"
                link={ADMIN_SETTINGS_ROUTE}
                icon={'fa-cog'}
                isActive={ADMIN_SETTINGS_ROUTE === pathname}
              />
            )}
            <MenuItem
              name="System action Log"
              link={ADMIN_LOG_ROUTE}
              icon={'fa-clipboard-list'}
              isActive={ADMIN_LOG_ROUTE === pathname}
            />

            <ButtonCollapse />
          </div>
        </div>
      </div>
      <div className="app-sidebar-bg"></div>
      <div className="app-sidebar-mobile-backdrop">
        <a href="#" data-dismiss="app-sidebar-mobile" className="stretched-link">
          {' '}
        </a>
      </div>
    </>
  );
};

export default Sidebar;
