import React, { useContext, useEffect, useState } from 'react';
import AdminService from '../services/AdminService';
import '../assets/css/dataTables.bootstrap5.min.css';
import '../assets/css/admin.css';
import $ from 'jquery';
import PaginationPage from '../components/PaginationPage';
import TableTh from '../components/admin/TableTh';
import TableInfo from '../components/admin/TableInfo';
import TableRow from '../components/admin/TableRow';
import SearchForm from '../components/admin/SearchForm';
import LayoutTable from '../components/admin/LayoutTable';
import Spinner from '../components/Spinner';
import TableThWithoutSort from '../components/admin/TableThWithoutSort';
import { Context } from '../index';
import { observer } from 'mobx-react-lite';

const TablePins = () => {
  const { adminStore } = useContext(Context);
  const [numberDevices, setNumberDevices] = useState(adminStore.numberDevices);
  const [spinner, setSpinner] = useState(true);
  const [pins, setPins] = useState([]);
  const [countItems, setCountItems] = useState(0);
  const [numberPage, setNumberPage] = useState(1);
  const [sortDirection, setSortDirection] = useState(true);
  const [nameDirection, setNameDirection] = useState('ASC');
  const [sortFieldName, setSortFieldName] = useState('id');
  const [term, setTerm] = useState();
  const [field, setField] = useState();
  const [watch, setWatch] = useState(false);
  const [limitItems, setLimitItems] = useState(10);
  const [optionLoad, setOptionLoad] = useState(false);
  const lastPage = limitItems * numberPage;
  const endItemsCurrentPage = lastPage <= countItems ? lastPage : countItems;
  const startItemsCurrentPage =
    lastPage <= countItems ? endItemsCurrentPage - limitItems + 1 : lastPage - limitItems + 1;
  const isPaginate = limitItems < countItems;
  const paginationConfig = {
    totalCount: countItems,
    limit: limitItems,
    page: numberPage,
  };

  useEffect(() => {
    if (adminStore.queryOptions.pins) {
      const optionPins = adminStore.queryOptions.pins;
      setNumberPage(optionPins.numberPage || numberPage);
      setLimitItems(optionPins.limitItems || limitItems);
      setSortFieldName(optionPins.sortFieldName || sortFieldName);
      setNameDirection(optionPins.nameDirection || nameDirection);
      setTerm(optionPins.term || term);
      setField(optionPins.field || field);
    }
    setOptionLoad(true);
  }, []);

  useEffect(() => {
    setNumberDevices(adminStore.numberDevices);
  }, [adminStore.numberDevices]);

  useEffect(() => {
    adminStore.setQueryOptions(
      {
        numberPage,
        limitItems,
        sortFieldName,
        nameDirection,
        term,
        field,
      },
      'pins',
    );

    if (optionLoad) {
      AdminService.fetchPins(numberPage, limitItems, sortFieldName, nameDirection, term, field).then((response) => {
        setPins(response.data.rows);
        setCountItems(response.data.count);
        setSpinner(false);
      });
    }
  }, [numberPage, term, limitItems, field, optionLoad]);

  const changeCurrentPage = (page) => {
    setNumberPage(page);
  };

  const getQuerySearch = (query, field) => {
    if (watch) {
      setTerm(query.trim());
      setField(field);
      setNumberPage(1);
    }
    setWatch(true);
  };

  const getLimitItems = (count) => {
    if (watch) {
      setLimitItems(count);
      setNumberPage(1);
    }
    setWatch(true);
  };
  const sortTable = (fieldName, direct, event) => {
    if (countItems > 1) {
      if (fieldName === sortFieldName) {
        direct = !direct;
        setSortDirection(direct);
      } else {
        setSortDirection(true);
        direct = true;
        setSortFieldName(fieldName);
      }

      const direction = direct ? 'ASC' : 'DESC';
      setNameDirection(direction);
      const selectorSorting = $(document).find('.sorting');

      selectorSorting.removeClass('sorting_asc');
      selectorSorting.removeClass('sorting_desc');

      if (direct) {
        event.target.classList.add('sorting_asc');
        event.target.classList.remove('sorting_desc');
      } else {
        event.target.classList.add('sorting_desc');
        event.target.classList.remove('sorting_asc');
      }

      adminStore.setQueryOptions(
        {
          numberPage,
          limitItems,
          sortFieldName,
          nameDirection,
          term,
          field,
        },
        'pins',
      );

      AdminService.fetchPins(numberPage, limitItems, fieldName, direction, term, field).then((response) => {
        setPins(response.data.rows);
        setCountItems(response.data.count);
        setSpinner(false);
      });
    }
  };
  return (
    <LayoutTable panelTitle={'Pins Table'}>
      <SearchForm
        getQuerySearch={getQuerySearch}
        getLimitItems={getLimitItems}
        isButton={false}
        buttonName={'import csv'}
        buttonRoute={'#'}
        tableId={'pins'}
      >
        <option value="email">Email</option>
        <option value="pin">Pin</option>
      </SearchForm>
      <table className="table dataTable table-hover table-bordered align-middle table-striped mb-0 text-dark">
        <thead>
          <tr>
            <TableTh
              sortTable={sortTable}
              sortParams={{ fieldName: 'pin', direction: sortDirection }}
              name="Ornament PIN"
              tooltip={'Ornament PIN code'}
            />

            <TableTh
              sortTable={sortTable}
              sortParams={{ fieldName: 'email', direction: sortDirection }}
              name="Email Address"
              tooltip={'Email address of customer account'}
            />

            <TableTh
              sortTable={sortTable}
              sortParams={{ fieldName: 'status', direction: sortDirection }}
              name="PIN Status"
              tooltip={'Registration status for the PIN – registered or not'}
            />
            <TableTh
              sortTable={sortTable}
              sortParams={{ fieldName: 'countDevices', direction: sortDirection }}
              name="Devices"
            />
            <TableTh
              sortTable={sortTable}
              sortParams={{ fieldName: 'batchName', direction: sortDirection }}
              name="PIN batch"
            />
            <TableTh
              sortTable={sortTable}
              sortParams={{ fieldName: 'registeredAt', direction: sortDirection }}
              name="Activation date"
            />
            <TableThWithoutSort name="Commentary" />
            <TableThWithoutSort name={'Action'} tooltip={'Is the PIN able to access video content or is it blocked'} />
          </tr>
        </thead>
        <tbody>
          {spinner ? (
            <tr>
              <td>
                <Spinner />
              </td>
            </tr>
          ) : (
            pins &&
            pins.map((coll) => (
              <TableRow
                key={coll.id}
                id={coll.id}
                email={coll.email}
                userId={coll.userId}
                pin={coll.pin}
                status={coll.status}
                devices={coll.devices}
                batchName={coll.batchName}
                registeredAt={coll.registeredAt}
                comment={coll.pinDeactivationComments[0]?.comment.trim()}
                countDevices={coll.countDevices}
                numberDevices={numberDevices}
              />
            ))
          )}
        </tbody>
      </table>
      <div className="row">
        <div className="col-sm-12 col-md-5">
          <TableInfo
            startItemsCurrentPage={startItemsCurrentPage}
            endItemsCurrentPage={endItemsCurrentPage}
            countItems={countItems}
          />
        </div>
        <div className="col-sm-12 col-md-7">
          {isPaginate && <PaginationPage items={paginationConfig} changeCurrentPage={changeCurrentPage} />}
        </div>
      </div>
    </LayoutTable>
  );
};

export default observer(TablePins);
