import React from 'react';
import PropTypes from 'prop-types';

const ModalDialogSupport = ({ classWrap = '' }) => {
  return (
    <p className={`nsc-r modal-support ${classWrap}`}>
      <span className="sc-r">For questions, information, and to share stories of kindness, please email </span>
      <a href="mailto:hello@santakindness.com">
        <b>hello@santakindness.com</b>
      </a>{' '}
      <span className="sc-r">or call </span>
      <a href="tel:8552235692">
        <b>855-223-5692</b>
      </a>
      .
    </p>
  );
};

export default ModalDialogSupport;
ModalDialogSupport.propTypes = {
  classWrap: PropTypes.string,
};
