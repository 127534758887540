import React, { useContext, useEffect, useRef, useState } from 'react';
import { Context } from '../index';
import { observer } from 'mobx-react-lite';
import AdminService from '../services/AdminService';
import { useNavigate, useParams } from 'react-router-dom';
import AuthService from '../services/AuthService';
import { ADMIN_LOGIN_ROUTE, ADMIN_TABLE_PINS_ROUTE, ADMIN_TABLE_ROUTE, roles } from '../utils/consts';
import ModalDialogBox from '../components/admin/ModalDialogBox';
import { validationPassword } from '../utils/validationForm';

const AdminProfile = () => {
  const { adminStore } = useContext(Context);
  const admin = adminStore.admin;
  const { id } = useParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [messageActivate, setMessageActivate] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const passRef = useRef(null);
  const passInput = passRef.current;
  const history = useNavigate();
  const isMember = admin.role === roles.member.name;
  const emailRef = useRef(null);
  const emailInput = emailRef.current;

  useEffect(() => {
    if (isMember) {
      history(ADMIN_TABLE_PINS_ROUTE);
    }

    AdminService.fetchOneAdmin(id).then((response) => {
      setEmail(response.data.email);
      setFirstName(response.data.firstName);
      setLastName(response.data.lastName);
      setRole(response.data.role);
    });
  }, []);

  const update = async () => {
    const err = {};
    if (!email) {
      err.email = 'Required';
      emailInput.classList.add('is-invalid');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      err.email = 'Invalid email address';
      emailInput.classList.add('is-invalid');
    } else {
      err.email = 'valid';
      emailInput.classList.remove('is-invalid');
    }

    if (passInput && passInput.value !== '' && password !== '') {
      err.pass = validationPassword(password, passInput);
    } else {
      err.pass = 'valid';
    }

    setErrors(err);
    if (err.email === 'valid' && err.pass === 'valid') {
      setErrors({});
      await AuthService.updateProfile(id, email, password, firstName, lastName, role).then((response) => {
        if (response?.error) {
          emailInput.classList.add('is-invalid');
          setErrorMessage(response.message);
        } else {
          emailInput.classList.remove('is-invalid');
          setMessageActivate(true);
          setErrorMessage('');
          setTimeout(() => setMessageActivate(false), 5000);
        }
      });
    }
  };
  const remove = async () => {
    await AuthService.delete(id).then((data) => {
      if (admin.id === Number(id) && data) {
        adminStore.logout().then(() => {
          history(ADMIN_LOGIN_ROUTE);
        });
      } else if (data) {
        history(ADMIN_TABLE_ROUTE);
      }
      return data;
    });
  };

  return (
    <>
      <div className="profile">
        <div className="profile-header">
          <div className="profile-header-cover"></div>
          <div className="profile-header-content">
            <div className="profile-header-info">
              <h4 className="mt-0 mb-1">
                {firstName} {lastName}
              </h4>
              <p className="mb-2">{email}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="register register-with-news-feed">
        <div className="register-container">
          <div className="register-header mb-25px h1">
            <h4>Edit account</h4>
            {messageActivate ? (
              <small className="d-block fs-15px lh-16 link-green">Account successfully updated.</small>
            ) : (
              <small className="d-block fs-15px lh-16 link-red">{errorMessage}</small>
            )}
          </div>

          <div className="register-content">
            <form action="" method="" className="fs-13px" data-parsley-validate="true" autoComplete="off">
              <div className="mb-3">
                <label className="mb-2">Email</label>
                {admin.id === Number(id) ? (
                  <input
                    ref={emailRef}
                    value={email}
                    type="text"
                    className="form-control fs-13px"
                    placeholder="Email address"
                    disabled={true}
                  />
                ) : (
                  <input
                    ref={emailRef}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    type="text"
                    className="form-control fs-13px"
                    placeholder="Email address"
                  />
                )}

                <div className="invalid-feedback">{errors.email}</div>
              </div>
              <div className="mb-3">
                <label className="mb-2">Name</label>
                <div className="row gx-3">
                  <div className="col-md-6 mb-2 mb-md-0">
                    <input
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      type="text"
                      className="form-control fs-13px"
                      placeholder="First name"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      type="text"
                      className="form-control fs-13px"
                      placeholder="Last name"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label className="mb-2">System role</label>
                <select onChange={(e) => setRole(e.target.value)} className="form-select" name="role" value={role}>
                  <option value="ADMIN">IT Admin</option>
                  <option value="MEMBER">Customer support</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="mb-2">New Password</label>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  type="password"
                  className="form-control fs-13px"
                  placeholder="Password"
                />
              </div>
              <div className="mb-3">
                <label className="mb-2">Confirm Password</label>
                <input
                  ref={passRef}
                  type="password"
                  className="form-control fs-13px"
                  id="re-password"
                  placeholder="Confirm password"
                />
                <div className="invalid-feedback">{errors.pass}</div>
              </div>

              <div className="mb-4">
                <button type="button" onClick={update} className="btn btn-primary w-100 btn-lg h-45px fs-13px mb-3">
                  Update
                </button>
                {admin.id !== Number(id) && (
                  <button
                    type="button"
                    className="btn btn-red w-100 btn-lg h-45px fs-13px"
                    data-bs-toggle="modal"
                    data-bs-target={'#modalAlertDelete'}
                  >
                    Delete account
                  </button>
                )}

                <ModalDialogBox
                  title={'Warning'}
                  messageTitle={'Are you sure you want to delete your account?'}
                  buttonName={'Delete'}
                  buttonEvent={remove}
                  modalId={'modalAlertDelete'}
                  status={'danger'}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(AdminProfile);
