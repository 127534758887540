import React, { useContext, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import '../assets/scss/pages/registration-form.scss';
import '../assets/scss/pages/user-verify.scss';
import { Context } from '../index';
import LayoutForm from '../components/user/LayoutForm';
import FormButtonSubmit from '../components/user/FormButtonSubmit';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthUserService from '../services/AuthUserService';
import { USER_RESET_PASSWORD_ROUTE } from '../utils/consts';

const UserVerificationCode = () => {
  const { userStore } = useContext(Context);
  const [code, setCode] = useState('');
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');
  const codeRef = useRef(null);
  const codeInput = codeRef.current;
  const history = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const decodeEmail = decodeURIComponent(email);
  const [messageEmail, setMessageEmail] = useState({});
  const msgRef = useRef(null);
  const sendVerifyCode = async (e) => {
    e.preventDefault();
    const err = {};
    if (!code) {
      err.code = 'Required';
      codeRef.current.classList.add('is-invalid');
      codeRef.current.focus();
    } else {
      err.code = '';
      codeInput.classList.remove('is-invalid');
    }

    setErrors(err);

    if (err.code === '') {
      try {
        await AuthUserService.sendCodeResetPassword(code, decodeEmail).then((response) => {
          if (response?.error) {
            codeInput.classList.add('is-invalid');
            setErrors({ code: response.message });
            codeRef.current.focus();
          } else {
            codeInput.classList.remove('is-invalid');
            setMessage(response?.data?.message);
            userStore.setUser(response.data.user);
            history(USER_RESET_PASSWORD_ROUTE);
          }
        });
      } catch (e) {
        codeInput.classList.add('is-invalid');
        setErrors({ code: e.response?.data?.message });
        codeRef.current.focus();
      }
    }
  };

  const resendEmail = async () => {
    const msgElement = msgRef.current;
    try {
      await AuthUserService.sendEmailResetPassword(decodeEmail).then((response) => {
        if (response) {
          msgElement.classList.remove('is-invalid');
          msgElement.classList.add('is-valid');
          setMessageEmail({ success: response.data.message });
        }
      });
    } catch (e) {
      msgElement.classList.remove('is-valid');
      msgElement.classList.add('is-invalid');
      setMessageEmail({ error: e.response?.data?.message });
    }
  };
  return (
    <LayoutForm classSelector={'user-verify'} title={'Check your email'}>
      <span>
        We’ve sent a verification code to <b>{decodeEmail}</b>.
      </span>
      <form action="" method="">
        <div className={`input-text ${!errors.code || errors.code === '' ? '' : 'box-invalid'}`}>
          <input
            ref={codeRef}
            onChange={(e) => setCode(e.target.value)}
            value={code}
            type="text"
            className=""
            id="code"
            placeholder="Verification Code"
            required
          />
          <div className="invalid-feedback">{errors.code}</div>
          {message && <div className="invalid-feedback">{message}</div>}
          <label htmlFor="code">Verification Code</label>
        </div>

        <FormButtonSubmit name={'Verify'} type={'submit'} color={'red'} callback={sendVerifyCode} />
      </form>
      <div className={'block-message p-0'}>
        <div ref={msgRef}></div>
        <div className="invalid-feedback">{messageEmail.error}</div>
        {messageEmail.success && <div className="valid-feedback">{messageEmail.success}</div>}
      </div>
      <div className="bottom-info">
        <span className="sc-r">Didn’t receive a code?</span>{' '}
        <a href={'#'} onClick={resendEmail}>
          Send again
        </a>
        <div className="sub-text">
          <span className="sc-r">Still not received? Please, be sure that you entered a correct</span>{' '}
          <b className="sc-r">email address </b>
          <span className="sc-r">or try later</span>
        </div>
      </div>
    </LayoutForm>
  );
};

export default observer(UserVerificationCode);
