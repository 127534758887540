import React from 'react';
import PropTypes from 'prop-types';

const CommentForm = ({ id, setNewMessage, newMessage }) => {
  return (
    <form action="">
      <div>
        <label htmlFor={'comment_' + id} className="form-label">
          Please, leave a commentary
        </label>
        <textarea
          className="form-control"
          id={'comment_' + id}
          rows="3"
          maxLength={120}
          onChange={(e) => setNewMessage(e.currentTarget.value)}
          value={newMessage}
          required={true}
        ></textarea>
      </div>
    </form>
  );
};

export default CommentForm;
CommentForm.propTypes = {
  id: PropTypes.number,
  setNewMessage: PropTypes.func,
  newMessage: PropTypes.string,
};
