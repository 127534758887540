import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { ADMIN_VIEW_PROFILE_LINK_ROUTE, roles } from '../../utils/consts';
import { Context } from '../../index';

const TableAdminRow = ({ id, role, email, firstName, lastName, isActivated }) => {
  const { adminStore } = useContext(Context);
  const admin = adminStore.admin;
  const isAdmin = admin.role === roles.admin.name;
  const ref = useRef();
  const roleName = role === roles.member.name ? roles.member.value : roles.admin.value;

  return (
    <tr ref={ref}>
      <td>{roleName}</td>
      <td>{email}</td>
      <td>{firstName}</td>
      <td>{lastName}</td>
      <td>{isActivated}</td>
      {isAdmin && (
        <td>
          <a href={ADMIN_VIEW_PROFILE_LINK_ROUTE + id} className="btn btn-success">
            View profile
          </a>
        </td>
      )}
    </tr>
  );
};

export default TableAdminRow;

TableAdminRow.propTypes = {
  id: PropTypes.number,
  email: PropTypes.string,
  role: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  isActivated: PropTypes.string,
};
