import React from 'react';
import { INDEX_ROUTE } from '../utils/consts';
import '../assets/scss/pages/index.scss';
import '../assets/scss/components/modal-dialog-box-content.scss';
import '../assets/scss/pages/error-page.scss';
import LayoutUserPage from '../components/user/LayoutUserPage';
import FormButton from '../components/user/FormButton';
import FormSplitter from '../components/user/FormSplitter';
import ModalDialogBoxContent from '../components/user/ModalDialogBoxContent';
import ModalDialogSupport from '../components/user/ModalDialogSupport';

const ErrorPage = () => {
  return (
    <LayoutUserPage>
      <div className="error-page">
        <h1>Oops!</h1>
        <h2>The page not found!</h2>
        <FormButton path={INDEX_ROUTE} name={'Go Home'} color={'green'} />
        <FormSplitter />

        <div className="block-info">
          <a href="#" data-bs-toggle="modal" data-bs-target={'#modalSupport'}>
            Contact Us
          </a>
        </div>
        <ModalDialogBoxContent title={'Support'} modalId={'modalSupport'} classSelector={'modal-small'}>
          <ModalDialogSupport classWrap={'modal-support-small'} />
        </ModalDialogBoxContent>
      </div>
    </LayoutUserPage>
  );
};

export default ErrorPage;
