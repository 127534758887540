import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TableInfo = ({ startItemsCurrentPage, endItemsCurrentPage, countItems }) => {
  return (
    <>
      {countItems && (
        <div className="dataTables_info" id="data-table-default_info" role="status" aria-live="polite">
          Showing {startItemsCurrentPage} to {endItemsCurrentPage} of {countItems} entries
        </div>
      )}
    </>
  );
};

export default memo(TableInfo);

TableInfo.propTypes = {
  startItemsCurrentPage: PropTypes.number,
  endItemsCurrentPage: PropTypes.number,
  countItems: PropTypes.number,
};
