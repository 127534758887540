import React from 'react';
import TableTdButtonGroup from './TableTdButtonGroup';
import PropTypes from 'prop-types';

const TableUserRow = ({ id, email, pins, marketingEmail }) => {
  return pins.map((pin) => (
    <tr key={pin.id}>
      <td>{email}</td>
      <td>{pin.pin}</td>
      <td>{pin.name}</td>
      <TableTdButtonGroup
        pinId={pin.id}
        status={pin.status}
        userId={id}
        email={email}
        marketingEmail={marketingEmail}
      />
    </tr>
  ));
};

export default TableUserRow;
TableUserRow.propTypes = {
  id: PropTypes.number,
  email: PropTypes.string,
  marketingEmail: PropTypes.bool,
};
