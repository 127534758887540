import React from 'react';
import screenReader from '../../utils/screenReader';

const ModalDialogTerms = () => {
  screenReader('b, li');
  return (
    <div className={'modal-support number-headings'}>
      <h3>Introduction</h3>
      <p>
        These Terms of Use (“<b>Terms</b>”) constitute an enforceable agreement between you and DEMDACO (“DEMDACO”,
        “we”, “us” or “our”) governing your access and use of the Santa’s Kindness Ornament (“<b>SKO</b>”) and related
        websites and/or mobile applications (“<b>SKO Websites</b>”) (collectively, the “<b>Services</b>”).
      </p>

      <h3>Acknowledgment</h3>
      <p className="fw-bold">BY ACCESSING AND USING THE SERVICES, YOU AGREE TO BE BOUND AND ABIDE BY THESE TERMS.</p>

      <h3>Arbitration / Dispute Resolution Notice</h3>
      <p className="fw-bold">
        YOU AGREE THAT ANY DISPUTE BETWEEN YOU AND DEMDACO CONCERNING YOUR ACCESS AND USE OF THE SERVICES WILL BE
        RESOLVED BY THE DISPUTE RESOLUTION PROCEDURES OUTLINED IN SECTION 16 BELOW, INCLUDING BUT NOT LIMITED TO
        INDIVIDUAL ARBITRATION. YOU ALSO WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS OR REPRESENTATIVE ACTION, OR MASS
        ARBITRATION, AS DEFINED BELOW. FOR MORE INFORMATION ABOUT THIS PROCESS, AND HOW YOU CAN OPT-OUT OF THE PROCESS,
        SEE SECTION 16 BELOW.
      </p>

      <h3>Eligibility</h3>
      <p>
        You must be an adult over the age of 18 years of age, or the age of consent in the state of your residence, in
        order to create an account and use the Services. If you do not meet these requirements, you may not create an
        account or register for the Services.
      </p>

      <h3>Account Creation</h3>
      <ul>
        <li>
          <b className="text-decoration-underline">Creating an Account.</b>{' '}
          <span>
            You must create an account with DEMDACO in order to use the Services. To create an account, you will be
            required to submit your name, e-mail address, telephone number, address, and provide a password. It is a
            condition of your use of the Services that all information you provide through the Services is accurate,
            correct, current, complete, and free from any viruses, unlawful, or otherwise harmful information.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Account Confidentiality.</b>{' '}
          <span>
            You must treat your account username and password as confidential and must not disclose such information to
            any other person or entity. You also acknowledge that your account is personal to you and agree not to
            provide any other person with access to the Services or portions of it using your username, password, or
            other related information. You agree to notify us immediately of any unauthorized access to or use of your
            user name or password or any other breach of security of such information.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Disabling the Account.</b>{' '}
          <span>
            We have the right to disable any username, password, or other identifier, whether chosen by you or provided
            by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you or any
            other user has violated any provision of these Terms.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Keeping the Account Active.</b>{' '}
          <span>
            You must use your account to keep it active. We have the right to disable any account that is deemed to be
            inactive by us in our sole discretion.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Fraudulent Use.</b>{' '}
          <span>
            If we reasonably suspect that your account is at risk of being used by a third party fraudulently (for
            example, as a result of an account compromise), we may suspend your account until you can reclaim ownership.
            Based on the nature of the compromise, we may be required to disable access. We reserve the right to disable
            any account that is deemed to have been potentially or actively compromised in our sole discretion.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Account Ownership.</b>{' '}
          <span>
            You understand and acknowledge that you have no ownership rights in your account on the Services, and that
            if you cancel your account or your account is terminated, all information you have submitted as part of your
            account will be marked as deleted in and may be deleted from our systems. Information may be retained in our
            sole discretion.
          </span>
        </li>
      </ul>

      <h3>Trademark and Acceptable Use</h3>
      <ul>
        <li>
          <b className="text-decoration-underline">Protected Materials.</b>{' '}
          <span>
            The Services and their entire contents, features, and functionality (including but not limited to all
            information, software, text, displays, images, video, and audio, and the design, selection, and arrangement
            thereof) (collectively, “<b>Materials</b>”) are owned by DEMDACO, its licensors, or other providers of such
            material and are protected by United States and international copyright, trademark, patent, trade secret,
            and other intellectual property or proprietary rights laws.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Marks.</b>{' '}
          <span>
            The trademarks, service marks, trade dress, trade names, and logos contained on the Services, including but
            not limited to the DEMDACO name and logo and other trademarks registered in the United States (collectively,
            “<b>Marks</b>”) are the sole property of DEMDACO or those brands to which the Marks apply. In addition, all
            page headers, custom graphics, and custom icons on the Services relating to DEMDACO are Marks of DEMDACO.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Limited Use.</b>{' '}
          <span>
            These Terms permit you to use the Services for your personal, non-commercial use only. You must not
            reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish,
            download, store, or transmit any of the material on our Services, except as follows:
          </span>
          <ul>
            <li>
              Your computer may temporarily store copies of such materials incidental to your accessing and viewing
              those materials.
            </li>
            <li>
              You may store files that are automatically cached by your Web browser for display enhancement purposes.
            </li>
            <li>
              You may print or download one copy of a reasonable number of pages of the Sites for your own personal,
              non-commercial use and not for further reproduction, publication, or distribution.
            </li>
            <li>
              If we provide desktop, mobile, or other applications for download, you may download a single copy to your
              computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound
              by our end user license agreement for such applications.
            </li>
          </ul>
        </li>
        <li>
          <b className="text-decoration-underline">Prohibited Uses.</b> <span>You must not:</span>
          <ul>
            <li>Modify copies of any materials from our Services.</li>
            <li>
              Use any illustrations, photographs, video or audio sequences, or any graphics separately from the
              accompanying text.
            </li>
            <li>
              Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials
              from the Sites.
            </li>
            <li>Access or use for any commercial purposes any part of the Services or content contained therein.</li>
          </ul>
        </li>
        <li>
          <b className="text-decoration-underline">Termination of Access.</b>{' '}
          <span>
            If you print, copy, modify, download, or otherwise use or provide any other person with access to any part
            of the Services in breach of these Terms, your right to use the Services will stop immediately and you must,
            at our option, return or destroy any copies of the materials you have made. No right, title, or interest in
            or to the Services or any content on the Services is transferred to you, and all rights not expressly
            granted are reserved by DEMDACO. Any use of the Services not expressly permitted by these Terms is a breach
            of these Terms and may violate copyright, trademark, and other laws.
          </span>
        </li>
      </ul>

      <h3>Trademark and Acceptable Use</h3>
      <ul>
        <li>
          <b className="text-decoration-underline">User Contributions.</b>{' '}
          <span>
            The Services contain interactive features and capabilities (the “<strong>Interactive Services</strong>”)
            that allow users to post, submit, publish, display, or transmit information and documents (collectively, “
            <strong>User Contributions</strong>”). All User Contributions must comply with the standards and rules set
            forth in these Terms.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Confidentiality of User Contributions.</b>{' '}
          <span>
            Any User Contributions submitted through the Services will be considered non-confidential, non-proprietary,
            and the property of DEMDACO. Demdaco reserves the right to use, share, disclose, modify, display,
            distribute, and otherwise process any User Contributions for any purpose.
          </span>
        </li>
      </ul>

      <h3>Notice of Copyright Infringement</h3>
      <ul>
        <li>
          <b className="text-decoration-underline">Copyright Infringement Notice.</b>{' '}
          <span>
            Pursuant to the Digital Millennium Copyright Act (17 U.S.C. § 512), DEMDACO has implemented procedures for
            receiving written notification of claimed infringements. DEMDACO has also designated an agent to receive
            notices of claimed copyright infringement. If you believe in good faith that your copyright has been
            infringed, you may submit such a request to{' '}
            <a href="mailto:customersupport@demdaco.com">customersupport@demdaco.com</a> containing the following
            information:
          </span>
          <ul>
            <li>
              An electronic or physical signature of the person authorized to act on behalf of the owner of the
              copyright interest;
            </li>
            <li>A description of the copyrighted work that you claim has been infringed;</li>
            <li>A description specifying the location on our website of the material that you claim is infringing;</li>
            <li>Your email address and your mailing address and/or telephone number;</li>
            <li>
              A statement by you that you have a good faith belief that the disputed use is not authorized by the
              copyright owner, its agent, or the law;
            </li>
            <li>
              A statement by you, made under penalty of perjury, that the information in your notice is accurate and
              that you are the copyright owner or authorized to act on the copyright owner’s behalf.
            </li>
          </ul>
        </li>
        <li>
          <b className="text-decoration-underline">Counter-Notice.</b>{' '}
          <span>
            If you believe that a notice of copyright infringement has been improperly submitted against you, you may
            submit a Counter-Notice, pursuant to the Digital Millennium Copyright Act, by sending a Counter-Notice to{' '}
            <a href="mailto:customersupport@demdaco.com">customersupport@demdaco.com</a> containing the following
            information:
          </span>
          <ul>
            <li>Your physical or electronic signature;</li>
            <li>Identification of the material removed or to which access has been disabled;</li>
            <li>
              A statement under penalty of perjury that you have a good faith belief that removal or disablement of the
              material was a mistake or that the material was misidentified;
            </li>
            <li>Your full name, your email address, and your mailing address.</li>
          </ul>
        </li>
      </ul>

      <h3>Prohibited Uses</h3>
      <p>
        You may use the Services only for lawful purposes and in accordance with these Terms. You agree not to use the
        Services:
      </p>
      <ul>
        <li>
          In any way that violates any applicable federal, state, local, or international law or regulation (including,
          without limitation, any laws regarding the export of data or software to and from the US or other countries).
        </li>
        <li>
          In any way that violates US international trade laws (including, without limitation, any laws and regulations
          implementing US and international sanctions programs on individuals, entities, or persons in sanctioned
          countries).
        </li>
        <li>
          For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to
          inappropriate content, asking for personally identifiable information, or otherwise.
        </li>
        <li>
          To transmit, or procure the sending of, any advertising or promotional material without our prior written
          consent, including any “junk mail,” “chain letter,” “spam,” or any other similar solicitation.
        </li>
        <li>
          To impersonate or attempt to impersonate DEMDACO, a DEMDACO employee, another user, or any other person or
          entity (including, without limitation, by using email addresses associated with any of the foregoing).
        </li>
        <li>
          To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Services, or which,
          as determined by us, may harm DEMDACO or users of the Services, or expose them to liability.
        </li>
      </ul>

      <p>Additionally, you agree not to:</p>
      <ul>
        <li>
          Use the Services in any manner that could disable, overburden, damage, or impair the site or interfere with
          any other party’s use of the Services, including their ability to engage in real time activities through the
          Services.
        </li>
        <li>
          Use any robot, spider, or other automatic device, process, or means to access the Services for any purpose,
          including monitoring or copying any of the material available through the Services.
        </li>
        <li>
          Use any manual process to monitor or copy any of the material on the Services, or for any other purpose not
          expressly authorized in these Terms, without our prior written consent.
        </li>
        <li>Use any device, software, or routine that interferes with the proper working of the Services.</li>
        <li>
          Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or
          technologically harmful.
        </li>
        <li>
          Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Services, the
          server on which the Services are stored, or any server, computer, or database connected to the Services.
        </li>
        <li>Attack the Services via a denial-of-service attack or a distributed denial-of-service attack.</li>
        <li>Otherwise attempt to interfere with the proper working of the Services.</li>
      </ul>

      <h3>Enforcement and Termination</h3>
      <p>DEMDACO reserves the right, at any time, to:</p>
      <ul>
        <li>
          Take any action with respect to any use of our Services that we deem necessary or appropriate in our sole
          discretion, including if we believe that such use violates these Terms, infringes any intellectual property
          right or other right of any person or entity, threatens the personal safety of users of the Services or the
          public, or could create liability for DEMDACO.
        </li>
        <li>
          Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or
          unauthorized use of the Services.
        </li>
        <li>
          Terminate or suspend your access to all or part of the Services for any or no reason, including without
          limitation, any violation of these Terms.
        </li>
        <li>
          DEMDACO reserves the right to cancel your account, profile, User Contributions, or other related activities.
          Any decision by DEMDACO relating to the cancellation, termination, or suspension of any member account or
          related DEMDACO Rewards shall be final and binding.
        </li>
      </ul>
      <p>
        Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities or
        court order requesting or directing us to disclose the identity or other information of anyone submitting any
        materials on or through the Services.
      </p>

      <h3>Links</h3>
      <p>
        If the Services contain links to other sites and resources provided by third parties, these links are provided
        for your convenience only. This includes links contained in advertisements, including banner advertisements and
        sponsored links. We have no control over the contents of those sites or resources, and accept no responsibility
        for them or for any loss or damage that may arise from your use of them. If you decide to access any of the
        third-party websites linked to our Services, you do so entirely at your own risk and subject to these Terms of
        use for such websites.
      </p>

      <h3>Disclaimer of Warranties</h3>
      <p>
        TO THE FULLEST EXTENT PROVIDED BY LAW, DEMDACO MAKES THE FOLLOWING REPRESENTATIONS. YOUR USE OF THE SERVICES,
        ITS CONTENT, AND ANY SERVICES OR PRODUCTS OBTAINED THROUGH THE SERVICES IS AT YOUR OWN RISK. THE SERVICES, ITS
        CONTENT, AND ANY SERVICES OR PRODUCTS OBTAINED THROUGH THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS
        AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER DEMDACO NOR ANY PERSON
        ASSOCIATED WITH DEMDACO MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
        RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER DEMDACO
        NOR ANYONE ASSOCIATED WITH DEMDACO REPRESENTS OR WARRANTS THAT THE SERVICES, ITS CONTENT, OR ANY SERVICES OR
        ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL
        BE CORRECTED, THAT OUR SERVICES OR THE SERVER THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
        COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR
        NEEDS OR EXPECTATIONS.
      </p>
      <p>
        TO THE FULLEST EXTENT PROVIDED BY LAW, DEMDACO HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
        IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
        NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT
        BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
      </p>

      <h3>Limitation of Liability</h3>
      <p>
        TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL DEMDACO, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE
        PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
        ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICES, ANY WEBSITES LINKED TO IT, ANY
        PRODUCTS OBTAINED THROUGH THE SERVICES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
        PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
        REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA,
        AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. TO THE
        EXTENT ANY SUCH DAMAGES ARE REQUIRED BY APPLICABLE LAW, THEY SHALL BE CAPPED AT ONE HUNDRED DOLLARS ($1,000)
        (US). THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
      </p>

      <h3>Indemnification</h3>
      <p>
        You agree to defend, indemnify, and hold harmless DEMDACO, its affiliates, licensors, and service providers, and
        its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors,
        and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or
        fees (including reasonable attorneys’ fees) arising out of or relating to: (1) your violation of these Terms;
        (2) your violation of any law, rule, regulation, or authority in connection with your use of the Services; (3)
        any negligent, reckless, or intentional acts or omissions committed by you in connection with your use of the
        Services; (4) any claim against DEMDACO that your use of the Services infringes the rights of a third party,
        including relating to any claim for patent, copyright, trademark, or trade secret infringement; (5) any claim
        against DEMDACO for libel, false advertisement, slander, defamation, copyright infringement, trademark
        infringement, false designation of origin, infringement of moral rights, disparagement, violation of privacy,
        publicity, identity, or other proprietary of rights, violation of patent or shop rights, piracy or plagiarism
        that arises out of your use of the Services.
      </p>

      <h3>Force Majeure</h3>
      <p>
        Under no circumstances shall DEMDACO be held responsible or liable for any delay or failure in performance
        resulting directly or indirectly from acts of nature, forces, or causes beyond its reasonable control,
        including, without limitation, Internet failures, computer equipment failures, telecommunication equipment
        failures, other equipment failures, electrical power failures, strikes, labor disputes, riots, insurrections,
        civil disturbances, shortages of labor or materials, fires, floods, storms, explosions, acts of God, war
        (including but not limited to cyber related events and/or occurrences attributed to state and/or quasi-state
        actors by either public or privacy organizations and/or entities and/or governments), governmental actions,
        orders of domestic or foreign courts or tribunals, non-performance of third parties, or loss of or fluctuations
        in heat, lighting, or air conditioning.
      </p>

      <h3>Dispute Resolution / Arbitration</h3>
      <ul>
        <li>
          <b className="text-decoration-underline">Disputes.</b>{' '}
          <span>
            The terms of this section shall apply to all Disputes between you and DEMDACO. For the purposes of this
            section, “Dispute” shall mean any dispute, claim, controversy or action between you and DEMDACO, including
            but not limited to disputes concerning these Terms, your use of the Services, and any other dispute whether
            in contract, warranty, misrepresentation, fraud, tort, intentional tort, statute, regulation, ordinance, or
            any other legal or equitable basis. YOU AND DEMDACO AGREE THAT “DISPUTE” AS DEFINED IN THESE TERMS OF USE
            SHALL NOT INCLUDE ANY CLAIM OR CAUSE OF ACTION BY DEMDACO FOR (1) TRADE SECRET MISAPPROPRIATION; (2) PATENT
            INFRINGEMENT; (3) COPYRIGHT INFRINGEMENT OR MISUSE; AND/OR (4) TRADEMARK INFRINGEMENT OR DILUTION.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Opt-Out.</b>{' '}
          <span>
            You may opt-out of the arbitration procedures and the waiver of class and representative proceedings
            specified in these Terms by sending a written letter to DEMDACO at{' '}
            <a href="mailto:customersupport@demdaco.com">customersupport@demdaco.com</a> within thirty (30) calendar
            days of your initial agreement to these Terms (including your first use of the Services) that specifies: (1)
            your name; (2) your mailing address; (3) and your request to be excluded from the final, binding individual
            arbitration procedure and waiver of class and representative proceedings specified in this Section. In the
            event that you opt-out consistent with the procedures set forth above, all other terms of these Terms shall
            continue to apply, including the duty to provide a Dispute Notice and mediate any Disputes, if applicable.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Binding Arbitration.</b>{' '}
          <span>
            You and DEMDACO agree: (1) to arbitrate all Disputes between you and DEMDACO pursuant to the provision of
            these Terms, to the extent applicable; (2) these Terms memorialize a transaction in interstate commerce; (3)
            the Federal Arbitration Act (9 U.S.C. § 1, et seq.) governs the interpretation and enforcement of this
            Section (notwithstanding the choice-of-law provision contained herein); and (4) this Section shall survive
            termination of these Terms.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Dispute Notice.</b>{' '}
          <span>
            You and DEMDACO agree: (1) to arbitrate all Disputes between you and DEMDACO pursuant to the provision of
            these Terms, to the extent applicable; (2) these Terms memorialize a transaction in interstate commerce; (3)
            the Federal Arbitration Act (9 U.S.C. § 1, et seq.) governs the interpretation and enforcement of this
            Section (notwithstanding the choice-of-law provision contained herein); and (4) this Section shall survive
            termination of these Terms.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Dispute Notice.</b>{' '}
          <span>
            In the event of a Dispute, you or DEMDACO must first send to the other party a notice of the Dispute that
            shall include a written statement that sets forth the name, address, and contact information of the party
            giving it, the facts giving rise to the Dispute, and the relief requested (the “<b>Dispute Notice</b>”). The
            Dispute Notice to DEMDACO must be addressed to{' '}
            <a href="mailto:customersupport@demdaco.com">customersupport@demdaco.com</a> (“<b>DEMDACO Notice Address</b>
            ”). The Dispute Notice to you will be sent by certified mail to the most recent address we have on file or
            otherwise in our records for you, or via email if we do not have any such address on file. Within 60
            calendar days upon either party receiving the Dispute Notice, the parties shall engage in a good faith
            informal dispute resolution conference, whether by phone, e-mail, or other channel as agreed between the
            parties. You and DEMDACO will work in good faith to schedule the informal conference at a mutually
            convenient time. If you are represented by counsel, your counsel may participate in the informal dispute
            resolution conference, but you shall also fully participate in such discussions. The arbitrator may dismiss
            any arbitration brought without first proceeding through the informal dispute resolution conference. The
            statute of limitations and any filing fee deadlines shall be tolled while the parties engage in the informal
            dispute resolution process required by this paragraph.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Mediation.</b>{' '}
          <span>
            In the event the parties cannot resolve the Dispute via the informal dispute resolution conference, the
            Dispute must first be submitted to non-binding mediation before a neutral third party before it may proceed
            to arbitration. Selecting the mediator, the appropriate terms for mediation (including costs), and a date
            for mediation shall be negotiated in good faith between the parties. The administrative fees associated with
            mediation shall be negotiated between the parties, but you will not be required to pay any such fees that
            exceed those fees you would be required to pay if proceeding in a court of law. Such fees also will not
            include attorneys’ fees and costs, if any, unless awarded during mediation. If the parties cannot agree to
            appropriate terms concerning mediation, the parties shall be entitled to proceed with an arbitration
            proceeding pursuant to this section. The arbitrator may dismiss any arbitration brought without first
            proceeding through the informal dispute resolution conference. The statute of limitations and any filing fee
            deadlines shall be tolled while the parties engage in the informal dispute resolution process required by
            this paragraph.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Small Claims Court.</b>{' '}
          <span>
            Notwithstanding the foregoing, you may bring an individual action in the small claims court of your state,
            municipality, province or territory if the action is within that court’s jurisdiction and is pending only in
            that court.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">CLASS ACTION WAIVER.</b>{' '}
          <span>
            TO THE EXTENT PERMITTED BY LAW, YOU AGREE THAT YOU MAY BRING DISPUTES AGAINST DEMDACO IN AN INDIVIDUAL
            CAPACITY ONLY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING,
            INCLUDING WITHOUT LIMITATION FEDERAL OR STATE CLASS ACTIONS.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">MASS ACTION WAIVER.</b>{' '}
          <span>
            You and DEMDACO agree that any Dispute between you shall be resolved only in an individual arbitration
            pursuant to this Section. You and DEMDACO expressly waive the right to have any dispute, claim, or
            controversy brought, heard, administered, resolved, or arbitrated as a Mass Action, as defined below, and
            neither an arbitrator nor an arbitration provider shall have any authority to hear, arbitrate, or administer
            any Mass Action to award relief to anyone but the individual in arbitration, unless otherwise provided in
            this section.
          </span>
          <p>
            “<b>Mass Action</b>” includes instances in which you or DEMDACO are represented by a law firm or collection
            of law firms that has filed 25 or more arbitration demands of a substantially similar nature against the
            other party within 180 days of the arbitration demand filed on you or DEMDACO’ behalf, and the law firm or
            collection of law firms seek to simultaneously or collectively administer and/or arbitrate all arbitration
            demands in the aggregate. Notwithstanding this section, nothing prevents you or DEMDACO from participating
            in a mass settlement of claims.
          </p>
        </li>
        <li>
          <b className="text-decoration-underline">Special Master Appointment.</b>{' '}
          <span>
            In the event there is a dispute concerning the Mass Action Waiver, the parties agree to resolve such dispute
            before a Special Master appointed by the arbitration provider and agreed to between the parties. The Special
            Master shall have authority to resolve disputes concerning: (i) filing fees owed with respect to any Mass
            Action; (ii) any dispute regarding whether this arbitration agreement has been followed; (iii) whether
            claimants are barred from proceeding with a Mass Action; (iv) any dispute relating to the representation of
            the same claimant by multiple law firms; (v) any dispute regarding discovery common to all claims; and (vi)
            any disputes regarding legal or factual issues common to all claims. If the Special Master determines you
            violated the Mass Action Waiver, either party shall have the opportunity to opt-out of arbitration within 30
            calendar days of the arbitrator’s decision. You may opt-out of arbitration by providing a written notice to{' '}
            <a href="mailto:customersupport@demdaco.com">customersupport@demdaco.com</a>. DEMDACO may opt-out of
            arbitration by sending written notice of its intention to the arbitration provider and to you or your
            attorney, agent, or representative. For the avoidance of doubt, the ability to opt-out of arbitration at
            this stage in the proceedings only applies if the arbitrator or panel of arbitrators determines that you
            have violated the Mass Action Waiver. If the parties proceed with arbitration, the parties agree to the
            batching procedures below.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Batching.</b>{' '}
          <span>
            After proceedings before the Special Master have concluded, and to the extent any Mass Actions are permitted
            to proceed, the parties agree that Mass Actions will be batched into groups of no more than 200 demands per
            batch by state of residence (with any remaining demands batched into a single group). The parties shall
            inform the arbitrator of the batches and their composition within 14 calendar days of the conclusion of
            proceedings before the Special Master. The arbitrator provider shall treat each batch of claims as one case,
            with each case having one demand for arbitration, one appointed arbitrator, and one set of administrative
            documents and filing fees per batch. The parties shall randomly assign sequential numbers to each batch, and
            only one batch shall proceed to arbitration at a time in the order of the random sequential numbers. A
            separate arbitrator will be appointed to, and administrative and filings fees assessed for, each batch as
            the batch proceeds to arbitration.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Arbitration Procedure.</b>{' '}
          <span>
            If a party elects to commence arbitration, the arbitration shall be administered by the American Arbitration
            Association (“<b>AAA</b>”) and be governed by the AAA Commercial Arbitration Rules and Mediation Procedures
            (“<b>AAA Commercial Rules</b>”), and other applicable rules, including the AAA Consumer Arbitration Rules (“
            <b>AAA Consumer Rules</b>”), as modified by these Terms of Service. The AAA Commercial Rules and AAA
            Consumer Rules are available at adr.org. If there is a conflict between the AAA Commercial Rules and AAA
            Consumer Rules and the rules set forth in these Terms of Service, the rules set forth in these Terms shall
            govern. All Disputes shall be resolved by a single neutral arbitrator, and both parties shall have a
            reasonable opportunity to participate in the selection of the arbitrator. The arbitrator is bound by the
            terms of these Terms. The arbitrator, and not any federal, state, provincial, territorial or local court or
            agency, shall have exclusive authority to resolve all disputes arising out of or relating to the
            interpretation, applicability, enforceability or formation of these Terms of Service, including, but not
            limited to, any claim that all or any part of these Terms is void or voidable. The arbitrator shall be
            empowered to grant whatever relief would be available in a court under law or in equity. The arbitrator’s
            award shall be binding on the parties and may be entered as a judgment in any court of competent
            jurisdiction.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Hearing Format.</b>{' '}
          <span>
            Unless otherwise agreed, the arbitration shall take place in Kansas, but may proceed telephonically in the
            event the total amount of the claim does not exceed $2,500 U.S. dollars (if the claimant so chooses). In all
            hearing formats, the arbitrator shall issue a written decision that explains the essential findings and
            conclusions on which an award, in any, is based. During the arbitration, the amount of any settlement offer
            made by DEMDACO or you shall not be disclosed to the arbitrator until after the arbitrator determines the
            amount, if any, to which you or DEMDACO is entitled. The discovery or exchange of non-privileged information
            relevant to the Dispute may be allowed during the arbitration.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Arbitration Fees.</b>{' '}
          <span>
            The arbitration fees are governed by AAA’s Commercial Arbitration Rules and Mediation Procedures
            Administrative Fee Schedule (“<b>Commercial Fee Schedule</b>”) or Consumer Arbitration Rules Costs of
            Arbitration (“<b>Consumer Fee Schedule</b>”), as applicable, and as modified by these Terms. AAA’s
            Commercial Fee Schedule and Consumer Fee Schedule are available at adr.org. You agree that if you initiate
            the arbitration, you will pay your filing fee. You shall not be required to pay a filing fee that exceeds
            any amounts you would otherwise be required to pay by applicable law when filing a claim in a court of
            relevant jurisdiction. You are responsible for all additional costs that you incur in the arbitration,
            including without limitation, fees for attorneys or expert witnesses.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Arbitrator’s Authority.</b>{' '}
          <span>
            All issues of arbitrability shall be reserved to the arbitrator. The arbitrator may award relief, including
            but not limited to monetary, declaratory, injunctive or other equitable relief only in favor of the
            individual party seeking relief and only to the extent necessary to provide relief. The decision of the
            arbitrator shall be in writing and shall briefly set forth the essential findings of fact and legal
            analysis. A judgment to enforce the award may be entered by a court of competent jurisdiction, however, any
            award that has been satisfied may not be filed or entered into court.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Binding Authority / Confidentiality.</b>{' '}
          <span>
            The parties agree that that they are each waiving their right to a trial by jury to the maximum extent
            permitted by law, and that the arbitrator’s award shall be final and binding unless the parties otherwise
            agree in writing. All arbitration proceedings will be confidential and closed to the public and any parties
            other than you and DEMDACO.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Amendments to this Section.</b>{' '}
          <span>
            Notwithstanding any provision in these Terms to the contrary, you and DEMDACO agree that if DEMDACO makes
            any material amendments to the dispute resolution procedure and class action waiver provisions in these
            Terms, DEMDACO will notify you and you will have thirty (30) calendar days from the date of notice to
            affirmatively opt-out of any such amendments by sending a written letter to the DEMDACO Notice Address that
            specifies: (1) your name; (2) your mailing address; and (3) your request to opt-out of such amendments. If
            you affirmatively opt-out of any future amendments, you are agreeing that you will arbitrate any Dispute
            between us in accordance with the language of this Section as stated in these Terms, without any of the
            proposed amendments governing. If you do not affirmatively opt-out of any future amendments, you will be
            deemed to have consented to any such future amendments.{' '}
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Severability.</b>{' '}
          <span>
            If any provision in this Section is found to be unenforceable, that provision shall be severed with the
            remainder of these Terms remaining in full force and effect. The foregoing shall not apply to the
            prohibition against class or representative actions; if the prohibition against class or representative
            actions is found to be unenforceable, this entire Section shall be null and void. The terms of this Section
            shall otherwise survive any termination of these Terms.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Exclusive Venue For Other Controversies.</b>{' '}
          <span>
            DEMDACO and you agree that any controversy excluded from the dispute resolution procedure and class action
            waiver provisions in this Section (other than an individual action filed in small claims court) shall be
            filed only in the courts located within the State of Kansas and each party hereby irrevocably and
            unconditionally consents and submits to the exclusive jurisdiction of such courts for any such.
          </span>
        </li>
      </ul>

      <h3>California Consumer Notice</h3>
      <p>
        California users are entitled to the following consumer rights notice: The Services are provided by DEMDACO. If
        you have a question or complaint regarding the Services, please contact us by writing to{' '}
        <a href="mailto:customersupport@demdaco.com">customersupport@demdaco.com</a>. California residents may reach the
        Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs
        by post at 1625 North Market Blvd., Sacramento, CA 95834 or by telephone at (916) 445-1254 or (800) 952-5210 or
        Hearing Impaired at TDD (800) 326-2297 or TDD (916) 322-1700.
      </p>

      <h3>Governing Law, Jurisdiction, Venue</h3>
      <p>
        This Agreement will be governed by and construed in accordance with Kansas. If for any reason a court of
        competent jurisdiction or arbitrator finds any provision or portion of this Agreement to be unenforceable, the
        remaining portion of this Agreement will continue in full force and effect.
      </p>

      <h3>Waiver and Severability</h3>
      <p>
        No waiver by DEMDACO of any term or condition set out in these Terms shall be deemed a further or continuing
        waiver of such term or condition or a waiver of any other term or condition, and any failure of DEMDACO to
        assert a right or provision under these Terms shall not constitute a waiver of such right or provision. If any
        provision of these Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal,
        or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that
        the remaining provisions of these Terms will continue in full force and effect.
      </p>

      <h3>Entire Agreement</h3>
      <p>
        These Terms constitute the sole and entire agreement between you and DEMDACO regarding the Services and
        supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both
        written and oral, regarding the Services.
      </p>

      <h3>Contact Us</h3>
      <p>
        All other feedback, comments, requests for technical support, and other communications relating to the Services
        should be directed to <a href="mailto:customersupport@demdaco.com">customersupport@demdaco.com</a>
      </p>
    </div>
  );
};

export default ModalDialogTerms;
