import React, { useContext, useEffect, useState } from 'react';
import AdminService from '../services/AdminService';
import '../assets/css/admin.css';
import $ from 'jquery';
import PaginationPage from '../components/PaginationPage';
import TableTh from '../components/admin/TableTh';
import TableInfo from '../components/admin/TableInfo';
import SearchForm from '../components/admin/SearchForm';
import LayoutTable from '../components/admin/LayoutTable';
import Spinner from '../components/Spinner';
import TableAdminRow from '../components/admin/TableAdminRow';
import TableThWithoutSort from '../components/admin/TableThWithoutSort';
import { ADMIN_TABLE_PINS_ROUTE, roles } from '../utils/consts';
import { Context } from '../index';
import { useNavigate } from 'react-router-dom';

const TableAdmins = () => {
  const { adminStore } = useContext(Context);
  const [spinner, setSpinner] = useState(true);
  const [users, setUsers] = useState([]);
  const admin = adminStore.admin;
  const isAdmin = admin.role === roles.admin.name;
  const history = useNavigate();
  const [countItems, setCountItems] = useState(0);
  const [numberPage, setNumberPage] = useState(1);
  const [sortDirection, setSortDirection] = useState(true);
  const [nameDirection, setNameDirection] = useState('ASC');
  const [sortFieldName, setSortFieldName] = useState('id');
  const [term, setTerm] = useState();
  const [field, setField] = useState();
  const [watch, setWatch] = useState(false);
  const [limitItems, setLimitItems] = useState(10);
  const [optionLoad, setOptionLoad] = useState(false);
  const lastPage = limitItems * numberPage;
  const endItemsCurrentPage = lastPage <= countItems ? lastPage : countItems;
  const startItemsCurrentPage =
    lastPage <= countItems ? endItemsCurrentPage - limitItems + 1 : lastPage - limitItems + 1;
  const isPaginate = limitItems < countItems;
  const paginationConfig = {
    totalCount: countItems,
    limit: limitItems,
    page: numberPage,
  };

  useEffect(() => {
    if (!isAdmin) {
      history(ADMIN_TABLE_PINS_ROUTE);
    }
  });

  useEffect(() => {
    if (adminStore.queryOptions.admins) {
      const optionAdmins = adminStore.queryOptions.admins;
      setNumberPage(optionAdmins.numberPage || numberPage);
      setLimitItems(optionAdmins.limitItems || limitItems);
      setSortFieldName(optionAdmins.sortFieldName || sortFieldName);
      setNameDirection(optionAdmins.nameDirection || nameDirection);
      setTerm(optionAdmins.term || term);
      setField(optionAdmins.field || field);
    }
    setOptionLoad(true);
  }, []);

  useEffect(() => {
    adminStore.setQueryOptions(
      {
        numberPage,
        limitItems,
        sortFieldName,
        nameDirection,
        term,
        field,
      },
      'admins',
    );

    if (optionLoad) {
      AdminService.fetchAdmins(numberPage, limitItems, sortFieldName, nameDirection, term, field).then((response) => {
        setUsers(response.data.rows);
        setCountItems(response.data.count);
        setSpinner(false);
      });
    }
  }, [numberPage, term, limitItems, field, optionLoad]);

  const changeCurrentPage = (page) => {
    setNumberPage(page);
  };

  const getQuerySearch = (query, field) => {
    if (watch) {
      setTerm(query);
      setField(field);
      setNumberPage(1);
    }
    setWatch(true);
  };
  const getLimitItems = (count) => {
    if (watch) {
      setLimitItems(count);
      setNumberPage(1);
    }
    setWatch(true);
  };
  const sortTable = (fieldName, direct, event) => {
    if (countItems > 1) {
      if (fieldName === sortFieldName) {
        direct = !direct;
        setSortDirection(direct);
      } else {
        setSortDirection(true);
        direct = true;
        setSortFieldName(fieldName);
      }

      const direction = direct ? 'ASC' : 'DESC';
      setNameDirection(direction);
      const selectorSorting = $(document).find('.sorting');

      selectorSorting.removeClass('sorting_asc');
      selectorSorting.removeClass('sorting_desc');

      if (direct) {
        event.target.classList.add('sorting_asc');
        event.target.classList.remove('sorting_desc');
      } else {
        event.target.classList.add('sorting_desc');
        event.target.classList.remove('sorting_asc');
      }

      adminStore.setQueryOptions(
        {
          numberPage,
          limitItems,
          sortFieldName: fieldName,
          nameDirection: direction,
          term,
          field,
        },
        'admins',
      );

      AdminService.fetchAdmins(numberPage, limitItems, fieldName, direction, term, field).then((response) => {
        setUsers(response.data.rows);
        setCountItems(response.data.count);
        setSpinner(false);
      });
    }
  };

  return (
    <LayoutTable panelTitle={'Admins Table'}>
      <SearchForm getQuerySearch={getQuerySearch} getLimitItems={getLimitItems} isButton={isAdmin} tableId={'admins'}>
        <option value="email">Email</option>
        <option value="firstName">First Name</option>
        <option value="lastName">Last Name</option>
      </SearchForm>
      <table className="table dataTable table-hover table-bordered align-middle table-striped mb-0 text-dark">
        <thead>
          <tr>
            <TableTh
              sortTable={sortTable}
              sortParams={{ fieldName: 'role', direction: sortDirection }}
              name="Role"
              tooltip={'Admin Role'}
            />
            <TableTh
              sortTable={sortTable}
              sortParams={{ fieldName: 'email', direction: sortDirection }}
              name="Email Address"
            />
            <TableTh
              sortTable={sortTable}
              sortParams={{ fieldName: 'firstName', direction: sortDirection }}
              name="First Name"
            />
            <TableTh
              sortTable={sortTable}
              sortParams={{ fieldName: 'lastName', direction: sortDirection }}
              name="Last Name"
            />
            <TableTh
              sortTable={sortTable}
              sortParams={{ fieldName: 'isActivated', direction: sortDirection }}
              name="Account status"
            />
            {isAdmin && <TableThWithoutSort name={'Actions'} tooltip={'View account page'} />}
          </tr>
        </thead>
        <tbody>
          {spinner ? (
            <tr>
              <td>
                <Spinner />
              </td>
            </tr>
          ) : (
            users &&
            users.map((user) => (
              <TableAdminRow
                key={user.id}
                id={user.id}
                role={user.role}
                email={user.email}
                firstName={user.firstName}
                lastName={user.lastName}
                isActivated={user.isActivated ? 'Active' : 'Deactivated'}
              />
            ))
          )}
        </tbody>
      </table>
      <div className="row">
        <div className="col-sm-12 col-md-5">
          <TableInfo
            startItemsCurrentPage={startItemsCurrentPage}
            endItemsCurrentPage={endItemsCurrentPage}
            countItems={countItems}
          />
        </div>
        <div className="col-sm-12 col-md-7">
          {isPaginate && <PaginationPage items={paginationConfig} changeCurrentPage={changeCurrentPage} />}
        </div>
      </div>
    </LayoutTable>
  );
};

export default TableAdmins;
