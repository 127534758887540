import React from 'react';
import '../assets/scss/pages/registration-form.scss';
import '../assets/scss/pages/user-verify.scss';
import '../assets/scss/pages/message-page.scss';
import LayoutForm from '../components/user/LayoutForm';
import FormButton from '../components/user/FormButton';
import { LOGIN_ROUTE } from '../utils/consts';

const UserMessagePasswordResetSuccessfully = () => {
  return (
    <LayoutForm
      classSelector={'user-verify message-page message-reset-password'}
      iconShow={true}
      classParentSelector={'icon-top icon-outside-right'}
      title={'Password reset successfully'}
    >
      <span>Your password has been changed</span>
      <div className={'block-button'}>
        <FormButton path={LOGIN_ROUTE} color={'red'} name={'Back to Sign In'} />
      </div>
    </LayoutForm>
  );
};

export default UserMessagePasswordResetSuccessfully;
