import React, { useContext } from 'react';
import { Context } from '../../index';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const MenuItem = ({ name, link, icon, isActive }) => {
  const { adminStore } = useContext(Context);

  const collapse = () => {
    if (adminStore.isSidebarCollapsed) {
      adminStore.setSidebarCollapsed(false);
    }
  };

  return (
    <div className={`menu-item has-sub ${isActive ? 'active' : ''}`} onClick={collapse}>
      <Link to={link} className="menu-link" title={name}>
        <div className="menu-icon">
          <i className={`fa ${icon}`}> </i>
        </div>
        <div className="menu-text"> {name}</div>
      </Link>
    </div>
  );
};

export default MenuItem;

MenuItem.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
  icon: PropTypes.string,
  isActive: PropTypes.bool,
};
