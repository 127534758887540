export const ADMIN_ROUTE = '/admin';
export const ADMIN_PROFILE_ROUTE = '/admin/profile';
export const ADMIN_VIEW_PROFILE_ROUTE = '/admin/admin-profile/:id';
export const ADMIN_VIEW_PROFILE_LINK_ROUTE = '/admin/admin-profile/';
export const ADMIN_TABLE_ROUTE = '/admin/table-admins';
export const ADMIN_TABLE_PINS_ROUTE = '/admin/table-pins';
export const ADMIN_TABLE_USERS_ROUTE = '/admin/table-users';
export const ADMIN_USER_PROFILE_ROUTE = '/admin/user-profile/:id';
export const ADMIN_USER_PROFILE_LINK_ROUTE = '/admin/user-profile/';
export const ADMIN_REGISTRATION_ROUTE = '/admin/registration';
export const ADMIN_LOGIN_ROUTE = '/admin/login';
export const ADMIN_RESET_PASSWORD_ROUTE = '/admin/reset-password/:id/:token';
export const ADMIN_FORGOT_PASSWORD_ROUTE = '/admin/forgot-password';
export const ADMIN_SETTINGS_ROUTE = '/admin/controls';
export const ADMIN_LOG_ROUTE = '/admin/log';
export const LOGIN_ROUTE = '/login';
export const USER_FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const USER_RESET_PASSWORD_ROUTE = '/reset-password';
export const USER_VERIFICATION_CODE_ROUTE = '/verify-code';
export const USER_MESSAGE_PASSWORD_RESET_ROUTE = '/reset-password-message';
export const USER_EXPIRED_ACTIVATION_LINK_ROUTE = '/expired-activation-link';
export const REGISTRATION_ROUTE = '/registration';
export const USER_VERIFY_AGE_ROUTE = '/verify-age';
export const USER_ROUTE = '/user';
export const INDEX_ROUTE = '/';
export const ERROR_PAGE_ROUTE = '/404';
export const USER_VERIFY_ROUTE = '/user/verify';
export const USER_ACCOUNT_CREATED_ROUTE = '/user/account-created';
export const USER_PIN_AUTHORIZATION_ROUTE = '/user/pin-authorization';
export const USER_VERIFY_MESSAGE_ROUTE = '/user/verify-message';
export const USER_ACTIVATE_PIN_ROUTE = '/user/activate-pin';
export const USER_ADD_PIN_ROUTE = '/user/add-pin';
export const USER_MESSAGE_ADDED_PIN_ROUTE = '/user/message-added-pin';
export const USER_MESSAGE_MAXIMUM_NUMBER_DEVICES_ROUTE = '/user/maximum-number-devices';
export const USER_FORGET_DEVICE_ROUTE = '/user/forget-device';
export const USER_MESSAGE_FORGET_DEVICE_ROUTE = '/user/message-forget-device';

export const roles = {
  admin: {
    name: 'ADMIN',
    value: 'IT Admin',
  },
  member: {
    name: 'MEMBER',
    value: 'Customer support',
  },
  customer: {
    name: '',
    value: 'Customer',
  },
};
