import React from 'react';

const ModalDialogFaq = () => {
  return (
    <>
      <h3>Can children create an account?</h3>
      <p>
        No, children are not permitted to create an account or enter their personal information. An adult needs to
        set-up the account in order to use the ornament.
      </p>

      <h3>Why is registration required?</h3>
      <p>
        The registration by an adult allows kids to have a safe online experience by delivering secure messages from
        Santa.
      </p>

      <h3>Why are messages not playing on my device?</h3>
      <p>
        Ensure that you have followed the registration process. Also turn on your browser’s ‘cookies’ so that you
        receive all messages in order.
      </p>

      <h3>What is a SAFE SCAN PIN code and where is it located?</h3>
      <p>
        A Safe Scan PIN is a 7-digit alpha-numeric code on the back of your ornament. Keep this with the ornament for
        future use.
      </p>

      <h3>What do I do if I’m having additional technical issues?</h3>
      <p>
        For questions, information or to share your stories of kindness, please email Hello@SantaKindness.com or call
        855-223-5692.
      </p>

      <h3>What is a QR code?</h3>
      <p>
        A QR code is similar to a barcode. When you scan your Santa Kindness Ornament with the camera of your phone or
        digital tablet, it takes you to see video messages from Santa!
      </p>

      <h3>How do I scan a QR code?</h3>
      <p>
        Simply open the camera on your phone or tablet. Point it at the QR code. The built-in scanner will scan the code
        and take you to see videos from Santa.{' '}
      </p>
      <h3>What if my phone doesn’t have a QR code scanner?</h3>
      <p>You should be able to download a third-party QR code reader from most phone app stores. </p>
      <h3>Is the Santa’s Kindness technology secure?</h3>
      <p>Yes, we’ve partnered with security elves that make sure your data is secure.</p>
      <h3>Am I able to use the QR code if I don’t have a way to scan it?</h3>
      <p>No. You must scan the QR code to see Santa’s videos. </p>
      <h3>What’s included in the box?</h3>
      <p>Santa’s Kindness Ornament with QR code and an interactive 80-page Kindness Journal.</p>
      <h3>Can more journals be purchased?</h3>
      <p>
        Yes, additional journals are available for purchase for families with more than one child. Visit demdaco.com to
        find a store near you or order on the site.
      </p>
      <h3>When does Santa’s Kindness Ornament start?</h3>
      <p>
        Starting Dec 1st with multiple daily video messages from Santa until Christmas and other messages periodically
        throughout the year.
      </p>
      <h3>How many videos a day?</h3>
      <p>
        2-3 video messages per day Dec 1st – 23rd and more on Dec 24th to follow Santa all day long and as he takes off
        for his Christmas Eve flight.
      </p>
      <h3>Does the experience meet accessibility requirements?</h3>
      <p>Yes, plus all videos are captioned so that those with hearing challenges can still participate.</p>
      <h3>Is Santa’s Kindness Ornament child safety tested?</h3>
      <p>
        Yes. The ornament is designed to be kid safe and the digital experience is COPPA Compliant and a ho-ho-whole lot
        of fun.
      </p>
      <h3>What is the age range for this product?</h3>
      <p>Designed for kids ages 3+ and kids at heart of all ages.</p>
      <h3>Are batteries required?</h3>
      <p>
        No batteries are required. Just scan the QR code with your phone or tablet to start seeing messages from Santa!
      </p>
      <h3>What is the hole in the back of the ornament for?</h3>
      <p>
        You can add a holiday light string bulb to enhance the colors throughout the ornament while hanging on tree.
      </p>
      <h3>Is this ornament reusable the following year?</h3>
      <p>
        Yes, the QR code will be active each holiday season for basic content. Each year, we will deliver new ways to
        enhance the experience for families.
      </p>
      <h3>Is this Journal reusable the following year?</h3>
      <p>We recommend new journals each season for increased memory keeping as children grow. </p>
      <h3>Can Santa’s Kindness Ornament be purchased separately/without the journal?</h3>
      <p>No, the ornament may only be purchased together with the journal. </p>
      <h3>Is the QR code active all year?</h3>
      <p>
        Yes, the QR/URL link is active all year. Some videos will be available periodically throughout the year to
        encourage continued kindness.”
      </p>
    </>
  );
};

export default ModalDialogFaq;
