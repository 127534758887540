import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/scss/pages/registration-form.scss';
import { USER_MESSAGE_PASSWORD_RESET_ROUTE } from '../utils/consts';
import AuthUserService from '../services/AuthUserService';
import LayoutForm from '../components/user/LayoutForm';
import FormButtonSubmit from '../components/user/FormButtonSubmit';
import { Context } from '../index';
import { validationPassword } from '../utils/validationForm';

const UserResetPassword = () => {
  const { userStore } = useContext(Context);
  const history = useNavigate();
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const passRef = useRef(null);
  const emailRef = useRef(null);
  const emailInput = emailRef.current;
  const user = userStore.user;

  const resetPassword = async (e) => {
    e.preventDefault();
    const err = {};
    err.pass = validationPassword(password, passRef.current, true, true);

    setErrors(err);
    try {
      if (err.pass === 'valid') {
        await AuthUserService.resetPassword(user.id, password).then((data) => {
          if (data?.status === 200) {
            history(USER_MESSAGE_PASSWORD_RESET_ROUTE);
          }
        });

        emailInput.classList.remove('is-valid');
      }
    } catch (e) {
      return { error: true, message: e.response?.data?.message };
    }
  };
  return (
    <LayoutForm classSelector={''} title={'Reset password'}>
      <span>Please enter your new password</span>
      <form action="" method="">
        <div className="input-text">
          <input
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            type="password"
            className=""
            id="password"
            placeholder="Create a password"
            required
          />
          <label htmlFor="password">Password</label>
        </div>
        <div className={`input-text ${!errors.pass || errors.pass === 'valid' ? '' : 'box-invalid'}`}>
          <input ref={passRef} type="password" className="" id="re-password" placeholder="Confirm Password" />
          <div className="invalid-feedback">{errors.pass}</div>
          <label htmlFor="re-password">Confirm Password</label>
        </div>

        <FormButtonSubmit name={'Reset password'} type={'submit'} color={'red'} callback={resetPassword} />
      </form>
    </LayoutForm>
  );
};

export default UserResetPassword;
