import React, { useContext, useEffect, useRef, useState } from 'react';
import { Context } from '../index';
import { observer } from 'mobx-react-lite';
import '../assets/scss/pages/registration-form.scss';
import '../assets/scss/pages/login-form.scss';
import FormButtonSubmit from '../components/user/FormButtonSubmit';
import LayoutForm from '../components/user/LayoutForm';
import { getPlatform } from '../utils/getPlatform';
import AuthUserService from '../services/AuthUserService';
import { USER_MESSAGE_FORGET_DEVICE_ROUTE } from '../utils/consts';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner';

const UserForgetDevice = () => {
  const { userStore, adminStore } = useContext(Context);
  const formRef = useRef(null);
  const user = userStore.user;
  const [currentPin, setCurrentPin] = useState(userStore.pinIdAuthorization || user.currentPin.id);
  const [pinRows, setPinRows] = useState([]);
  const history = useNavigate();
  const [disableButton, setDisableButton] = useState(true);
  const [newDevice, setNewDevice] = useState({});
  const [pinId, setPinId] = useState(currentPin);
  const numberDevices = adminStore.numberDevices;
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    setCurrentPin(userStore.pinIdAuthorization || user.currentPin.id);
    const pinArray = user.pins?.rows.filter((item) => item.id === currentPin);
    setPinRows(pinArray);
  }, [user.pins?.rows, user.currentPin?.id]);

  const numberEnabledPins = (pinRows) => {
    return pinRows.filter((pin) => !pin.blocked);
  };

  useEffect(() => {
    setDisableButton(true);
    setPinId(currentPin || numberEnabledPins(pinRows)[0]?.id);
    if (pinId) {
      AuthUserService.bindDeviceToPin(pinId, navigator.userAgent, false).then((response) => {
        setNewDevice(response.data.device);
        setSpinner(false);
      });
    }
  }, [user, pinId, userStore.pinIdAuthorization]);

  const countCheckedDevices = () => {
    const form = formRef.current;
    const numberCheckbox = form.querySelectorAll('[name^="' + pinId + '_"]').length;
    const formData = form.querySelectorAll('[type="checkbox"]:checked');
    let iter = 0;

    formData.forEach((el) => {
      const data = el.name.split('_');
      const pin = data[0];
      if (Number(pin) === pinId) {
        iter++;
      }
    });

    const numberActiveCheckbox = numberCheckbox - iter;

    if (
      (numberDevices >= numberActiveCheckbox && formData.length > 0) ||
      (!user.currentPin.id && formData.length > 0)
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };
  const removeDevice = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const arr = [];
    formData.forEach((el) => {
      arr.push(Number(el));
    });
    try {
      await AuthUserService.removeDevices(arr).then((response) => {
        if (response.data.success) {
          userStore.setDeletedDevices(response.data.results);
          history(USER_MESSAGE_FORGET_DEVICE_ROUTE);
        }
      });
    } catch (e) {
      console.error(e.response.data.message);
      window.location.reload();
    }
  };

  const handleClick = () => {
    history(-1);
  };

  if (spinner) return <Spinner />;
  else
    return (
      <LayoutForm classSelector={'login-form forget-device'} title={'Forget device'}>
        <span>Select device(s) to remove from your account.</span>
        <form action="" method="" ref={formRef} onSubmit={removeDevice}>
          <h5>Select device:</h5>
          {pinRows &&
            pinRows.map(
              (pin) =>
                pin.countDevices !== 0 && (
                  <div key={pin.id} className="input-checkbox">
                    <h6 className={'heading-pin'}>{pin.name ? `${pin.name} (${pin.pin})` : pin.pin}:</h6>
                    {(pin.id === newDevice.pinCodeId || pin.id === user.currentPin.id) && (
                      <div className="form-check input-disabled">
                        <input
                          onChange={countCheckedDevices}
                          className="form-check-input"
                          type="checkbox"
                          value={newDevice.id}
                          name={`${pin.id}_${newDevice.id}`}
                          checked={false}
                          id={`flexCheck${newDevice.id}`}
                        />
                        <label className="form-check-label" htmlFor={`flexCheck${newDevice.id}`}>
                          {getPlatform(newDevice.title)}
                        </label>
                      </div>
                    )}
                    {pin.devices &&
                      pin.devices.map(
                        (device) =>
                          newDevice.id !== device.id && (
                            <div key={device.id} className="form-check">
                              <input
                                onChange={countCheckedDevices}
                                className="form-check-input"
                                type="checkbox"
                                value={device.id}
                                name={`${pin.id}_${device.id}`}
                                defaultChecked={false}
                                id={`flexCheck${device.id}`}
                              />
                              <label className="form-check-label" htmlFor={`flexCheck${device.id}`}>
                                {getPlatform(device.title)}
                              </label>
                            </div>
                          ),
                      )}
                  </div>
                ),
            )}
          <FormButtonSubmit
            name={'Forget device'}
            color={disableButton ? 'default' : 'red'}
            callback={(e) => e}
            disabled={disableButton}
            type={'submit'}
          />
          <div className="text-center my-3">
            <p className="h5">OR</p>
          </div>
          <div className="text-center">
            <Link className="h5" onClick={handleClick}>
              Back
            </Link>
          </div>
        </form>
      </LayoutForm>
    );
};

export default observer(UserForgetDevice);
