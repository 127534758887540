import React, { useContext, useEffect, useState } from 'react';
import AdminService from '../services/AdminService';
import '../assets/css/dataTables.bootstrap5.min.css';
import '../assets/css/admin.css';
import $ from 'jquery';
import PaginationPage from '../components/PaginationPage';
import TableTh from '../components/admin/TableTh';
import TableInfo from '../components/admin/TableInfo';
import TableUserRow from '../components/admin/TableUserRow';
import SearchForm from '../components/admin/SearchForm';
import LayoutTable from '../components/admin/LayoutTable';
import Spinner from '../components/Spinner';
import TableThWithoutSort from '../components/admin/TableThWithoutSort';
import TableUserPinsRow from '../components/admin/TableUserPinsRow';
import moment from 'moment/moment';
import { getPlatform } from '../utils/getPlatform';
import { Context } from '../index';

const TableUsers = () => {
  const { adminStore } = useContext(Context);
  const [spinner, setSpinner] = useState(true);
  const [users, setUsers] = useState([]);
  const [countItems, setCountItems] = useState(0);
  const [numberPage, setNumberPage] = useState(1);
  const [field, setField] = useState();
  const [sortDirection, setSortDirection] = useState(true);
  const [nameDirection, setNameDirection] = useState('ASC');
  const [sortFieldName, setSortFieldName] = useState('id');
  const [term, setTerm] = useState();
  const [date, setDate] = useState({});
  const [watch, setWatch] = useState(false);
  const [limitItems, setLimitItems] = useState(10);
  const [optionLoad, setOptionLoad] = useState(false);
  const lastPage = limitItems * numberPage;
  const endItemsCurrentPage = lastPage <= countItems ? lastPage : countItems;
  const startItemsCurrentPage =
    lastPage <= countItems ? endItemsCurrentPage - limitItems + 1 : lastPage - limitItems + 1;
  const isPaginate = limitItems < countItems;
  const paginationConfig = {
    totalCount: countItems,
    limit: limitItems,
    page: numberPage,
  };

  useEffect(() => {
    if (adminStore.queryOptions.users) {
      const optionUsers = adminStore.queryOptions.users;
      setNumberPage(optionUsers.numberPage || numberPage);
      setLimitItems(optionUsers.limitItems || limitItems);
      setSortFieldName(optionUsers.sortFieldName || sortFieldName);
      setNameDirection(optionUsers.nameDirection || nameDirection);
      setTerm(optionUsers.term || term);
      setField(optionUsers.field || field);
      setDate(optionUsers.date || date);
    }
    setOptionLoad(true);
  }, []);

  useEffect(() => {
    adminStore.setQueryOptions(
      {
        numberPage,
        limitItems,
        sortFieldName,
        nameDirection,
        term,
        field,
        date,
      },
      'users',
    );

    if (optionLoad) {
      AdminService.fetchUsers(numberPage, limitItems, sortFieldName, nameDirection, term, field, date).then(
        (response) => {
          setUsers(response.data.rows);
          setCountItems(response.data.count);
          setSpinner(false);
        },
      );
    }
  }, [numberPage, term, limitItems, date, field, optionLoad]);

  const changeCurrentPage = (page) => {
    setNumberPage(page);
  };

  const getQuerySearch = (query, field) => {
    if (watch) {
      setTerm(query.trim());
      setField(field);
      setNumberPage(1);
    }
    setWatch(true);
  };

  const getLimitItems = (count) => {
    if (watch) {
      setLimitItems(count);
      setNumberPage(1);
    }
    setWatch(true);
  };
  const sortTable = (fieldName, direct, event) => {
    if (countItems > 1) {
      if (fieldName === sortFieldName) {
        direct = !direct;
        setSortDirection(direct);
      } else {
        setSortDirection(true);
        direct = true;
        setSortFieldName(fieldName);
      }

      const direction = direct ? 'ASC' : 'DESC';
      setNameDirection(direction);
      const selectorSorting = $(document).find('.sorting');

      selectorSorting.removeClass('sorting_asc');
      selectorSorting.removeClass('sorting_desc');

      if (direct) {
        event.target.classList.add('sorting_asc');
        event.target.classList.remove('sorting_desc');
      } else {
        event.target.classList.add('sorting_desc');
        event.target.classList.remove('sorting_asc');
      }

      adminStore.setQueryOptions(
        {
          numberPage,
          limitItems,
          sortFieldName: fieldName,
          nameDirection: direction,
          term,
          field,
          date,
        },
        'users',
      );

      AdminService.fetchUsers(numberPage, limitItems, fieldName, direction, term, field, date).then((response) => {
        setUsers(response.data.rows);
        setCountItems(response.data.count);
        setSpinner(false);
      });
    }
  };

  const maxNumberDevices = (dataUsers) => {
    let maxNumberDevices = 0;
    dataUsers.forEach((el) => {
      let maxElement = 0;
      if (el.pins.length > 0) {
        el.pins.forEach((el) => {
          if (maxElement < el.countDevices) maxElement = el.countDevices;
        });
      }
      maxNumberDevices = maxNumberDevices > maxElement ? maxNumberDevices : maxElement;
    });
    return maxNumberDevices;
  };
  const parseDevice = (arrDevices, quantityDevices) => {
    if (quantityDevices) {
      let quantity = quantityDevices - arrDevices.length;
      quantity = Number(quantity) > 0 ? quantity : 0;
      const arrEmpty = [...Array(quantity * 2)].map(() => '');
      const arrCurrentDevices = arrDevices.map((device) => {
        const lastLogin = device.lastLogin ? moment(device.lastLogin).format('MM/DD/YY hh:mm:ss a') : '';
        return [getPlatform(device.title), lastLogin];
      });
      return [].concat(...arrCurrentDevices, arrEmpty);
    }
    return [];
  };

  const deviceHeadings = (quantity) => {
    let serialNumber = 0;
    return [...Array(quantity * 2)].map((title, i) => {
      if (i % 2 === 0) {
        serialNumber++;
        title = 'Device ' + serialNumber;
      } else {
        title = 'Device ' + serialNumber + ' last login';
      }
      return title;
    });
  };

  const exportCSV = async () => {
    const dataUsers = await AdminService.fetchUsers(0, 0, sortFieldName, nameDirection, term, field, date, true).then(
      (response) => {
        return response.data.rows;
      },
    );

    const normalizeArray = [];
    const quantityDevices = maxNumberDevices(dataUsers);
    normalizeArray.push([
      'Email address',
      'Ornament PIN code',
      'Ornament name',
      'Ornament devices quantity',
      'Marketing subscription status',
      'PIN Status',
      'Account creation date',
      'Email confirmation status',
      ...deviceHeadings(quantityDevices),
    ]);

    dataUsers.forEach((el) => {
      const emailAddress = el.email;
      const emailConfirmationStatus = el.isActivated ? 'Confirmed' : 'Not confirmed';
      const emailSubscribeStatus = el.marketingEmail ? 'Subscribed' : 'Not subscribed';
      const accountCreationDate = moment(el.createdAt).format('MM/DD/YY hh:mm:ss a');
      let ornamentCode = '';
      let ornamentName = '';
      let ornamentDeviceQuantity = 0;
      let pinStatus = '';
      let checkPin = true;

      el.pins.forEach((ornament) => {
        ornamentCode = ornament.pin;
        ornamentName = ornament.name ? ornament.name.replace(/[,;]/g, ' /') : '';
        ornamentDeviceQuantity = ornament.countDevices;
        pinStatus = ornament.status ? 'Activated' : 'Deactivated';

        normalizeArray.push([
          emailAddress,
          ornamentCode,
          ornamentName,
          ornamentDeviceQuantity,
          emailSubscribeStatus,
          pinStatus,
          accountCreationDate,
          emailConfirmationStatus,
          ...parseDevice(ornament.devices, quantityDevices),
        ]);

        checkPin = false;
      });

      if (checkPin) {
        normalizeArray.push([
          emailAddress,
          ornamentCode,
          ornamentName,
          ornamentDeviceQuantity,
          emailSubscribeStatus,
          pinStatus,
          accountCreationDate,
          emailConfirmationStatus,
          ...parseDevice([], quantityDevices),
        ]);
      }
    });

    let csvContent = 'data:text/csv;charset=utf-8,';

    normalizeArray.forEach((rowArray) => {
      const row = rowArray.join(',');
      csvContent += row + '\r\n';
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'Consumers report.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const getDateRange = (startDate, endDate) => {
    setDate({ startDate, endDate });
  };

  return (
    <LayoutTable panelTitle={'Users Table'}>
      <SearchForm
        getQuerySearch={getQuerySearch}
        getLimitItems={getLimitItems}
        getDateRange={getDateRange}
        isButton={false}
        buttonName={'import csv'}
        buttonRoute={'#'}
        exportCSV={exportCSV}
        isExport={true}
        isFilter={true}
        tableId={'users'}
      >
        <option value="email">Email</option>
        <option value="pin">Pin</option>
      </SearchForm>
      <table className="table dataTable table-hover table-bordered align-middle table-striped mb-0 text-dark">
        <thead>
          <tr>
            <TableTh
              sortTable={sortTable}
              sortParams={{ fieldName: 'email', direction: sortDirection }}
              name="Email Address"
              tooltip={'Email address of customer account'}
            />
            <TableThWithoutSort name="Ornament PIN" tooltip={'Ornament PIN code'} />
            <TableThWithoutSort name="Ornament Name" tooltip={'Nick name of the ornament'} />

            <TableThWithoutSort name="PIN Status" tooltip={'Registration status for the PIN – registered or not'} />
            <TableTh
              sortTable={sortTable}
              sortParams={{ fieldName: 'marketingEmail', direction: sortDirection }}
              name="Subscription status"
              tooltip={'Marketing email status'}
            />
            <TableThWithoutSort
              name={'Actions'}
              tooltip={'Edit User / Block PIN with no access to video / Resend activation link'}
            />
          </tr>
        </thead>
        <tbody>
          {spinner ? (
            <tr>
              <td>
                <Spinner />
              </td>
            </tr>
          ) : (
            users &&
            users.map((coll) =>
              coll.pins.length === 0 ? (
                <TableUserRow key={coll.id} id={coll.id} email={coll.email} marketingEmail={coll.marketingEmail} />
              ) : (
                <TableUserPinsRow
                  key={coll.id}
                  id={coll.id}
                  email={coll.email}
                  pins={coll.pins}
                  marketingEmail={coll.marketingEmail}
                />
              ),
            )
          )}
        </tbody>
      </table>
      <div className="row">
        <div className="col-sm-12 col-md-5">
          <TableInfo
            startItemsCurrentPage={startItemsCurrentPage}
            endItemsCurrentPage={endItemsCurrentPage}
            countItems={countItems}
          />
        </div>
        <div className="col-sm-12 col-md-7">
          {isPaginate && <PaginationPage items={paginationConfig} changeCurrentPage={changeCurrentPage} />}
        </div>
      </div>
    </LayoutTable>
  );
};

export default TableUsers;
