import React, { useContext, useEffect, useRef, useState } from 'react';
import { Context } from '../../index';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import FormButtonSubmit from './FormButtonSubmit';
import '../../assets/scss/pages/registration-form.scss';
import ModalDialogBoxContent from './ModalDialogBoxContent';
import { INDEX_ROUTE, USER_PIN_AUTHORIZATION_ROUTE, USER_VERIFY_MESSAGE_ROUTE } from '../../utils/consts';
import LayoutForm from './LayoutForm';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import siteKey from '../../config.json';
import $ from 'jquery';
import { validationPassword } from '../../utils/validationForm';
import Spinner from '../Spinner';
import ModalDialogTerms from './ModalDialogTerms';
import ModalDialogPolicy from './ModalDialogPolicy';

const RegistrationForm = () => {
  const { userStore } = useContext(Context);
  const checkTerm = useRef();
  const history = useNavigate();
  const [email, setEmail] = useState('');
  const [terms, setTerms] = useState(false);
  const [marketEmail, setMarketEmail] = useState(false);
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const passRef = useRef(null);
  const passInput = passRef.current;
  const emailRef = useRef(null);
  const emailInput = emailRef.current;
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    if (userStore.isAuth) {
      history(USER_PIN_AUTHORIZATION_ROUTE, { replace: true });
    }
    setSpinner(userStore.isLoading);
  }, [userStore.isAuth, userStore.isLoading]);

  useEffect(() => {
    if (localStorage.getItem('verifyAge') !== 'true') {
      history(INDEX_ROUTE);
    }

    ReactRecaptcha3.init(siteKey.REACT_APP_SITE_KEY).then(() => {
      const observer = new MutationObserver(function () {
        if (document.contains($('.grecaptcha-badge')[0]) && $('#inline-badge').is(':empty')) {
          $(document).find('.grecaptcha-badge').clone().appendTo('#inline-badge');
          observer.disconnect();
        }
      });
      observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
    });
    return () => {
      ReactRecaptcha3.destroy();
      $(document).find('.grecaptcha-badge').remove();
    };
  }, []);

  const signUp = async (e) => {
    e.preventDefault();
    const err = {};

    if (!email) {
      err.email = 'Required';
      emailInput.classList.add('is-invalid');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      err.email = 'Invalid email address';
      emailInput.classList.add('is-invalid');
    } else {
      err.email = 'valid';
      emailInput.classList.remove('is-invalid');
    }

    if (!terms) {
      checkTerm.current.classList.add('is-invalid');
    } else {
      checkTerm.current.classList.remove('is-invalid');
    }

    err.pass = validationPassword(password, passInput, true);

    setErrors(err);
    try {
      if (err.pass === 'valid' && err.email === 'valid' && terms) {
        ReactRecaptcha3.getToken().then((verifyCaptcha) => {
          userStore.registration(email, password, marketEmail, verifyCaptcha).then((response) => {
            if (response?.error) {
              emailInput.classList.add('is-invalid');
              setErrors({ email: response.message });
            } else {
              emailInput.classList.remove('is-valid');
              history(USER_VERIFY_MESSAGE_ROUTE);
            }
          });
          emailInput.classList.remove('is-valid');
        });
      }
    } catch (e) {
      return { error: true, message: e.response?.data };
    }
  };

  if (spinner) return <Spinner />;
  else
    return (
      <LayoutForm classSelector={''} title={'Create Account'}>
        <span>A parent must create your account</span>
        <form action="" method="">
          <div className={`input-text ${!errors.email || errors.email === 'valid' ? '' : 'box-invalid'}`}>
            <input
              ref={emailRef}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              className=""
              id="emailAddress"
              placeholder="example@gmail.com"
              required
            />
            <div className="invalid-feedback">{errors.email}</div>
            <label htmlFor="emailAddress" className="">
              Parent&apos;s Email
            </label>
          </div>

          <div className="input-text">
            <input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
              className=""
              id="password"
              placeholder="Create a password"
              required
            />
            <label htmlFor="password" className="">
              Password
            </label>
          </div>
          <div
            className={`input-text ${!errors.pass || errors.pass === 'valid' ? '' : 'box-invalid'} ${
              errors.pass && errors.pass !== 'Required' && errors.pass !== 'Password mismatch' ? 'mb-e' : ''
            }`}
          >
            <input
              ref={passRef}
              type="password"
              className=""
              id="re-password"
              placeholder="Confirm password"
              required
            />
            <div className="invalid-feedback">
              {errors.pass}
              {errors.pass && errors.pass !== 'Required' && errors.pass !== 'Password mismatch' ? '.' : ''}
              <br />
              {errors.pass && errors.pass !== 'Required' && errors.pass !== 'Password mismatch'
                ? 'Please see password requirements below.'
                : ''}
              <br />
            </div>
            <label htmlFor="re-password" className="">
              Confirm Password
            </label>
          </div>
          <div className="mt-n1">
            Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter
            and one digit
          </div>
          <div className="input-checkbox">
            <label htmlFor="term">
              <input ref={checkTerm} type="checkbox" onChange={() => setTerms(!terms)} id={'term'} />
              <span>
                I agree to the{' '}
                <a href="#" data-bs-toggle="modal" data-bs-target={'#modalTerm'}>
                  Terms & Conditions
                </a>{' '}
                and that I have read and understand the{' '}
                <a href="#" data-bs-toggle="modal" data-bs-target={'#modalPolicy'}>
                  Privacy Policy
                </a>
                .
              </span>
            </label>

            <label htmlFor="market_email">
              <input onChange={() => setMarketEmail(!marketEmail)} type="checkbox" id={'market_email'} />
              <span className="nsc-r">Subscribe to exclusive DEMDACO news and promotions</span>
            </label>
          </div>
          <div className={'inline-badge'} id="inline-badge"></div>

          <FormButtonSubmit
            name={'Next'}
            type={'submit'}
            color={terms ? 'red' : 'default'}
            disabled={!terms}
            callback={signUp}
          />
        </form>
        <ModalDialogBoxContent title={'SKO Terms of Use'} modalId={'modalTerm'} classSelector={'full-height'}>
          <ModalDialogTerms />
        </ModalDialogBoxContent>
        <ModalDialogBoxContent title={'SKO Privacy Policy'} modalId={'modalPolicy'} classSelector={'full-height'}>
          <ModalDialogPolicy />
        </ModalDialogBoxContent>
      </LayoutForm>
    );
};

export default observer(RegistrationForm);
