import $api from '../http';

export default class AuthUserService {
  static async login(email, password, verifyCaptcha) {
    return $api.post(
      'api/user/login',
      { email, password, verifyCaptcha },
      {
        withCredentials: true,
        // credentials: "same-origin",
        credentials: 'include',
        // headers: {'Content-Type': 'application/json' }
      },
    );
  }

  static async registration(email, password, marketEmail, verifyCaptcha) {
    return $api.post('api/user/registration', { email, password, marketEmail, verifyCaptcha });
  }

  static async logout() {
    return $api.post('api/user/logout');
  }

  static update(id, password, firstName, lastName) {
    return $api.put('api/admin/update', { id, password, firstName, lastName });
  }

  static activatePinStatus(userId, email, marketingEmail, pin, device) {
    return $api.put('api/user/activate-pin', { userId, email, marketingEmail, pin, device });
  }

  static removeDevices(devices) {
    return $api.put('api/user/remove-devices', { devices });
  }

  static bindDeviceToPin(pinId, device, isCurrentDevice = true) {
    return $api.post('api/user/device-pin', { pinId, device, isCurrentDevice });
  }

  static updatePinStatus(id, status) {
    return $api.put('api/admin/update-pin-status', { id, status });
  }

  static delete(id) {
    return $api.delete('api/user/delete/' + id);
  }

  static sendEmailResetPassword(email) {
    return $api.post('api/user/reset-password', { email });
  }

  static sendCodeResetPassword(code, email) {
    return $api.post('api/user/verify-code', { code, email });
  }

  static resetPassword(id, password) {
    return $api.post(`api/user/reset-password/${id}`, { password });
  }
}
