import React from 'react';
import PropTypes from 'prop-types';

const LayoutTable = ({ panelTitle, children, addMargin = true }) => {
  return (
    <div className={`panel panel-inverse ${addMargin ? 'm-1' : ''}`}>
      <div className="panel-heading undefined">
        <h4 className="panel-title">{panelTitle}</h4>
      </div>
      <div className="panel-body">
        <div className="dataTables_wrapper">{children}</div>
      </div>
    </div>
  );
};

export default LayoutTable;

LayoutTable.propTypes = {
  children: PropTypes.array,
  panelTitle: PropTypes.string,
  addMargin: PropTypes.bool,
};
