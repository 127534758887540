import React, { useEffect, useState } from 'react';
import '../../assets/css/dataTables.bootstrap5.min.css';
import '../../assets/css/admin.css';
import '../../assets/scss/components/table-log.scss';
import $ from 'jquery';
import TableTh from '../../components/admin/TableTh';
import LayoutTable from '../../components/admin/LayoutTable';
import Spinner from '../../components/Spinner';
import TableRowLog from '../../components/admin/TableRowLog';
import PropTypes from 'prop-types';
import { roles } from '../../utils/consts';
import moment from 'moment';

const TableAdminLog = ({ log, tableName, fileName = 'System action log', addMargin = true, error }) => {
  const [spinner, setSpinner] = useState(true);
  const [logs, setLogs] = useState([]);
  const [defaultLogs, setDefaultLogs] = useState([]);
  const [foundLogs, setFoundLogs] = useState([]);
  const [countItems, setCountItems] = useState(0);
  const [sortDirection, setSortDirection] = useState(true);
  const [sortFieldName, setSortFieldName] = useState('date');
  const [querySearch, setQuerySearch] = useState('');

  useEffect(() => {
    setLogs(log);
    setFoundLogs(log);
    setDefaultLogs(log);
    setCountItems(log.length);
    setSpinner(false);
  }, [log]);

  const concatRoleName = (objProfile, role = objProfile.admin.role) => {
    let adminName =
      objProfile.admin?.firstName || objProfile.admin?.lastName
        ? objProfile.admin?.firstName + ' ' + objProfile.admin?.lastName
        : objProfile.admin?.email;
    adminName = adminName ? '(' + adminName.replace(/^\s+/g, '') + ')' : '';
    return role + adminName;
  };
  const sortable = (logs, field, direct) => {
    switch (field) {
      case 'role':
        if (direct)
          return logs.sort((a, b) => {
            const prev = a.admin === null ? roles.customer.value : concatRoleName(a);
            const next = b.admin === null ? roles.customer.value : concatRoleName(b);
            return prev > next ? 1 : -1;
          });
        return logs.sort((a, b) => {
          const prev = a.admin === null ? roles.customer.value : concatRoleName(a);
          const next = b.admin === null ? roles.customer.value : concatRoleName(b);
          return prev < next ? 1 : -1;
        });
      case 'action':
        if (direct) return logs.sort((a, b) => (a.actionType.name > b.actionType.name ? 1 : -1));
        return logs.sort((a, b) => (a.actionType.name < b.actionType.name ? 1 : -1));
      case 'newValue':
        if (direct) return logs.sort((a, b) => (a.value > b.value ? 1 : -1));
        return logs.sort((a, b) => (a.value < b.value ? 1 : -1));
      case 'date':
        if (direct) return logs.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
        return logs.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
      default:
        if (direct) return logs.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
        return logs.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
    }
  };

  const search = (logs, searchInput) => {
    if (searchInput.length > 0) {
      return logs.filter((item) => {
        let role = item.admin === null ? roles.customer.value : item.admin.role;

        if (role === roles.member.name) {
          role = roles.member.value;
        } else if (role === roles.admin.name) {
          role = roles.admin.value;
        } else {
          role = roles.customer.value;
        }
        role = concatRoleName(item, role);
        const date = moment(item.createdAt).format('MM/DD/YY hh:mm:ss a');
        return (
          role.toUpperCase().match(searchInput.toUpperCase()) ||
          item.actionType.name?.toUpperCase().match(searchInput.toUpperCase()) ||
          item.value?.toString().toUpperCase().match(searchInput.toUpperCase()) ||
          date.toString().toUpperCase().match(searchInput.toUpperCase())
        );
      });
    }
    return defaultLogs;
  };
  const getQuerySearch = (e) => {
    const value = e.currentTarget.value;
    const arrSearch = search(defaultLogs, value);
    setQuerySearch(value);
    setFoundLogs(arrSearch);
    setLogs(arrSearch);
    setCountItems(arrSearch.length);
  };

  const sortTable = (fieldName, direct, event) => {
    if (countItems > 1) {
      if (fieldName === sortFieldName) {
        direct = !direct;
        setSortDirection(direct);
      } else {
        setSortDirection(true);
        direct = true;
        setSortFieldName(fieldName);
      }

      const selectorSorting = $(document).find('.sorting');

      selectorSorting.removeClass('sorting_asc');
      selectorSorting.removeClass('sorting_desc');

      if (direct) {
        event.target.classList.add('sorting_asc');
        event.target.classList.remove('sorting_desc');
      } else {
        event.target.classList.add('sorting_desc');
        event.target.classList.remove('sorting_asc');
      }

      const sortArr = sortable(foundLogs, fieldName, direct);
      const defaultSortArr = sortable(defaultLogs, fieldName, direct);
      setDefaultLogs(defaultSortArr);
      setLogs(sortArr);
      setCountItems(logs.length);
      setSpinner(false);
    }
  };

  const exportCSV = () => {
    const normalizeArray = [];
    normalizeArray.push(['Role', 'Action', 'New value', 'Date']);

    logs.forEach((el) => {
      let roleName = '';
      const admin = el.admin;
      const role = admin?.role;

      if (role === roles.member.name) {
        roleName = roles.member.value;
      } else if (role === roles.admin.name) {
        roleName = roles.admin.value;
      } else {
        roleName = roles.customer.value;
      }
      const name = concatRoleName(el, roleName);
      const action = el.actionType.name.replace(/[,;]/g, ' /');
      const newValue = el.value.replace(/[,;]/g, ' /');
      const date = moment(el.createdAt).format('MM/DD/YY hh:mm:ss a');

      normalizeArray.push([name, action, newValue, date]);
    });

    let csvContent = 'data:text/csv;charset=utf-8,';

    normalizeArray.forEach((rowArray) => {
      const row = rowArray.join(',');
      csvContent += row + '\r\n';
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName + '.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <LayoutTable panelTitle={tableName} addMargin={addMargin}>
      <div className="row table-action-panel">
        <div className="col-sm-12 col-md-6 d-flex align-items-center">
          <button type="button" className="btn btn-gray me-2 mb-1 default" onClick={exportCSV}>
            Export CSV
          </button>
          <div id="data-table-default_info" role="status" aria-live="polite">
            {countItems > 0 ? `Showing ${countItems === 1 ? '1 entry' : countItems + ' entries'}` : 'No matches found'}
          </div>
          {error && <div className={'text-red'}>Error: {error}</div>}
        </div>
        <div className="d-flex gap-3 justify-content-end align-items-center col-sm-12 col-md-6">
          <div id="data-table-default_filter" className="dataTables_filter">
            <label>
              Search:
              <input
                value={querySearch}
                onChange={getQuerySearch}
                type="search"
                className="form-control form-control-sm"
                placeholder=""
                aria-controls="data-table-default"
              />
            </label>
          </div>
        </div>
      </div>
      <div className={`${addMargin ? '' : 'row'} table-log`}>
        <table className="table dataTable table-hover table-bordered align-middle table-striped mb-0 text-dark">
          <thead>
            <tr>
              <TableTh
                sortTable={sortTable}
                sortParams={{ fieldName: 'role', direction: sortDirection }}
                name="Role"
                tooltip={'Role'}
              />
              <TableTh
                sortTable={sortTable}
                sortParams={{ fieldName: 'action', direction: sortDirection }}
                name="Action"
                tooltip={'Action'}
              />
              <TableTh
                sortTable={sortTable}
                sortParams={{ fieldName: 'newValue', direction: sortDirection }}
                name="New value"
              />
              <TableTh sortTable={sortTable} sortParams={{ fieldName: 'date', direction: sortDirection }} name="Date" />
            </tr>
          </thead>
          <tbody>
            {spinner ? (
              <tr>
                <td>
                  <Spinner />
                </td>
              </tr>
            ) : (
              logs.length > 0 &&
              logs.map((coll) => (
                <TableRowLog
                  key={coll.id}
                  admin={coll.admin}
                  action={coll.actionType.name}
                  newValue={coll.value}
                  date={coll.createdAt}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
    </LayoutTable>
  );
};

export default TableAdminLog;
TableAdminLog.propTypes = {
  log: PropTypes.array,
  tableName: PropTypes.string,
  fileName: PropTypes.string,
  addMargin: PropTypes.bool,
  error: PropTypes.string,
};
