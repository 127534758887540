import React, { useState } from 'react';
import HeaderNav from './HeaderNav';
import { Link } from 'react-router-dom';
import { ADMIN_TABLE_PINS_ROUTE } from '../../utils/consts';

const NavbarHeader = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    if (!showSidebar) document.getElementById('sidebar').style.left = 0;
    else document.getElementById('sidebar').style = '';
  };
  return (
    <div id="header" className="app-header">
      <div className="navbar-header">
        <Link to={ADMIN_TABLE_PINS_ROUTE} className="navbar-brand">
          <span className="navbar-logo"></span>
          <b>Dashboard&#8195;</b>Admin
        </Link>
        <button
          type="button"
          className="navbar-mobile-toggler"
          data-toggle="app-sidebar-mobile"
          onClick={toggleSidebar}
        >
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
      </div>
      <HeaderNav />
    </div>
  );
};

export default NavbarHeader;
