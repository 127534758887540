import $api from '../http';

export default class AuthService {
  static async login(email, password, verifyCaptcha) {
    return $api.post('api/admin/login', { email, password, verifyCaptcha });
  }

  static async registration(email, password, firstName, lastName, role) {
    return $api.post('api/admin/registration', { email, password, firstName, lastName, role });
  }

  static async logout() {
    return $api.post('api/admin/logout');
  }

  static update(id, password, firstName, lastName) {
    return $api.put('api/admin/update', { id, password, firstName, lastName });
  }

  static updateProfile(id, email, password, firstName, lastName, role) {
    return $api.put('api/admin/update-profile', { id, email, password, firstName, lastName, role });
  }

  static updatePinStatus(id, status, comment = ' ') {
    return $api.put('api/admin/update-pin-status', { id, status, comment });
  }

  static delete(id) {
    return $api.delete('api/admin/delete/' + id);
  }

  static sendEmailResetPassword(email) {
    return $api.post('api/admin/password-reset', { email });
  }

  static resetPassword(id, token, password) {
    return $api.post(`api/admin/password-reset/${id}/${token}`, { password });
  }
}
