import React from 'react';
import '../assets/scss/pages/registration-form.scss';
import '../assets/scss/pages/user-verify.scss';
import LayoutForm from '../components/user/LayoutForm';
import { useSearchParams } from 'react-router-dom';

const UserExpiredActivationLink = () => {
  const [searchParams] = useSearchParams();
  searchParams.get('statusUser');
  return (
    <LayoutForm
      classSelector={'user-verify'}
      title={searchParams.get('statusUser') ? 'Incorrect activation link' : 'Your Link has Expired'}
    ></LayoutForm>
  );
};

export default UserExpiredActivationLink;
