import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AdminService from '../../services/AdminService';
import PropTypes from 'prop-types';

const ButtonResendEmail = ({ email }) => {
  const [buttonName, setButtonName] = useState('Resend email');
  const resendEmail = async () => {
    setButtonName('Sending...');
    await AdminService.resendEmailActivateLink(email).then(() => {
      setButtonName('Resend email');
    });
  };
  return (
    <Link to={'#'} className={'btn btn-lime default me-1 mb-1'} onClick={resendEmail}>
      {buttonName}
    </Link>
  );
};

export default ButtonResendEmail;

ButtonResendEmail.propTypes = {
  email: PropTypes.string,
};
