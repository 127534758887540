import React, { useEffect, useState } from 'react';
import ModalDialogBox from './ModalDialogBox';
import AuthService from '../../services/AuthService';
import { Link } from 'react-router-dom';
import { ADMIN_USER_PROFILE_LINK_ROUTE } from '../../utils/consts';
import PropTypes from 'prop-types';
import CommentForm from './CommentForm';

const TableTdButtonGroup = ({ pinId, status, userId, marketingEmail }) => {
  const [isActive, setActive] = useState(status);
  const [newMessage, setNewMessage] = useState('');
  const [classButton, setClassButton] = useState('disabled');

  useEffect(() => {
    if (newMessage && newMessage.length >= 2) setClassButton('');
    else setClassButton('disabled');
  }, [newMessage]);

  const toggleActive = async () => {
    setActive(!isActive);
    await AuthService.updatePinStatus(pinId, !isActive, newMessage);
  };
  return (
    <>
      <td>{isActive ? 'Registered' : 'Not Registered'}</td>
      <td>{marketingEmail ? 'Subscribed' : 'Unsubscribed'}</td>
      <td>
        <Link to={ADMIN_USER_PROFILE_LINK_ROUTE + userId} className={'btn btn-success me-1 mb-1'}>
          View profile
        </Link>
        {isActive ? (
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target={'#modalAlert_' + pinId}
            className="btn btn-gray me-1 mb-1 default"
          >
            Deactivate
          </button>
        ) : (
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target={'#modalAlert_' + pinId}
            className="btn btn-default me-1 mb-1 default"
          >
            Activate
          </button>
        )}

        <ModalDialogBox
          title={'Warning'}
          messageTitle={' Are you sure you want to change the status?'}
          buttonName={'Submit'}
          buttonEvent={toggleActive}
          modalId={'modalAlert_' + pinId}
          classModalBox={'modal-comment'}
          classButton={classButton}
        >
          <CommentForm id={pinId} setNewMessage={setNewMessage} newMessage={newMessage} />
        </ModalDialogBox>
      </td>
    </>
  );
};

export default TableTdButtonGroup;

TableTdButtonGroup.propTypes = {
  pinId: PropTypes.number,
  status: PropTypes.bool,
  userId: PropTypes.number,
  marketingEmail: PropTypes.bool,
};
