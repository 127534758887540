import React from 'react';
import PropTypes from 'prop-types';
import { roles } from '../../utils/consts';
import Moment from 'react-moment';

const TableRowLog = ({ admin, action, newValue, date }) => {
  let roleName = '';
  let adminName = '';

  if (admin) {
    adminName = admin?.firstName || admin?.lastName ? admin?.firstName + ' ' + admin?.lastName : admin?.email;
    adminName = adminName ? ' (' + adminName.replace(/^\s+/g, '') + ')' : '';
  }

  const role = admin?.role;
  if (role === roles.member.name) {
    roleName = roles.member.value;
  } else if (role === roles.admin.name) {
    roleName = roles.admin.value;
  } else {
    roleName = roles.customer.value;
  }
  return (
    <tr>
      <td>{roleName + adminName}</td>
      <td>{action}</td>
      <td>{newValue}</td>
      <td>
        <Moment format="MM/DD/YY hh:mm:ss a">{date}</Moment>
      </td>
    </tr>
  );
};

export default TableRowLog;

TableRowLog.propTypes = {
  admin: PropTypes.object,
  action: PropTypes.string,
  newValue: PropTypes.string,
  date: PropTypes.string,
};
