import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ADMIN_LOGIN_ROUTE } from '../utils/consts';
import AuthService from '../services/AuthService';

const AdminForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');
  const emailRef = useRef(null);
  const emailInput = emailRef.current;
  const history = useNavigate();
  const sendEmailResetPassword = async () => {
    try {
      await AuthService.sendEmailResetPassword(email.toLowerCase()).then((response) => {
        if (response?.error) {
          emailInput.classList.add('is-invalid');
          emailInput.classList.remove('is-valid');
          setMessage(response.message);
        } else {
          emailInput.classList.remove('is-invalid');
          emailInput.classList.add('is-valid');
          setMessage(response?.data?.message);
          history(ADMIN_LOGIN_ROUTE);
        }
      });
    } catch (e) {
      emailInput.classList.add('is-invalid');
      emailInput.classList.remove('is-valid');
      setMessage(e.response?.data?.message);
    }
  };

  const sendEmail = () => {
    const err = {};
    if (!email) {
      err.email = 'Required';
      emailInput.classList.remove('is-valid');
      emailInput.classList.add('is-invalid');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      err.email = 'Invalid email address';
      emailInput.classList.remove('is-valid');
      emailInput.classList.add('is-invalid');
    } else {
      err.email = '';
      emailInput.classList.remove('is-invalid');
    }

    setErrors(err);
    if (err.email === '') {
      sendEmailResetPassword();
    }
  };

  return (
    <div className="login login-v1 reset-password">
      <div className="login-container">
        <div className="login-header">
          <div className="brand">
            <div className="d-flex align-items-center">Reset Password</div>
          </div>
          <div className="icon">
            <i className="fa fa-lock"></i>
          </div>
        </div>
        <div className="login-body">
          <div className="login-content fs-13px">
            <form action="" method="">
              <div className="form-floating mb-20px">
                <input
                  ref={emailRef}
                  onChange={(e) => setEmail(e.target.value)}
                  data-parsley-type="email"
                  value={email}
                  type="email"
                  className="form-control fs-13px h-45px"
                  placeholder="Email address"
                />
                <div className="valid-feedback">Entered correctly</div>
                <div className="invalid-feedback">{errors.email}</div>
                {message && <div className="valid-tooltip">{message}</div>}
                {message && <div className="invalid-tooltip">{message}</div>}

                <label htmlFor="password" className="d-flex align-items-center py-0">
                  Email
                </label>
              </div>

              <div className="login-buttons mb-20px">
                <button type="button" onClick={sendEmail} className="btn h-45px btn-success w-100 btn-lg">
                  Send Email
                </button>
              </div>
              <div className="text-gray-500">
                {' '}
                Click&nbsp;
                <Link to={ADMIN_LOGIN_ROUTE} className="text-white">
                  here
                </Link>
                &nbsp;to login.
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminForgotPassword;
