import React, { useEffect, useState } from 'react';
import 'bootstrap';
import PropTypes from 'prop-types';

const ModalImportCSV = ({ title, messageTitle, buttonName, modalId, fileName, resMessage }) => {
  const [loadStatus, setLoadStatus] = useState('');
  const [message, setMessage] = useState({});
  const [blockModal, setBlockModal] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(false);

  useEffect(() => {
    if (resMessage.error) {
      setLoadStatus('error');
      setBlockModal('');
      setMessage({ error: resMessage.error });
      setDisabledBtn(true);
    } else if (resMessage.message) {
      setLoadStatus('success');
      setBlockModal('');
      setMessage({ success: resMessage.message });
      setDisabledBtn(true);
    } else if (loadStatus === 'loading') {
      setDisabledBtn(true);
    } else {
      setLoadStatus('');
      setBlockModal('');
      setMessage({});
      setDisabledBtn(false);
    }
  }, [loadStatus, resMessage]);
  const loadProcess = () => {
    setLoadStatus('loading');
    setBlockModal('blocked-modal');
  };

  const renderLoadStatus = (param) => {
    switch (param) {
      case 'loading':
        return (
          <div className="fa-3x  text-center">
            <i className="fas fa-spinner fa-pulse text-success"></i>
            <h5>{fileName?.name || 'File'} is processing. Please stand by!</h5>
          </div>
        );
      case 'success':
        return (
          <div className="fa-3x  text-center text-success">
            <i className="fas fa-circle-check"></i>
            <h5>Processing complete!</h5>
            <h5>{message.success}</h5>
          </div>
        );
      case 'error':
        return (
          <div className="fa-3x  text-center text-red">
            <i className="fas fa-circle-exclamation"></i>
            <h5>{message.error}</h5>
          </div>
        );
      default:
        return (
          <h5>
            <i className="fa fa-info-circle"></i>
            {messageTitle}
          </h5>
        );
    }
  };

  return (
    <div className={`modal fade  `} id={modalId} aria-modal="true" data-blocked={blockModal} role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
          </div>
          <div className="modal-body">
            <div className={'alert'}>{renderLoadStatus(loadStatus)}</div>
          </div>
          <div className="modal-footer">
            <a href="" className="btn btn-white" data-bs-dismiss="modal">
              Close
            </a>
            <input
              type="submit"
              value={buttonName}
              className={`btn btn-success`}
              disabled={disabledBtn}
              onClick={loadProcess}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalImportCSV;

ModalImportCSV.propTypes = {
  title: PropTypes.string,
  messageTitle: PropTypes.string,
  buttonName: PropTypes.string,
  modalId: PropTypes.string,
  fileName: PropTypes.object,
  resMessage: PropTypes.object,
};
