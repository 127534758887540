import React from 'react';
import screenReader from '../../utils/screenReader';

const ModalDialogPolicy = () => {
  screenReader('b, li');
  return (
    <div className={'modal-support'}>
      <p>
        This Privacy Policy describes how DD Traders, Inc. dba DEMDACO (“DEMDACO”, “we”, “us”, or “our”) collects, uses,
        stores, and shares your information when: (1) you access and/or use the Santa’s Kindness Ornament (“SKO”),
        including any related websites and/or mobile applications (“SKO Websites”); and (2) when you communicate with us
        in any manner concerning the SKO.
      </p>

      <h3>Acknowledgment</h3>
      <p className="fw-bold">
        BY USING THE SKO, SKO WEBSITES, OR COMMUNICATING WITH US IN ANY MANNER, YOU REPRESENT THAT YOU HAVE READ AND
        UNDERSTOOD THIS PRIVACY POLICY.
      </p>

      <h3>What Information Do We Collect?</h3>
      <p>
        We collect information from and about you when you use the SKO, SKO Websites, and communicate with us, including
        when you register an account, update your profile, access our content, make a purchase within the SKO and SKO
        Websites, or engage with our customer support team. This information includes:
      </p>
      <ul>
        <li>
          <b className="text-decoration-underline">Basic Identifying Information.</b>{' '}
          <span>
            Information such as your full name, telephone number, e-mail address, phone number, account name, username,
            and account password.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Device Identifier and other Unique Identifiers.</b>{' '}
          <span>
            Information we collect automatically when you engage with the SKO and SKO Websites, or engage in
            communications with us, such as your device identifier, internet protocol (IP) address, cookies, beacons,
            pixel tags, mobile ad identifier, and other unique identifiers. For more information, see Cookies and
            Related Technologies below.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Internet or Other Network Activity.</b>{' '}
          <span>Information regarding your interactions with the SKO Websites.</span>
        </li>
        <li>
          <b className="text-decoration-underline">Geolocation Information.</b>{' '}
          <span>Information that permits us to determine your general location, such as zip code.</span>
        </li>
        <li>
          <b className="text-decoration-underline">Commercial Information.</b>{' '}
          <span>Information such as your preferences and history of activity with the SKO and SKO Websites.</span>
        </li>
        <li>
          <b className="text-decoration-underline">Demographic Information.</b>{' '}
          <span>
            Information such as number of members in your household, your age, and other demographic information, from
            time to time.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">User Content.</b>{' '}
          <span>
            Information such as your communications with us and any other content you provide, such as comments, product
            reviews, testimonials, and other content.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Audio Information.</b>{' '}
          <span>
            Information such as recordings of your voice (such as when we record customer service calls for quality
            assurance).
          </span>
        </li>
      </ul>

      <h3>How Do We Use Your Information?</h3>
      <p>We may use the above information for a variety of purposes, including to:</p>
      <ul>
        <li>Fulfill your requests and respond to email questions;</li>
        <li>
          Support our core business functions, such as order fulfillment, internal business process management,
          authentication, loss and fraud prevention, and public safety functions;
        </li>
        <li>Communicate with you about the SKO and SKO Websites;</li>
        <li>Provide the SKO to you;</li>
        <li>Improve the SKO and SKO Websites;</li>
        <li>Statistically analyze the usage of the SKO and SKO Websites;</li>
        <li>Contact you;</li>
        <li>Resolve disputes, investigate problems, and enforce our terms;</li>
        <li>Help diagnose problems with our server, manage the SKO and SKO Websites, and to enhance the SKO;</li>
        <li>
          Perform a business transaction, such as a merger, acquisition, sale of assets, bankruptcy, or related
          transactions.
        </li>
      </ul>

      <h3>How Do We Disclose Your Information?</h3>
      <p>We may disclose your information in the following circumstances:</p>
      <ul>
        <li>
          <b className="text-decoration-underline">With Your Consent.</b>{' '}
          <span>
            We may disclose or make available your information with your consent, which may be obtained in writing,
            online, through “click-through” agreements, when you accept our terms for our Sites, or by other means.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">With Service Providers & Business Partners.</b>{' '}
          <span>
            We may disclose or make available your information with our third party service providers, affiliates and
            subsidiaries, business partners, those that provide customer service, and those third parties that help
            protect against malicious, deceptive, fraudulent, or illegal activity.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">In A Business Transfer.</b>{' '}
          <span>
            We may disclose or make available your information as part of a business transaction, such as a merger or
            acquisition, joint venture, corporate reorganization, financing, or sale of company assets, or in the
            unlikely event of insolvency, bankruptcy, or receivership, in which such information could be transferred to
            third parties as a business asset in the transaction.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">For Legal Process & Protection.</b>{' '}
          <span>
            We may disclose or make available your information to satisfy any law, regulation, legal process,
            governmental request, or where we have a good faith belief that access, use, preservation or disclosure of
            such information is reasonably necessary to: (1) enforce or apply agreements, or initiate, render, or bill
            for use of the Sites; (2) protect our rights or interests, property or safety or that of others; (3) in
            connection with claims, disputes, or litigation - in court or elsewhere; (4) protect users of our Sites and
            other carriers, providers, or partners from fraudulent, abusive, unlawful, or otherwise improper use of our
            Sites; (5) facilitate or verify the appropriate calculation of taxes, fees, or other obligations due to a
            local, state, or federal government.
          </span>
        </li>
      </ul>

      <h3>Cookies and Related Technologies</h3>
      <p>
        Cookies help us improve the SKO Websites providing us with information about which parts of the SKO Websites are
        most popular, enabling us to analyze technical and navigational information about the SKO Websites, and helping
        us to detect and prevent fraud. We also use cookies and other data collection tools (such as web beacons and
        server logs) to help improve your experience with the SKO Websites.
      </p>
      <p>
        We also use analytics services, such as Google Analytics, to help us understand how users access and use the SKO
        Websites, as well as help us understand market trends and popularity of our products and services. You can learn
        more about Google Analytics by vising{' '}
        <a href="https://policies.google.com/technologies/partner-sites">
          How Google uses information from sites or apps that use our services
        </a>
        .
      </p>

      <h3>How Do We Secure Your Information?</h3>
      <p>
        Although no system or website can guarantee the complete security of your information, we take all commercially
        reasonable steps to ensure your information is protected in accordance with all applicable laws and regulations,
        as appropriate to the sensitivity of your information.
      </p>

      <h3>How Long Do We Keep Your Information?</h3>
      <p>
        We keep your information for as long as is necessary in accordance with the purpose for which it was collected,
        our business needs, and our legal and regulatory obligations. If we dispose of your information, we will do so
        in a way that is secure and appropriate to nature of the information subject to disposal.
      </p>

      <h3>Your Choices</h3>
      <p>
        We provide you the ability to exercise certain controls over how we collect, use, and share your information.
        Please note, if you are a California resident, you may have additional rights (see Your California Rights
        below).
      </p>

      <ul>
        <li>
          <b className="text-decoration-underline">Right to Correction.</b>{' '}
          <span>
            You may at any time submit a request to change the information we have about you by sending us an e-mail
            with the subject line “CORRECTION” to{' '}
            <a href="mailto:customersupport@demdaco.com">customersupport@demdaco.com</a>.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Opt-Out of DEMDACO Communications.</b>{' '}
          <span>
            From time to time, we may send you various types of communications, including direct mail such as our
            catalogs, e-mails, marketing messages, and other informational messages. To opt-out of receiving our e-mail
            communications, please send us an e-mail with the subject line “UNSUBSCRIBE - EMAILS” at{' '}
            <a href="mailto:customersupport@demdaco.com">customersupport@demdaco.com</a> or by clicking “UNSUBSCRIBE” at
            the bottom of any e-mail you’ve received. To opt-out of receiving our direct mail communications, including
            our catalog, please send us an e-mail with the subject line “UNSUBSCRIBE - MAILING” at{' '}
            <a href="mailto:customersupport@demdaco.com">customersupport@demdaco.com</a> or by calling us at{' '}
            <a href="tel:8883363226">888.336.3226</a>. Please note, if you opt-out of receiving our direct mail
            communications, your information also will not be shared with our direct mail marketing partners. Please
            also note that even if you unsubscribe from our e-mail or direct mailing communications, we may still send
            you non-marketing messages relating to order confirmations and other transactional information.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">
            Opt-Out of Sharing Your Information For Advertising and Marketing Purposes.
          </b>{' '}
          <span>
            From time to time, we may share your information with third parties to facilitate certain advertising and
            marketing. Those third parties may, in some instances, use your information for their own commercial
            purposes. To opt-out of having your information shared these advertising and marketing partners, please send
            us an e-mail with the subject line “OPT-OUT” at{' '}
            <a href="mailto:customersupport@demdaco.com">customersupport@demdaco.com</a> or by calling us at{' '}
            <a href="tel:8883363226">888.336.3226</a>. If you are a California or Nevada resident, you may also submit a
            request to opt-out of the “sale” of your information by clicking here
          </span>{' '}
          <a href="https://privacy.demdaco.com/consumer/index?_ga=2.9636477.914379569.1613405674-1271772920.1607014367">
            Do Not Sell or Share My Personal Information
          </a>
          .
        </li>
        <li>
          <b className="text-decoration-underline">Managing Cookies.</b>{' '}
          <span>
            You may wish to restrict the use of cookies or completely prevent them from being set. Most browsers provide
            for ways to control cookies, such as the length of time they are stored. If you disable cookies, please be
            aware that some of the features of our Website may not function correctly. To find out more about how you
            can manage and delete cookies, please visit
          </span>{' '}
          <a href="https://www.demdaco.com/www.aboutcookies.org">www.aboutcookies.org</a>.{' '}
          <span>
            For more details on your choices regarding use of your web browsing activity for interest-based advertising,
            you may visit
          </span>{' '}
          <a href="http://networkadvertising.org">http://networkadvertising.org</a>,{' '}
          <a href="http://optout.aboutads.info">http://optout.aboutads.info</a>, or{' '}
          <a href="http://youradchoices.com">http://youradchoices.com</a>.
        </li>
      </ul>

      <h3>California Residents</h3>
      <h4>Definitions</h4>
      <ul>
        <li>
          <b className="text-decoration-underline">Personal information.</b>{' '}
          <span>
            Information that identifies, relates to, describes, is reasonably capable of being associated with, or could
            reasonably be linked, directly or indirectly, to you or your household.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Sensitive personal information.</b>{' '}
          <span>
            Social security number, driver’s license number, state identification card, passport number, account log-in
            and password, financial account and password, debit or credit card number and access code, precise
            geolocation information, race, ethnic origin, religious or philosophical beliefs, union membership, the
            content of your mail, email or texts other than those communications with us, genetic data, biometric
            information, health information, and information that concerns your sex life or sexual orientation.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Sell, sale, or sold.</b>{' '}
          <span>
            Selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise
            communicating orally, in writing, or other means, your personal information to a third party for money or
            other valuable consideration.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Share, shared, or sharing.</b>{' '}
          <span>
            Sharing, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise
            communicating orally, in writing, or other means, your personal information to a third party for
            cross-context behavioral advertising, whether for monetary or other valuable consideration.
          </span>
        </li>
      </ul>
      <h4>Your Rights</h4>
      <p>
        To process any request below, we must be able to verify your identity. In order to do so, you must provide the
        information we request as part of the verification process, such as your contact information and an additional
        identifier based on your relationship with us. Please note, you can designate an authorized agent to make the
        below requests on your behalf. When you use an authorized agent, you must provide the authorized agent with
        written permission to do so, and, in certain circumstances, we may ask you to verify your own identity directly
        with us. We may deny a request from an authorized agent that does not submit proof that they have been
        authorized by you to act on your behalf.
      </p>
      <p>
        To submit any of the requests below, you may send us an e-mail to{' '}
        <a href="mailto:customersupport@demdaco.com">customersupport@demdaco.com</a> or by contacting customer service
        by <a href="tel:8883363226">888.336.3226</a>.
      </p>
      <ul>
        <li>
          <b className="text-decoration-underline">Right to Know.</b>{' '}
          <span>
            You have a right to request that we disclose, up to two times per year, what personal information we’ve
            collected about you.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Right to Delete.</b>{' '}
          <span>You have a right to request deletion your personal information, subject to certain exceptions.</span>
        </li>
        <li>
          <b className="text-decoration-underline">Right to Correct.</b>{' '}
          <span>
            You have a right to ask that we correct the personal information we have about you, subject to certain
            exceptions.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">
            Right to Opt-Out of the Sale or Sharing of Your Personal Information.
          </b>{' '}
          <span>
            You have a right to opt-out of the sale or sharing of your personal information. You may exercise this right
            either through the submission process above or https://privacy.demdaco.com/consumer/index.
          </span>
        </li>
        <li>
          <b className="text-decoration-underline">Right to Non-Discrimination.</b>{' '}
          <span>You have a right to exercise the above rights without being discriminated against.</span>
        </li>
      </ul>

      <h4>Notice of Collection</h4>
      <ul>
        <li>
          To learn more about the categories of personal information we collect about California residents, please see
          What Information Do We Collect?
        </li>
        <li>
          For more information about how we use those categories of personal information, please see How Do We Use Your
          Information?
        </li>
        <li>
          For more information about how we collect categories of personal information, please see What Information Do
          We Collect and Cookies And Related Technologies above.
        </li>
        <li>
          To learn more about how we disclose categories of personal information, and the categories of third parties
          with whom we disclose such information, please see How Do We Disclose Your Information? above.
        </li>
        <li>To learn more about how long we keep your information, please see How Long Do We Keep Your Information?</li>
      </ul>

      <h4>Notice of Disclosure for a Business Purpose</h4>
      <p>
        To learn more about the categories personal information we have disclosed for a business purpose, and the
        categories of third parties with whom we’ve disclosed such information, please see How Do We Disclose Your
        Information? above.
      </p>

      <h4>Notice of Sale and Sharing</h4>
      <p>
        We “sell” and “share” your personal information through the use of certain Third Party tools on the Sites,
        including those relating to analytics and advertising. Specifically, we make available certain of your online
        identifiers and other persistent online identifiers with advertising, analytics, and marketing partners that may
        be considered a “sale” or “sharing” of your personal information, as defined under California law. We don’t sell
        or share the personal information or sensitive personal information of any California resident who is 16 years
        or younger.
      </p>

      <h4>Notice of Use of Sensitive Personal Information</h4>
      <p>
        We do not use California resident sensitive personal information for any purpose other than is permissible under
        the CCPA. Specifically, we do not use sensitive personal information of California residents to derive
        characteristics about California residents.
      </p>
      <p>7/19/2023</p>
      <p>
        <span>Contact us</span>
        <br />
        <a href="mailto:customersupport@demdaco.com">customersupport@demdaco.com</a>
        <br />
        <a href="tel:8883363226">888.336.3226</a>
      </p>
    </div>
  );
};

export default ModalDialogPolicy;
