import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/scss/components/layout-user-page.scss';
import imgBanner from '../../assets/images/banner.jpg';
import imgBannerMobile from '../../assets/images/banner-mobile.jpg';
import imgLogo from '../../assets/images/logo.png';
import PropTypes from 'prop-types';
import screenReader from '../../utils/screenReader';

const LayoutUserPage = ({ children }) => {
  screenReader('.block-info strong');
  return (
    <div className={'screen-reader'}>
      <header className="banner">
        <img src={imgBanner} className={'banner-img'} alt="Santa's Kindness" />
        <img src={imgBannerMobile} className={'banner-img-mobile'} alt="Santa's Kindness (mobile)" />

        <div className="logo">
          <Link to={'/'}>
            <img src={imgLogo} alt="Santa's Kindness (logo)" />
          </Link>
        </div>
      </header>
      <div className="container-form">{children}</div>
    </div>
  );
};

export default LayoutUserPage;

LayoutUserPage.propTypes = {
  children: PropTypes.node,
};
