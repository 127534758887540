import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Context } from '../index';
import { observer } from 'mobx-react-lite';
import '../assets/scss/pages/registration-form.scss';
import '../assets/scss/pages/pin-form.scss';
import ModalDialogBoxContent from '../components/user/ModalDialogBoxContent';
import InputPin from '../components/user/InputPin';
import $ from 'jquery';
import AuthUserService from '../services/AuthUserService';
import { useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE, USER_ACCOUNT_CREATED_ROUTE } from "../utils/consts";
import LayoutForm from '../components/user/LayoutForm';
import ModalDialogFaq from '../components/user/ModalDialogFaq';
import ModalDialogSupport from '../components/user/ModalDialogSupport';

const UserActivatePin = () => {
  const { userStore, adminStore } = useContext(Context);
  const history = useNavigate();
  const [errors, setErrors] = useState([]);
  const user = userStore.user;
  const [numChildren, setNumChildren] = useState(0);
  const [numberDevices, setNumberDevices] = useState(adminStore.numberDevices || '5');
  const children = [];

  useEffect(() => {
    setNumberDevices(adminStore.numberDevices);
  }, []);
  const removeComponent = (e, number) => {
    e.preventDefault();
    $(e.target)
      .parents('#input_ornament_' + number)
      .remove();
  };

  for (let i = 0; i < numChildren; i++) {
    children.push(<InputPin key={i + 1} number={i + 1} removeComponent={removeComponent} />);
  }
  const addComponent = (e) => {
    e.preventDefault();
    setNumChildren((count) => count + 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const arr = [];
    const pinArr = [];
    let iter = 0;
    let pinStr = '';
    let nameStr = '';
    if ($(form).find('input.is-invalid').length === 0) {
      formData.forEach((value) => {
        arr.push(value);
        iter++;
        if (iter <= 2) {
          if (iter === 1) {
            pinStr = value.toUpperCase();
          } else {
            nameStr = value;
          }
          if (iter === 2) {
            pinArr.push({ pin: pinStr, name: nameStr });
            iter = 0;
          }
        }
      });

      try {
        await AuthUserService.activatePinStatus(
          user.id,
          user.email,
          user.marketingEmail,
          pinArr,
          navigator.userAgent,
        ).then((response) => {
          if (response?.error) {
            setErrors(response.error);
          } else {
            setErrors([]);
            history(USER_ACCOUNT_CREATED_ROUTE);
          }
        });
      } catch (e) {
        if (e.response?.data?.errors[0] === 'user_error') {
          userStore.isAuth = false;
          localStorage.removeItem('tokenUser');
          history(LOGIN_ROUTE);
        }
        setErrors(e.response?.data?.errors);
        return { error: true, message: e.response?.data?.message };
      }
    }
  };
  return (
    <LayoutForm classSelector={'pin-form'} title={'Enter Safe Scan PIN'}>
      <div className={'form-info'}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
            fill="#006548"
          />
        </svg>
        Each ornament is limited to {numberDevices} devices.
      </div>
      <div className="error-info sc-r">
        {errors &&
          errors.map(
            (message, index) =>
              message && (
                <Fragment key={index}>
                  {message}
                  <br />
                </Fragment>
              ),
          )}
      </div>

      <form action="" method="" onSubmit={handleSubmit}>
        <InputPin number={0} />
        {children}

        <div className="block-add-component">
          <span>Have multiple ornaments?</span>
          <a href="#" onClick={addComponent}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 6.75H6.75V12H5.25V6.75H0V5.25H5.25V0H6.75V5.25H12V6.75Z" fill="#006548" />
            </svg>
            Add another ornament
          </a>
        </div>

        <div className="block-info">
          <strong>Can’t find your PIN?</strong>
          <a href="#" data-bs-toggle="modal" data-bs-target={'#modalFAQ'}>
            View FAQ
          </a>
          <a href="#" data-bs-toggle="modal" data-bs-target={'#modalSupport'}>
            Contact Support
          </a>
        </div>

        <button type="submit" className={'btn btn-red'}>
          Next
        </button>
      </form>
      <ModalDialogBoxContent title={'Frequently Asked Questions'} modalId={'modalFAQ'} classSelector={'full-height'}>
        <ModalDialogFaq />
      </ModalDialogBoxContent>
      <ModalDialogBoxContent title={'Support'} modalId={'modalSupport'}>
        <ModalDialogSupport />
      </ModalDialogBoxContent>
    </LayoutForm>
  );
};

export default observer(UserActivatePin);
