import React, { useContext } from 'react';
import '../assets/scss/pages/registration-form.scss';
import '../assets/scss/pages/user-verify.scss';
import '../assets/scss/pages/message-page.scss';
import LayoutForm from '../components/user/LayoutForm';
import FormButton from '../components/user/FormButton';
import { Context } from '../index';
import { getPlatform } from '../utils/getPlatform';

const UserMessageForgetDevice = () => {
  const { userStore, adminStore } = useContext(Context);
  const devices = userStore.deletedDevices;
  const token = '?token=' + localStorage.getItem('deviceToken');
  const playerUrl = adminStore.playerUrl + token;

  return (
    <LayoutForm
      classSelector={'user-verify message-page message-forget-device'}
      iconShow={false}
      classParentSelector={'icon-top'}
      title={'Device forgotten'}
    >
      <span>
        You have successfully removed the device(s)
        {devices.map((device, index) => `${index !== 0 ? ', ' : ' '}${getPlatform(device.name)}`)} from your account.
      </span>

      <div className={'block-button'}>
        <FormButton path={playerUrl} color={'red'} name={'Go to video'} />
      </div>
    </LayoutForm>
  );
};

export default UserMessageForgetDevice;
