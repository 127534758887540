import React, { useState } from 'react';
import PropTypes from 'prop-types';

const PaginationPage = ({ items, changeCurrentPage }) => {
  const pageCount = Math.ceil(items.totalCount / items.limit);
  const middleNumberPage = Math.ceil(pageCount / 2) || 10;
  const [centerNumberPage, setCenterNumberPage] = useState(middleNumberPage);
  const [isEllipsisLeft, setIsEllipsisLeft] = useState(true);
  const [isEllipsisRight, setIsEllipsisRight] = useState(true);
  const pages = [];
  const firstThreePages = [1, 2, 3];
  const lastThreePages = [2, 1, 0];
  const middleThreePages = [-1, 0, 1];

  if (pageCount <= 10 && pageCount !== 1) {
    let i = 0;
    while (i < pageCount) pages[i++] = i;
  }

  const prevPage = (page) => {
    if (page > 1) changeCurrentPage(page - 1);
  };
  const nextPage = (page) => {
    if (page < pageCount) changeCurrentPage(page + 1);
  };
  const currentMultiPage = (page) => {
    changeCurrentPage(page);
    // multi
    if (page === 3 || page === 5) {
      setCenterNumberPage(5);
      setIsEllipsisLeft(false);
      setIsEllipsisRight(true);
    } else if (page === 6) {
      setCenterNumberPage(6);
      setIsEllipsisLeft(true);
    } else if (page === centerNumberPage + 1 && page < pageCount - 4) {
      setCenterNumberPage(page);
    } else if (page === pageCount - 4) {
      setCenterNumberPage(page);
      setIsEllipsisRight(false);
    } else if (page === pageCount - 5) {
      setCenterNumberPage(page);
      setIsEllipsisRight(true);
    } else if (page === centerNumberPage - 1 && page > 4) {
      setCenterNumberPage(page);
    } else if (page === pageCount - 2) {
      setCenterNumberPage(pageCount - 4);
      setIsEllipsisRight(false);
      setIsEllipsisLeft(true);
    }
  };

  return (
    <div className="pagination">
      <div className={`page-item  ${items.page === 1 ? 'disabled' : ''}`}>
        <a href="#" className="page-link" onClick={() => prevPage(items.page)}>
          «
        </a>
      </div>
      {pageCount <= 10 ? (
        pages.map((page) => (
          <div
            key={page}
            className={`page-item ${items.page === page ? 'active' : ''}`}
            onClick={() => changeCurrentPage(page)}
          >
            <a href="#" className="page-link">
              {page}
            </a>
          </div>
        ))
      ) : (
        <>
          {firstThreePages.map((page) => (
            <div
              key={page}
              className={`page-item ${items.page === page ? 'active' : ''}`}
              onClick={() => currentMultiPage(page)}
            >
              <a href="#" className="page-link">
                {page}
              </a>
            </div>
          ))}

          {isEllipsisLeft && (
            <div
              className={'page-item-separate'}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 6,
                width: 20,
              }}
            >
              ...
            </div>
          )}

          {middleThreePages.map((page) => (
            <div
              key={page}
              className={`page-item ${items.page === centerNumberPage + page ? 'active' : ''}`}
              onClick={() => currentMultiPage(centerNumberPage + page)}
            >
              <a href="#" className="page-link">
                {centerNumberPage + page}
              </a>
            </div>
          ))}

          {isEllipsisRight && (
            <div
              className={'page-item-separate'}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 6,
                width: 20,
              }}
            >
              ...
            </div>
          )}

          {lastThreePages.map((page) => (
            <div
              key={page}
              className={`page-item ${items.page === pageCount - page ? 'active' : ''}`}
              onClick={() => currentMultiPage(pageCount - page)}
            >
              <a href="#" className="page-link">
                {pageCount - page}
              </a>
            </div>
          ))}
        </>
      )}
      <div className={`page-item  ${items.page === pageCount ? 'disabled' : ''}`}>
        <a href="#" className="page-link" onClick={() => nextPage(items.page)}>
          »
        </a>
      </div>
    </div>
  );
};

export default PaginationPage;

PaginationPage.propTypes = {
  items: PropTypes.object,
  changeCurrentPage: PropTypes.func,
};
