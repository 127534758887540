import React from 'react';
import imgLabel from '../../assets/images/snow.svg';
import PropTypes from 'prop-types';
import screenReader from '../../utils/screenReader';

const LayoutForm = ({ classSelector, classParentSelector = '', title, children, iconShow = false }) => {
  screenReader('.invalid-feedback');
  return (
    <div className={classParentSelector}>
      {iconShow && <img src={imgLabel} alt="icon" />}
      <div className={`screen-reader registration-form ${classSelector}`}>
        <header>
          <h1 className={'title-label'} tabIndex={0}>
            {title}
            {!iconShow && <img src={imgLabel} alt="icon" />}
          </h1>
        </header>
        {children}
      </div>
    </div>
  );
};

export default LayoutForm;
LayoutForm.propTypes = {
  title: PropTypes.string,
  classParentSelector: PropTypes.string,
  classSelector: PropTypes.string,
  children: PropTypes.node,
  iconShow: PropTypes.bool,
};
