import React, { Fragment, useEffect, useRef, useState } from 'react';
import AdminService from '../services/AdminService';
import { useParams } from 'react-router-dom';
import FieldPinEdit from '../components/admin/FieldPinEdit';
import '../assets/scss/pages/dashboard-uesr-detail-page.scss';
import TableAdminLog from '../components/admin/TableAdminLog';
import moment from 'moment/moment';

const DashboardUserDetailPage = () => {
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [email, setEmail] = useState('');
  const [emailDefault, setEmailDefault] = useState('');
  const [marketingEmail, setMarketingEmail] = useState(false);
  const [userId, setUserId] = useState();
  const [pins, setPins] = useState([]);
  const [isEdit, setIsEdit] = useState(true);
  const [log, setLog] = useState([]);
  const [fileName, setFileName] = useState('');
  const inputRef = useRef(null);
  const currentValue = inputRef.current;

  useEffect(() => {
    AdminService.fetchOneUserProfile(id).then((response) => {
      setEmail(response.data.email);
      setEmailDefault(response.data.email);
      setMarketingEmail(response.data.marketingEmail);
      setUserId(response.data.id);
      setPins(response.data.pins?.rows);
      const date = moment(new Date()).format('MM-DD-YY');
      setFileName('Actions log report _ ' + response.data.email + ' _ ' + date);
    });
    AdminService.getOneLog(id).then((response) => {
      setLog(response.data);
    });
  }, []);

  const hideInput = () => {
    setIsEdit(true);
    setEmail(emailDefault);
    setError(false);
  };
  const fieldEdit = () => {
    setIsEdit(false);
  };
  const emailSave = async () => {
    if (currentValue?.value) {
      try {
        await AdminService.updateUserEmail(userId, email).then((response) => {
          if (response?.error) {
            setError(true);
            setErrorMessage({ email: response.error.message });
          } else {
            setError(false);
            setErrorMessage({});
            setIsEdit(true);
          }
        });
      } catch (e) {
        setError(true);
        setErrorMessage({ email: e.response.data.message });
      }
    }
  };
  const subscribeStatusSave = async (value) => {
    setMarketingEmail(!marketingEmail);
    try {
      await AdminService.updateUserMarketingEmail(userId, value).then((response) => {
        if (response?.error) {
          setError(true);
          setErrorMessage({ subscribe: response.error.message });
        } else {
          setError(false);
          setErrorMessage({});
          setIsEdit(true);
        }
      });
    } catch (e) {
      setError(true);
      setErrorMessage({ subscribe: e.response.data.message });
    }
  };
  return (
    <div className={'profile-user'}>
      <div className="profile">
        <div className="profile-header">
          <div className="profile-header-cover"></div>
          <div className="profile-header-content">
            <div className="profile-header-info">
              <h4 className="mt-0 mb-1">{email}</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-content">
        <div className="tab-content p-0">
          <div className="tab-pane fade active show" id="profile-about">
            <div className="row">
              <div className="col-sm-12 col-md-7 pe-0">
                <div className="card mb-3">
                  <div className="panel panel-inverse  m-0">
                    <div className="panel-heading undefined">
                      <h4 className="panel-title">PIN code information</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    {pins &&
                      pins.map((pin) => (
                        <Fragment key={pin.id}>
                          <FieldPinEdit pin={pin} />
                        </Fragment>
                      ))}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-5 ps-0">
                <div className="card mb-3">
                  <div className="panel panel-inverse m-0">
                    <div className="panel-heading undefined">
                      <h4 className="panel-title">Consumer information</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Email</h6>
                      </div>
                      {isEdit ? (
                        <>
                          <div className="col-sm-5 text-secondary"> {email}</div>
                          <div className="col-sm-2 text-secondary">
                            <button onClick={fieldEdit} className="btn btn-primary ms-5px text-decoration-none fw-bold">
                              Edit
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-sm-5 text-secondary block-validate">
                            <input
                              ref={inputRef}
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                              type="email"
                              className={error ? 'border-red form-control' : 'form-control'}
                              autoFocus={!isEdit}
                            />
                            {error ? <span className={'text-red p-lg-2'}>{errorMessage.email}</span> : ''}
                          </div>
                          <div className="col-sm-4 text-secondary">
                            <button onClick={emailSave} className="btn btn-success ms-5px text-decoration-none fw-bold">
                              Save
                            </button>
                            <button
                              onClick={hideInput}
                              className="btn btn-default default ms-5px text-decoration-none fw-bold"
                            >
                              Cancel
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row align-items-center">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Subscription</h6>
                      </div>
                      <div className="col-sm-9 text-secondary block-validate">
                        <div className="form-check">
                          <input
                            onChange={() => subscribeStatusSave(!marketingEmail)}
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckChecked"
                            checked={marketingEmail}
                          />
                          <label className="form-check-label" htmlFor="flexCheckChecked">
                            Receive marketing emails
                          </label>
                          {error ? <span className={'text-red p-lg-2'}>{errorMessage.subscribe}</span> : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-3">
                  <TableAdminLog log={log} tableName={'Consumer actions log'} fileName={fileName} addMargin={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardUserDetailPage;
