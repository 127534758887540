import React, { useContext, useEffect, useRef, useState } from 'react';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';
import '../../assets/scss/pages/admin-registration-form.scss';
import { ADMIN_TABLE_PINS_ROUTE, roles } from '../../utils/consts';
import { useNavigate } from 'react-router-dom';
import { validationPassword } from '../../utils/validationForm';

const RegistrationForm = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState(roles.admin.name);
  const [password, setPassword] = useState('');
  const [isPassword, setIsPassword] = useState(false);
  const [messageActivate, setMessageActivate] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const history = useNavigate();
  const { adminStore } = useContext(Context);
  const admin = adminStore.admin;
  const isMember = admin.role === roles.member.name;
  const emailRef = useRef(null);
  const emailInput = emailRef.current;
  const passRef = useRef(null);
  const passInput = passRef.current;

  useEffect(() => {
    if (isMember) {
      history(ADMIN_TABLE_PINS_ROUTE);
    }
  });

  const validationForm = async () => {
    const err = {};
    if (!email) {
      err.email = 'Required';
      emailInput.classList.add('is-invalid');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      err.email = 'Invalid email address';
      emailInput.classList.add('is-invalid');
    } else {
      err.email = 'valid';
      emailInput.classList.remove('is-invalid');
    }

    if (passInput) {
      err.pass = validationPassword(password, passInput, false, true);
    } else {
      err.pass = 'valid';
    }

    setErrors(err);
    if (err.email === 'valid' && err.pass === 'valid') {
      setErrors({});

      await adminStore.registration(email, password, firstName, lastName, role).then((response) => {
        if (response?.error) {
          emailInput.classList.add('is-invalid');
          setErrorMessage(response.message);
        } else {
          emailInput.classList.remove('is-invalid');
          setEmail('');
          setFirstName('');
          setLastName('');
          setMessageActivate(true);
          setErrorMessage('');
          setPassword('');
          setTimeout(() => setMessageActivate(false), 5000);
        }
      });
    }
  };

  const showPassword = () => {
    setIsPassword(!isPassword);
    if (isPassword) {
      setPassword('');
      passInput.value = '';
    }
  };

  return (
    <div className="register register-with-news-feed">
      <div className="register-container">
        <div className="register-header mb-25px h1">
          <h4>Create new Account</h4>
          {messageActivate ? (
            <small className="d-block fs-15px lh-16 link-green">Account successfully created.</small>
          ) : (
            <small className="d-block fs-15px lh-16 text-red">{errorMessage}</small>
          )}
        </div>

        <div className="register-content">
          <form action="" method="" className="fs-13px" data-parsley-validate="true">
            <div className="mb-3">
              <label className="mb-2">
                Email <span className="text-danger">*</span>
              </label>
              <input
                ref={emailRef}
                onChange={(e) => setEmail(e.target.value)}
                data-parsley-type="email"
                value={email}
                type="email"
                className="form-control fs-13px"
                placeholder="Email"
              />
              <div className="valid-feedback">Entered correctly</div>
              <div className="invalid-feedback">{errors.email}</div>
            </div>
            <div className="mb-3">
              <label className="mb-2">Name</label>
              <div className="row gx-3">
                <div className="col-md-6 mb-2 mb-md-0">
                  <input
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    type="text"
                    className="form-control fs-13px"
                    placeholder="First name"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    type="text"
                    className="form-control fs-13px"
                    placeholder="Last name"
                  />
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label className="mb-2">System role</label>
              <select onChange={(e) => setRole(e.target.value)} className="form-select" name="role" value={role}>
                <option value={roles.admin.name}>{roles.admin.value}</option>
                <option value={roles.member.name}>{roles.member.value}</option>
              </select>
            </div>
            <div className="mb-3">
              <div className="form-check">
                <input
                  onChange={showPassword}
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Custom password field
                </label>
              </div>
            </div>
            {isPassword && (
              <div className={'box-password'}>
                <div className="mb-3">
                  <label className="mb-2">New Password</label>
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    type="password"
                    className="form-control fs-13px"
                    placeholder="Password"
                    title={'Password must include 6 characters, upper-case, lower-case, and number'}
                  />
                </div>
                <div className="mb-2">
                  <label className="mb-2">Confirm Password</label>
                  <input
                    ref={passRef}
                    type="password"
                    className="form-control fs-13px"
                    id="re-password"
                    placeholder="Confirm password"
                    title={'Password must include 6 characters, upper-case, lower-case, and number'}
                  />
                  <div className="invalid-feedback">{errors.pass}</div>
                </div>
                <div className="mb-3">
                  <span>Note: account will be automatically activated after creation</span>
                </div>
              </div>
            )}
            <div className="mb-4">
              <button onClick={validationForm} type="button" className="btn btn-primary w-100 btn-lg h-45px fs-13px">
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default observer(RegistrationForm);
