import React from 'react';
import { Link } from 'react-router-dom';
import { ADMIN_USER_PROFILE_LINK_ROUTE } from '../../utils/consts';
import ButtonResendEmail from './ButtonResendEmail';
import PropTypes from 'prop-types';

const TableUserRow = ({ id, email, marketingEmail }) => {
  return (
    <tr>
      <td>{email}</td>
      <td></td>
      <td></td>
      <td></td>
      <td>{marketingEmail ? 'Subscribed' : 'Unsubscribed'}</td>
      <td>
        <Link to={ADMIN_USER_PROFILE_LINK_ROUTE + id} className={'btn btn-success me-1 mb-1'}>
          Edit
        </Link>
        <ButtonResendEmail email={email} />
      </td>
    </tr>
  );
};

export default TableUserRow;

TableUserRow.propTypes = {
  id: PropTypes.number,
  email: PropTypes.string,
  marketingEmail: PropTypes.bool,
};
