export const validationPassword = (password, passInput, addClassValid = false, isRequired = false) => {
  const upperCaseRegex = /.*[A-Z]/;
  const lowerCaseRegex = /.*[a-z]/;
  const numberRegex = /.*\d/;

  if (isRequired && !password && !passInput?.value) {
    passInput.classList.add('is-invalid');
    passInput.focus();
    if (addClassValid) passInput.classList.remove('is-valid');
    return 'Required';
  } else if (password !== passInput.value) {
    passInput.classList.add('is-invalid');
    passInput.focus();
    if (addClassValid) passInput.classList.remove('is-valid');
    return 'Password mismatch';
  } else if (
    passInput.value.length < 8 ||
    !upperCaseRegex.test(passInput.value) ||
    !lowerCaseRegex.test(passInput.value) ||
    !numberRegex.test(passInput.value)
  ) {
    let passwordMessage = 'Password must include';
    passwordMessage += passInput.value.length < 8 ? ' 8 characters,' : '';
    passwordMessage += !upperCaseRegex.test(passInput.value) ? ' upper\u2011case,' : '';
    passwordMessage += !lowerCaseRegex.test(passInput.value) ? ' lower\u2011case,' : '';
    const numberValidation = passwordMessage.includes(',') ? ' and number' : ' number';
    passwordMessage += !numberRegex.test(passInput.value) ? numberValidation : '';
    passInput.classList.add('is-invalid');
    passInput.focus();
    if (addClassValid) passInput.classList.remove('is-valid');
    return passwordMessage.replace(/,\s*$/, '');
  } else {
    passInput.classList.remove('is-invalid');
    if (addClassValid) passInput.classList.add('is-valid');
    return 'valid';
  }
};
