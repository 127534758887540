import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import '../assets/scss/pages/registration-form.scss';
import '../assets/scss/pages/verify-age.scss';
import LayoutForm from '../components/user/LayoutForm';
import FormButtonSubmit from '../components/user/FormButtonSubmit';
import { REGISTRATION_ROUTE, USER_PIN_AUTHORIZATION_ROUTE } from '../utils/consts';
import { Context } from '../index';
import Spinner from '../components/Spinner';

const UserVerifyAge = () => {
  const { userStore } = useContext(Context);
  const monthRef = useRef(null);
  const yearsRef = useRef(null);
  const yearsField = yearsRef.current;
  const [checkValue, setCheckValue] = useState({ month: '', year: '' });
  const history = useNavigate();
  const [month, setMonth] = useState('');
  const [monthValue, setMonthValue] = useState('');
  const [years, setYears] = useState('');
  const [errors, setErrors] = useState('');
  const [isValid, setIsValid] = useState(false);
  const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    if (userStore.isAuth) {
      history(USER_PIN_AUTHORIZATION_ROUTE, { replace: true });
    }
    setSpinner(userStore.isLoading);
  }, [userStore.isAuth, userStore.isLoading]);

  useEffect(() => {
    if (checkValue.month && checkValue.year) {
      const formatMonth = Number(checkValue.month) < 10 ? 0 + checkValue.month : checkValue.month;
      const countYearsOld = calculateAge(`${checkValue.year}-${formatMonth}-01`);

      if (Number(countYearsOld) >= 18) {
        setIsValid(true);
        setErrors('');
        yearsField.classList.remove('is-invalid');
      } else {
        setIsValid(false);
        setErrors('Please ask your parent to create the account');
        yearsField.classList.add('is-invalid');
      }
    }
  }, [checkValue]);

  const yearsList = (startYear) => {
    const currentYear = new Date().getFullYear();
    const years = [];
    startYear = startYear || 1900;
    while (startYear <= currentYear) {
      years.unshift(startYear++);
    }
    return years;
  };

  const calculateAge = (dateString) => {
    const birthday = new Date(dateString);
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const selectedMonth = (e) => {
    e.preventDefault();
    const monthValue = e.currentTarget.dataset.value;

    setMonthValue(monthValue);
    setMonth(e.currentTarget.text);
    setCheckValue({ month: monthValue, year: years });
  };
  const selectedYear = (e) => {
    e.preventDefault();
    const yearValue = e.currentTarget.dataset.value;
    setYears(e.currentTarget.text);
    setCheckValue({ month: monthValue, year: yearValue });
  };
  const verifyAge = () => {
    if (isValid) {
      localStorage.setItem('verifyAge', 'true');
      history(REGISTRATION_ROUTE);
    }
  };

  if (spinner) return <Spinner />;
  else
    return (
      <LayoutForm classSelector={'verify-age'} title={"Parent's date of birth"}>
        <span>Hi Parent! Before you can create an account, please enter your date of birth.</span>
        <br />
        <span>This information is used to verify your age.</span>
        <form action="" method="" autoComplete={'off'}>
          <div className="input-text dropdown" style={{ zIndex: 2 }}>
            <div className="icon-select">
              <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L5 5L10 0H0Z" fill="#49454F" />
              </svg>
            </div>
            <input
              ref={monthRef}
              className="dropdown-toggle"
              id="month"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              placeholder={'Month'}
              value={month}
              readOnly={true}
              role="select"
            />
            <ul className="dropdown-menu" aria-labelledby="month">
              {monthList.map((element, index) => (
                <li key={element}>
                  <a className="dropdown-item" href="#" onClick={selectedMonth} data-value={index + 1}>
                    {element}
                  </a>
                </li>
              ))}
            </ul>

            <label htmlFor="month" className="">
              Parent&apos;s Birth Month
            </label>
          </div>

          <div className={`input-text ${errors === '' ? '' : 'box-invalid'}`} style={{ zIndex: 1 }}>
            <div className="icon-select">
              <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L5 5L10 0H0Z" fill="#49454F" />
              </svg>
            </div>
            <input
              className="dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              readOnly={true}
              ref={yearsRef}
              type="text"
              id="year"
              placeholder="Year"
              value={years}
              role="select"
            />
            <ul className="dropdown-menu" aria-labelledby="year">
              {yearsList().map((element) => (
                <li key={element}>
                  <a className="dropdown-item" href="#" onClick={selectedYear} data-value={element}>
                    {element}
                  </a>
                </li>
              ))}
            </ul>
            <div className="invalid-feedback">{errors}</div>
            <label htmlFor="year" className="">
              Parent’s Birth Year
            </label>
          </div>

          <FormButtonSubmit
            name={'Next'}
            color={isValid ? 'red' : 'default'}
            disabled={!isValid}
            callback={verifyAge}
          />
        </form>
      </LayoutForm>
    );
};

export default observer(UserVerifyAge);
