import React, { useContext, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import '../assets/scss/pages/registration-form.scss';
import '../assets/scss/pages/login-form.scss';
import FormButtonSubmit from '../components/user/FormButtonSubmit';
import LayoutForm from '../components/user/LayoutForm';
import AuthUserService from '../services/AuthUserService';
import { USER_PIN_AUTHORIZATION_ROUTE, USER_VERIFICATION_CODE_ROUTE } from '../utils/consts';
import { useNavigate } from 'react-router-dom';
import { Context } from '../index';
import Spinner from '../components/Spinner';

const UserForgotPassword = () => {
  const { userStore } = useContext(Context);
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState('');
  const [message, setMessage] = useState('');
  const emailRef = useRef(null);
  const emailInput = emailRef.current;
  const history = useNavigate();
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    if (userStore.isAuth) {
      history(USER_PIN_AUTHORIZATION_ROUTE, { replace: true });
    }
    setSpinner(userStore.isLoading);
  }, [userStore.isAuth, userStore.isLoading]);

  const sendEmail = async (e) => {
    e.preventDefault();
    let err = '';
    if (email === '') {
      err = 'Required';
      emailRef.current.classList.add('is-invalid');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      err = 'Invalid email address';
      emailInput.classList.add('is-invalid');
    } else {
      err = 'valid';
      emailInput.classList.remove('is-invalid');
    }

    setErrors(err);

    if (err === 'valid') {
      try {
        await AuthUserService.sendEmailResetPassword(email).then((response) => {
          if (response?.error) {
            emailInput.classList.add('is-invalid');
            setErrors(response.message);
          } else {
            emailInput.classList.remove('is-invalid');
            setMessage(response?.data?.message);
            history(USER_VERIFICATION_CODE_ROUTE + '?email=' + encodeURIComponent(email));
          }
        });
      } catch (e) {
        emailInput.classList.add('is-invalid');
        setErrors(e.response?.data?.message);
      }
    }
  };

  if (spinner) return <Spinner />;
  else
    return (
      <LayoutForm classSelector={'login-form'} title={'Forgot password?'}>
        <span>Enter the email address associated with your account to reset your password.</span>
        <form action="" method="">
          <div className={`input-text ${errors === '' || errors === 'valid' ? '' : 'box-invalid'}`}>
            <input
              ref={emailRef}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              className=""
              id="emailAddress"
              placeholder="example@gmail.com"
              required
            />
            <div className="invalid-feedback">{errors}</div>
            {message && <div className="invalid-feedback">{message}</div>}
            <label htmlFor="emailAddress" className="">
              Email
            </label>
          </div>

          <FormButtonSubmit name={' Send code'} type={'submit'} color={'red'} callback={sendEmail} />
        </form>
      </LayoutForm>
    );
};

export default observer(UserForgotPassword);
