import React, { useContext } from 'react';
import '../assets/scss/pages/registration-form.scss';
import '../assets/scss/pages/user-verify.scss';
import '../assets/scss/pages/message-page.scss';
import LayoutForm from '../components/user/LayoutForm';
import FormButton from '../components/user/FormButton';
import { Context } from '../index';
import { useSearchParams } from 'react-router-dom';

const UserMessageAddedOrnament = () => {
  const { adminStore } = useContext(Context);
  const [searchParams] = useSearchParams();
  const numberDevices = Number(searchParams.get('count'));
  const token = '?token=' + localStorage.getItem('deviceToken');
  const playerUrl = adminStore.playerUrl + token;
  return (
    <LayoutForm
      classSelector={'user-verify message-page message-added-ornament'}
      iconShow={true}
      classParentSelector={'icon-top'}
      title={'Ornament added'}
    >
      <span tabIndex={0}>You have successfully added another ornament to your account.</span>
      <br />
      <span tabIndex={0}>
        You now have {numberDevices} {numberDevices === 1 ? 'ornament' : 'ornaments'} associated with your account.
      </span>
      <div className={'block-button'}>
        <FormButton path={playerUrl} color={'red'} name={'Back to video'} />
      </div>
    </LayoutForm>
  );
};

export default UserMessageAddedOrnament;
