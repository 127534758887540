import React from 'react';
import 'bootstrap';
import PropTypes from 'prop-types';

const ModalDialogBox = ({
  title,
  messageTitle,
  messageText,
  buttonName,
  buttonEvent,
  modalId,
  status,
  children,
  classModalBox = '',
  classButton = '',
}) => {
  return (
    <div className={`modal fade ${classModalBox}`} id={modalId} aria-modal="true" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
          </div>
          <div className="modal-body">
            <div className={`alert ${status === 'danger' ? 'alert-danger' : ''}`}>
              <h5>
                <i className="fa fa-info-circle"></i>
                {messageTitle}
              </h5>
              {messageText && <p>{messageText}</p>}
              {children}
            </div>
          </div>
          <div className="modal-footer">
            <a href="" className="btn btn-white" data-bs-dismiss="modal">
              Close
            </a>
            <a
              href=""
              className={`btn ${status === 'danger' ? 'btn-danger' : 'btn-success'} ${classButton}`}
              data-bs-dismiss="modal"
              onClick={buttonEvent}
            >
              {buttonName}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDialogBox;

ModalDialogBox.propTypes = {
  title: PropTypes.string,
  messageTitle: PropTypes.string,
  messageText: PropTypes.string,
  children: PropTypes.element,
  buttonName: PropTypes.string,
  buttonEvent: PropTypes.func,
  modalId: PropTypes.string,
  status: PropTypes.string,
  classModalBox: PropTypes.string,
  classButton: PropTypes.string,
};
