import { useEffect } from 'react';
import $ from 'jquery';

export default (selectors) => {
  useEffect(() => {
    selectors = selectors ? ', ' + selectors : '';
    $(document).ready(() => {
      $('.screen-reader')
        .find('p:not(.nsc-r), h1, h2, h3, h4, h5, h6, span:not(.nsc-r), .sc-r' + selectors)
        .attr('tabindex', 0);
    });
  }, []);
};
