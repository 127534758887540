import React, { useEffect, useState } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import '../../assets/scss/components/filter-date.scss';
import moment from 'moment/moment';
import PropTypes from 'prop-types';

const FilterDate = ({ getDateRange }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    flatpickr('#startDate', {
      wrap: true,
      altInput: true,
      dateFormat: 'YYYY-MM-DD',
      altFormat: 'MM/DD/YYYY',
      allowInput: false,
      maxDate: endDate,
      parseDate: (datestr, format) => {
        return moment(datestr, format, true).toDate();
      },
      formatDate: (date, format) => {
        return moment(date).format(format);
      },
      onChange: function (selectedDates, dateStr) {
        setStartDate(dateStr);
      },
    });
    flatpickr('#endDate', {
      wrap: true,
      altInput: true,
      dateFormat: 'YYYY-MM-DD',
      altFormat: 'MM/DD/YYYY',
      allowInput: false,
      minDate: startDate,
      parseDate: (datestr, format) => {
        return moment(datestr, format, true).toDate();
      },
      formatDate: (date, format) => {
        return moment(date).format(format);
      },
      onChange: function (selectedDates, dateStr) {
        setEndDate(dateStr);
      },
    });
    getDateRange(startDate, endDate);
  }, [startDate, endDate]);

  return (
    <div className="filter-date me-2 mb-2">
      <div id="startDate" className={'input-date ms-2'}>
        From:
        <div className="flatpickr ms-1">
          <input type="text" placeholder="MM/DD/YYYY" data-input className={'form-control-sm'} />
          <a className="input-button ms-1 me-2" data-toggle>
            <i className="fas fa-calendar-days text-gray"></i>
          </a>
        </div>
      </div>
      <div id="endDate" className={'input-date ms-2'}>
        To:
        <div className="flatpickr ms-1">
          <input type="text" placeholder="MM/DD/YYYY" data-input className={'form-control-sm'} />
          <a className="input-button ms-1 me-2" data-toggle>
            <i className="fas fa-calendar-days text-gray"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FilterDate;
FilterDate.propTypes = {
  getDateRange: PropTypes.func,
};
