import React from 'react';
import '../../assets/scss/components/form-button.scss';
import PropTypes from 'prop-types';

const FormButtonSubmit = ({ name, color, disabled = false, callback, type = 'button' }) => {
  const onSubmit = (event) => {
    return callback(event);
  };
  return (
    <button type={type} className={`btn btn-${color}`} onClick={(event) => onSubmit(event)} disabled={disabled}>
      {name}
    </button>
  );
};

export default FormButtonSubmit;

FormButtonSubmit.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  callback: PropTypes.func,
  type: PropTypes.string,
};
