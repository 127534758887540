import React, { memo, useEffect, useRef } from 'react';
import { Tooltip as BsTooltip } from 'bootstrap';
import PropTypes from 'prop-types';

const TableThWithoutSort = ({ name, tooltip = name }) => {
  const thRef = useRef();
  useEffect(() => {
    const t = new BsTooltip(thRef.current, {
      title: tooltip,
      placement: 'top',
      trigger: 'hover',
    });
    return () => t.dispose();
  }, [tooltip]);
  return <th ref={thRef}>{name}</th>;
};

export default memo(TableThWithoutSort);

TableThWithoutSort.propTypes = {
  name: PropTypes.string,
  tooltip: PropTypes.string,
};
