import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import { API_URL } from '../http';
import AuthUserService from '../services/AuthUserService';

export default class UserStore {
  user = {};
  isAuth = false;
  isLoading = false;
  isSidebarCollapsed = false;
  deletedDevices = [];
  pinIdAuthorization = 0;
  constructor() {
    makeAutoObservable(this);
  }

  setAuth(bool) {
    this.isAuth = bool;
  }

  setSidebarCollapsed(bool) {
    this.isSidebarCollapsed = bool;
  }

  setUser(user) {
    this.user = user;
  }

  setLoading(boll) {
    this.isLoading = boll;
  }

  setDeletedDevices(deletedDevices) {
    this.deletedDevices = deletedDevices;
  }

  setPinIdAuthorization(pinIdAuthorization) {
    this.pinIdAuthorization = pinIdAuthorization;
  }

  async login(email, password, verifyCaptcha) {
    try {
      const response = await AuthUserService.login(email, password, verifyCaptcha);
      localStorage.setItem('tokenUser', response.data.accessToken);
      this.setAuth(true);
      this.setUser(response.data.user);
      return response.data.user;
    } catch (e) {
      return { error: true, message: e.response?.data?.message };
    }
  }

  async registration(email, password, marketEmail, verifyCaptcha) {
    try {
      const response = await AuthUserService.registration(email, password, marketEmail, verifyCaptcha);
      localStorage.setItem('tokenUser', response.data.accessToken);
      this.setUser(response.data.user);
      response.data.user.isActivated && this.setAuth(true);
    } catch (e) {
      return { error: true, message: e.response?.data?.message };
    }
  }

  async logout() {
    try {
      await AuthUserService.logout();
      localStorage.removeItem('tokenUser');
      localStorage.removeItem('deviceToken');
      this.setAuth(false);
      this.setUser({});
    } catch (e) {
      return { error: true, message: e.response?.data?.message };
    }
  }

  async checkAuth() {
    this.setLoading(true);
    try {
      const response = await axios.get(`${API_URL}api/user/refresh`, { withCredentials: true });
      localStorage.setItem('tokenUser', response.data.accessToken);
      response.data.user.isActivated && this.setAuth(true);
      this.setUser(response.data.user);
    } catch (e) {
      return { error: true, message: e.response?.data?.message };
    } finally {
      this.setLoading(false);
    }
  }
}
