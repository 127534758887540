import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import imgLabel from '../assets/images/snow.svg';
import imglogo from '../assets/images/logo-red.png';
import '../assets/scss/pages/registration-form.scss';
import '../assets/scss/pages/user-verify.scss';
import '../assets/scss/pages/account-created.scss';
import FormButton from '../components/user/FormButton';
import { Context } from '../index';
import screenReader from '../utils/screenReader';

const UserAccountCreated = () => {
  const { adminStore } = useContext(Context);
  const token = '?token=' + localStorage.getItem('deviceToken');
  const playerUrl = adminStore.playerUrl + token;
  screenReader();
  return (
    <div className="screen-reader account-created">
      <header>
        <div className="block-logo">
          <img src={imglogo} alt="Santa's Kindness ornament" />
        </div>
      </header>
      <div className="registration-form user-verify">
        <h1 className={'title-label'}>
          Your account has been created!
          <img src={imgLabel} alt="icon" />
        </h1>
        <span>
          If you have not been automatically redirected to the video content, <b>click the button below</b>.
        </span>
        <br />
        <span>To view videos on another device, scan the ornament QR code and sign in.</span>
        <div className={'block-button'}>
          <FormButton path={playerUrl} color={'red'} name={'Go to video'} />
        </div>
      </div>
    </div>
  );
};

export default observer(UserAccountCreated);
