import React, { useEffect, useState } from 'react';
import AdminService from '../services/AdminService';
import TableAdminLog from '../components/admin/TableAdminLog';

const AdminLog = () => {
  const [log, setLog] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchLogs() {
      try {
        await AdminService.fetchLogs().then((response) => {
          setLog(response.data);
        });
      } catch (e) {
        setError(e.response.data.message);
      }
    }
    fetchLogs();
  }, []);

  return <TableAdminLog log={log} tableName={'System action log'} error={error} />;
};

export default AdminLog;
