import React, { useContext, useState } from 'react';
import { Context } from '../../index';
import { Link, useNavigate } from 'react-router-dom';
import { ADMIN_LOGIN_ROUTE, ADMIN_PROFILE_ROUTE } from '../../utils/consts';
import { observer } from 'mobx-react-lite';

const HeaderNav = () => {
  const { adminStore } = useContext(Context);
  const history = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const username = adminStore.admin.first_name || adminStore.admin.email;
  const avatarSymbol = username.substring(0, 1).toUpperCase();
  const logout = async () => {
    await adminStore.logout().then(() => {
      history(ADMIN_LOGIN_ROUTE);
    });
  };

  return (
    <div className="navbar-nav">
      <div className="navbar-item navbar-user dropdown">
        <a
          href="#"
          onClick={() => setShowPopup(!showPopup)}
          className="navbar-link dropdown-toggle d-flex align-items-center"
          data-bs-toggle="dropdown"
        >
          <div className="avatar">{avatarSymbol}</div>
          <div>
            <span className="d-none d-md-inline">{username}</span>
            <b className="caret"></b>
          </div>
        </a>
        <div className={`dropdown-menu dropdown-menu-end me-1 ${showPopup ? 'show' : ''}`}>
          <Link to={ADMIN_PROFILE_ROUTE} className="dropdown-item">
            Edit Profile
          </Link>

          <a href="#" className="dropdown-item" onClick={logout}>
            Log Out
          </a>
        </div>
      </div>
    </div>
  );
};

export default observer(HeaderNav);
