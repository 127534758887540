import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AdminRouter from './components/AdminRouter';
import UserRouter from './components/UserRouter';

const App = function () {
  return (
    <div className={'app-container'}>
      <BrowserRouter>
        <AdminRouter />
        <UserRouter />
      </BrowserRouter>
    </div>
  );
};

export default App;
