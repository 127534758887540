import React, { useContext, useEffect, useRef, useState } from 'react';
import AdminService from '../../services/AdminService';
import PropTypes from 'prop-types';
import ModalDialogBox from './ModalDialogBox';
import AuthService from '../../services/AuthService';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';
import FieldDeviceEdit from './FieldDeviceEdit';
import CommentForm from './CommentForm';
import { Tooltip as BsTooltip } from 'bootstrap';

const FieldPinEdit = ({ pin }) => {
  const { adminStore } = useContext(Context);
  const [numberDevices, setNumberDevices] = useState(adminStore.numberDevices);
  const [isEdit, setIsEdit] = useState(true);
  const [isActive, setActive] = useState(pin.status);
  const [isView, setView] = useState(true);
  const inputRef = useRef(null);
  const currentValue = inputRef.current;
  const [name, setName] = useState(pin.name || '');
  const pinDefault = pin.name || '';
  const [countDevices, setCountDevices] = useState(pin.devices.length);
  const [newMessage, setNewMessage] = useState('');
  const [classButton, setClassButton] = useState('disabled');
  const commentRef = useRef();
  const checkComment = pin.pinDeactivationComments.length > 0;
  const [isComment, setIsComment] = useState(checkComment);
  const comment = checkComment ? pin.pinDeactivationComments[0].comment : '';

  useEffect(() => {
    if (newMessage && newMessage.length >= 2) {
      setIsComment(true);
      setClassButton('');
    } else setClassButton('disabled');

    const t = new BsTooltip(commentRef.current, {
      title: newMessage || comment,
      placement: 'top',
      trigger: 'hover',
    });

    return () => t.dispose();
  }, [comment, newMessage]);

  useEffect(() => {
    setNumberDevices(adminStore.numberDevices);
  }, [adminStore.numberDevices]);

  const hideInput = () => {
    setIsEdit(true);
    setName(pinDefault);
  };
  const fieldEdit = () => {
    setIsEdit(!isEdit);
  };
  const fieldSave = async () => {
    setIsEdit(true);
    if (currentValue?.value) {
      await AdminService.updatePinName(pin.id, name);
    }
  };

  const toggleActive = async () => {
    setActive(!isActive);
    await AuthService.updatePinStatus(pin.id, !isActive, newMessage);
  };

  const removeDevice = (count) => {
    setCountDevices(count);
  };

  return (
    <>
      <div className="row align-items-center bg-pin">
        <div className="col-sm-3">
          <h6 className="mb-0">{pin.pin}</h6>
        </div>
        {isEdit ? (
          <>
            <div className="col-sm-2 text-secondary"> {name}</div>
            <div className="col-sm-4 text-secondary">
              <button onClick={fieldEdit} className="btn btn-primary ms-5px text-decoration-none fw-bold">
                Edit
              </button>
              {isActive ? (
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target={'#modalAlert_' + pin.id}
                  className="btn btn-gray ms-5px default w-90px"
                >
                  Deactivate
                </button>
              ) : (
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target={'#modalAlert_' + pin.id}
                  className="btn btn-default ms-5px default w-90px"
                >
                  Activate
                </button>
              )}
              <div className="comment-info d-inline-flex align-middle">
                <i
                  ref={commentRef}
                  className={`fas fa-circle-info fa-fw fs-25px cursor-pointer ${
                    isComment ? 'text-primary-500' : 'text-default-800'
                  }`}
                ></i>
              </div>

              <ModalDialogBox
                title={'Warning'}
                messageTitle={' Are you sure you want to change the status?'}
                buttonName={'Submit'}
                buttonEvent={toggleActive}
                modalId={'modalAlert_' + pin.id}
                classModalBox={'modal-comment'}
                classButton={classButton}
              >
                <CommentForm id={pin.id} setNewMessage={setNewMessage} newMessage={newMessage} />
              </ModalDialogBox>
            </div>
          </>
        ) : (
          <>
            <div className="col-sm-3 text-secondary d-flex align-items-center">
              <input
                ref={inputRef}
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                className={'form-control'}
                autoFocus={!isEdit}
              />
            </div>
            <div className="col-sm-3 text-secondary">
              <button onClick={fieldSave} className="btn btn-success ms-5px text-decoration-none fw-bold">
                Save
              </button>
              <button onClick={hideInput} className="btn btn-default default ms-5px text-decoration-none fw-bold">
                Cancel
              </button>
            </div>
          </>
        )}
        {countDevices > 0 && (
          <div className="col-sm-3 text-secondary">
            {countDevices > Number(numberDevices) ? (
              <button
                onClick={() => setView(!isView)}
                className={'btn btn-red default'}
                data-bs-toggle="collapse"
                data-bs-target={`#flush-collapse-${pin.id}`}
                aria-expanded="false"
                aria-controls={`flush-collapse-${pin.id}`}
              >
                Devices {countDevices} of {numberDevices}{' '}
                <i className={`fa fa-${isView ? 'angle-down' : 'angle-up'}`}></i>
              </button>
            ) : (
              <button
                onClick={() => setView(!isView)}
                className={'btn btn-lime default'}
                data-bs-toggle="collapse"
                data-bs-target={`#flush-collapse-${pin.id}`}
                aria-expanded="false"
                aria-controls={`flush-collapse-${pin.id}`}
              >
                Device(s) {countDevices} of {numberDevices}{' '}
                <i className={`fa fa-${isView ? 'angle-down' : 'angle-up'}`}></i>
              </button>
            )}
          </div>
        )}
      </div>
      {pin.devices.length > 0 && (
        <div id={`flush-collapse-${pin.id}`} className={'collapse'}>
          {pin.devices.map((device) => (
            <div key={device.id} className="row align-items-center bg-device" id={`device-${device.id}`}>
              <FieldDeviceEdit key={device.id} device={device} deleteDevice={removeDevice} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default observer(FieldPinEdit);

FieldPinEdit.propTypes = {
  pin: PropTypes.object,
};
