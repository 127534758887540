import React, { useRef, useState } from 'react';
import $ from 'jquery';
import { getPlatform } from '../../utils/getPlatform';
import AdminService from '../../services/AdminService';
import PropTypes from 'prop-types';
import ModalDialogBox from './ModalDialogBox';
import moment from 'moment/moment';

const FieldDeviceEdit = ({ device, deleteDevice }) => {
  const [isEdit, setIsEdit] = useState(true);
  const [name, setName] = useState(device.name);
  const nameDefault = device.name;
  const lastLogin = device.lastLogin;
  const inputRef = useRef(null);
  const title = getPlatform(device.title);

  const hideInput = () => {
    setIsEdit(true);
    setName(nameDefault);
  };
  const fieldEdit = () => {
    setIsEdit(false);
  };
  const fieldSave = async () => {
    await AdminService.updateDeviceName(device.id, name).then(() => {
      setIsEdit(true);
    });
  };

  const removeDevice = async () => {
    $(document)
      .find('#device-' + device.id)
      .remove();

    await AdminService.removeDevice(device.id, name).then((response) => {
      setIsEdit(true);
      deleteDevice(response.data.count);
    });
  };

  return (
    <>
      <div className="col-sm-3">
        <h6 className="mb-0">{title}</h6>
      </div>

      {isEdit ? (
        <>
          <div className="col-sm-2 text-secondary"> {name}</div>
          <div className="col-sm-3 text-secondary">
            <button onClick={fieldEdit} className="btn btn-primary ms-5px text-decoration-none fw-bold">
              Edit
            </button>
            <button
              data-bs-toggle="modal"
              data-bs-target={'#modalAlert_' + device.id}
              className="btn btn-white default ms-5px text-decoration-none fw-bold"
            >
              Delete
            </button>

            <ModalDialogBox
              title={'Warning'}
              messageTitle={'Are you sure you want to delete device?'}
              buttonName={'Delete'}
              buttonEvent={removeDevice}
              modalId={'modalAlert_' + device.id}
              status={'danger'}
            />
          </div>
          <div className="col-sm-4">
            {lastLogin && (
              <div className={'text-lg-end'}>
                <b>Last login:</b> {moment(lastLogin).format('MM/DD/YY hh:mm:ss a')}
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="col-sm-3 text-secondary d-flex align-items-center">
            <input
              ref={inputRef}
              onChange={(e) => setName(e.target.value)}
              value={name}
              type="text"
              className={'form-control'}
              autoFocus={!isEdit}
            />
          </div>
          <div className="col-sm-3 text-secondary">
            <button onClick={fieldSave} className="btn btn-success ms-5px text-decoration-none fw-bold">
              Save
            </button>
            <button onClick={hideInput} className="btn btn-default default ms-5px text-decoration-none fw-bold">
              Cancel
            </button>
          </div>
          <div className="col-sm-3">
            {lastLogin && (
              <div className={'text-lg-end'}>
                <b>Last login:</b> {moment(lastLogin).format('MM/DD/YY hh:mm:ss a')}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FieldDeviceEdit;

FieldDeviceEdit.propTypes = {
  device: PropTypes.object,
  deleteDevice: PropTypes.func,
};
