import React, { useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ADMIN_LOGIN_ROUTE } from '../utils/consts';
import AuthService from '../services/AuthService';
import { validationPassword } from '../utils/validationForm';

const AdminResetPassword = () => {
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const history = useNavigate();
  const { id, token } = useParams();
  const passRef = useRef(null);
  const passInput = passRef.current;
  const sendPassword = async () => {
    const err = {};

    if (passInput) {
      err.pass = validationPassword(password, passInput, true, true);
    } else {
      err.pass = 'valid';
    }

    setErrors(err);
    if (err.pass === 'valid') {
      await AuthService.resetPassword(id, token, password).then((data) => {
        if (data?.status === 200) {
          history(ADMIN_LOGIN_ROUTE);
        }
      });
    }
  };

  return (
    <div className="login login-v1 reset-password">
      <div className="login-container">
        <div className="login-header">
          <div className="brand">
            <div className="d-flex align-items-center">Reset Password</div>
          </div>
          <div className="icon">
            <i className="fa fa-lock"></i>
          </div>
        </div>
        <div className="login-body">
          <div className="login-content fs-13px">
            <form action="" method="">
              <div className="form-floating mb-20px">
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  type="password"
                  className="form-control fs-13px h-45px"
                  id="password"
                  placeholder="Password"
                />
                <label htmlFor="password" className="d-flex align-items-center py-0">
                  Password
                </label>
              </div>
              <div className="form-floating mb-20px">
                <input
                  ref={passRef}
                  type="password"
                  className="form-control fs-13px h-45px"
                  id="re-password"
                  placeholder="Confirm Password"
                />
                <div className="valid-feedback">Entered correctly</div>
                <div className="invalid-feedback">{errors.pass}</div>
                <label htmlFor="re-password" className="d-flex align-items-center py-0">
                  Confirm Password
                </label>
              </div>

              <div className="login-buttons mb-20px">
                <button type="button" onClick={sendPassword} className="btn h-45px btn-success  w-100 btn-lg">
                  Save Password
                </button>
              </div>
              <div className="text-gray-500">
                {' '}
                Click&nbsp;
                <Link to={ADMIN_LOGIN_ROUTE} className="text-white">
                  here
                </Link>
                &nbsp;to login.
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminResetPassword;
