import React from 'react';
import PropTypes from 'prop-types';
import 'bootstrap';
import '../../assets/scss/components/modal-dialog-box-content.scss';

const ModalDialogBoxContent = ({ title, modalId, classSelector = '', children }) => {
  return (
    <div
      className={`modal modal-custom fade ${classSelector}`}
      id={modalId}
      role="dialog"
      aria-labelledby={'dialogLabel' + modalId}
      aria-describedby={'dialogDesc' + modalId}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <h2 tabIndex={0} className="modal-title" id={'dialogLabel' + modalId}>
              {title}
            </h2>
            <div className="modal-content" id={'dialogDesc' + modalId}>
              {children}
            </div>
          </div>
          <div className="modal-footer modal-footer-custom">
            <a href="#" className="close" data-bs-dismiss="modal">
              Close
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDialogBoxContent;

ModalDialogBoxContent.propTypes = {
  title: PropTypes.string,
  modalId: PropTypes.string,
  classSelector: PropTypes.string,
  children: PropTypes.node,
};
