import React, { useEffect, useRef, useState } from 'react';
import ModalDialogBox from './ModalDialogBox';
import AuthService from '../../services/AuthService';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tooltip as BsTooltip } from 'bootstrap';
import CommentForm from './CommentForm';

const TableRow = ({ id, email, pin, status, countDevices, batchName, registeredAt, comment, numberDevices }) => {
  const [isActive, setActive] = useState(status);
  const [newMessage, setNewMessage] = useState('');
  const [classButton, setClassButton] = useState('disabled');
  const commentRef = useRef();

  useEffect(() => {
    if (comment || newMessage) {
      const t = new BsTooltip(commentRef.current, {
        title: comment || newMessage,
        placement: 'top',
        trigger: 'hover',
      });

      if (newMessage && newMessage.length >= 2) setClassButton('');
      else setClassButton('disabled');

      return () => t.dispose();
    }
  }, [comment, newMessage]);

  const toggleActive = async () => {
    setActive(!isActive);
    await AuthService.updatePinStatus(id, !isActive, newMessage);
    commentRef.current.textContent = newMessage;
  };
  return (
    <tr>
      <td>{pin}</td>
      <td>{email}</td>
      <td>{isActive ? 'Registered' : 'Not Registered'}</td>
      <td>
        {countDevices > 0 && (
          <>
            {countDevices <= Number(numberDevices) ? (
              <span className={'text-success'}>
                Used {countDevices} of {numberDevices}
              </span>
            ) : (
              <span className={'text-red'}>
                Used {countDevices} of {numberDevices}
              </span>
            )}
          </>
        )}
      </td>
      <td>{batchName}</td>
      <td>{registeredAt && moment(registeredAt).format('MM/DD/YY hh:mm:ss a')}</td>
      <td ref={commentRef} style={{ maxWidth: 0 }} className={'text-truncate'}>
        {comment}
      </td>
      <td>
        {isActive ? (
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target={'#modalAlert_' + id}
            className="btn d-flex btn-gray me-1 mb-1 default"
          >
            Deactivate
          </button>
        ) : (
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target={'#modalAlert_' + id}
            className="btn d-flex btn-default me-1 mb-1 default"
          >
            Activate
          </button>
        )}
        <ModalDialogBox
          title={'Warning'}
          messageTitle={' You are going to change PIN status?'}
          buttonName={'Submit'}
          buttonEvent={toggleActive}
          modalId={'modalAlert_' + id}
          classModalBox={''}
          classButton={classButton}
        >
          <CommentForm id={id} setNewMessage={setNewMessage} newMessage={newMessage} />
        </ModalDialogBox>
      </td>
    </tr>
  );
};

export default TableRow;

TableRow.propTypes = {
  id: PropTypes.number,
  email: PropTypes.string,
  pin: PropTypes.string,
  status: PropTypes.bool,
  devices: PropTypes.array,
  countDevices: PropTypes.number,
  batchName: PropTypes.string,
  registeredAt: PropTypes.string,
  comment: PropTypes.string,
  numberDevices: PropTypes.number,
};
